// Core imports
import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'

// Application imports
import Teams from 'team/entities/team'

/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */
async function fetchTeams(params) {
  try {
   
    const {data} = await GraniteAPIClient().get('/company/teams/', {
      params,
    })
    const teams = data.map(items => new Teams(items))
    // TODO: update it while switching to granite api
    return {teams}
  }
  catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

export default {
  fetchTeams,
}
