class GraniteError extends Error {
  constructor(error, metadata) {
    super(error)
    this.name = this.constructor.name
    this.stack = new Error(error).stack
    this.metadata = metadata
    // GraniteError has a specific format, we capture all unformatted errors
    // and transforms them as "non_field" errors.
    this.errors = error
    if (error && typeof error !== 'object' && error.constructor !== Object) {
      this.errors = {non_field: error}
    }
  }
}

export default GraniteError
