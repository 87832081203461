// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'

// Application imports
import teamApi from 'team/gateways/team-api'
import {TEAM_EVENTS} from 'pages/shiftManagement/constants'

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */
async function fetchTeams(eventEmitter, params) {
	try {
		const data = await teamApi.fetchTeams(params)
		eventEmitter.emit(TEAM_EVENTS.GET_TEAM_SUCCESS, data)
	} catch (err) {
		eventEmitter.emit(TEAM_EVENTS.GET_TEAM_FAILURE, err)
	}
}

export {fetchTeams}
