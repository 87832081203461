import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class Company {
	constructor(data) {
		this.activated = data.activated || ''
		this.address_line1 = data.address_line1 || ''
		this.address_line2 = data.address_line2 || ''
		this.app_download = data.app_download || ''
		this.city = data.city || ''
		this.code = data.code || ''
		this.country = data.country || ''
		this.create_date = data.create_date || ''
		this.email = data.email || ''
		this.employee_count = data.employee_count || ''
		this.employee_management = data.employee_management || ''
		this.id = data.id || ''
		this.image = data.image || ''
		this.industry = data.industry || ''
		this.is_active = data.is_active || ''
		this.is_child = data.is_child || ''
		this.is_deleted = data.is_deleted || ''
		this.is_email_verified = data.is_email_verified || ''
		this.is_phone_verified = data.is_phone_verified || ''
		this.mailing_address_line1 = data.mailing_address_line1 || ''
		this.mailing_address_line2 = data.mailing_address_line2 || ''
		this.mailing_city = data.mailing_city || ''
		this.mailing_country = data.mailing_country || ''
		this.mailing_postal_code = data.mailing_postal_code || ''
		this.mailing_state = data.mailing_state || ''
		this.modified_date = data.modified_date || ''
		this.name = data.name || ''
		this.parent_company = data.parent_company || ''
		this.phone_ext = data.phone_ext || ''
		this.phone_no = data.phone_no || ''
		this.postal_code = data.postal_code || ''
		this.state = data.state || ''
		this.timezone = data.timezone || ''
		this.tutorial_comple = data.tutorial_comple || ''
		this.view_tracking = data.view_tracking || ''
	}
}

export default Company
