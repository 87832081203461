// Lib Imports
import React from 'react'
import {Box, TextInput, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'

// CORE Imports
import FormField from 'granite/components/FormField'

// Application Imports
import PROFILE_EVENTS from '../constants'

const ProfileForm = ({eventEmitter, updateProfileClicked, user}) => {
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    email: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .email('Invalid email')
      .required('Email is required'),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    try {
      const resp = await updateProfileClicked(values)
      eventEmitter.emit(PROFILE_EVENTS.PROFILE_POST_SUCCESS, resp)
    } catch (e) {
      eventEmitter.emit(PROFILE_EVENTS.PROFILE_POST_FAILURE)
      if (e.errors) setErrors(e.errors)
    }
    setSubmitting(false)
  }

  return (
    <>
      {/* <Avatar src={user.pic.mediumSrc} size="small" round="full" /> */}
      <Formik
        initialValues={{
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          username: user.username,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormField
              name="first_name"
              label="First Name"
              placeholder="John"
              error={touched.first_name && errors.first_name}
            >
              <TextInput
                name="first_name"
                placeholder="Enter First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
              />
            </FormField>
            <FormField
              name="last_name"
              placeholder="Doe"
              label="Last Name"
              error={touched.last_name && errors.last_name}
            >
              <TextInput
                placeholder="Enter Last Name"
                name="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
              />
            </FormField>
            <FormField
              name="email"
              label="Email"
              error={touched.email && errors.email}
            >
              <TextInput
                placeholder="name@company.com"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled
              />
            </FormField>
            <FormField
              name="username"
              label="Username"
              error={touched.username && errors.username}
            >
              <TextInput
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                disabled
              />
            </FormField>
            <Box
              fill="horizontal"
              direction="column"
              elevation="none"
              justify="between"
              pad="none"
            >
              <Button
                type="submit"
                alignSelf="start"
                margin={{vertical: 'small'}}
                label={isSubmitting ? 'Updating profile...' : 'Update profile'}
                disabled={isSubmitting}
                primary
              />
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ProfileForm
