import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class User {
  constructor({username, firstName, lastName, email, organisation, timezone, ...data}) {
    if (_.isNil(username) || !_.isString(username)) {
      throw new GraniteError('Userrname must be valid string')
    }
    this.username = username
    this.firstName = firstName || ''
    this.lastName = lastName || ''
    this.email = email || ''
    this.company_id = organisation[0].id || ''
    this.timezone = timezone || ''
  }
}

export default User
