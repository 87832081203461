import {cubicBezier, translate3d} from './utils'

// Type: Keyframe
const base = {
  animationTimingFunction: cubicBezier(0.2125, 0.61, 0.355, 1.0),
  transform: translate3d(0, 0, 0),
}

//   Type: Animation
const bounce = {

  '33%': {
    animationTimingFunction: cubicBezier(0.2125, 0.61, 0.355, 1.0),
    transform: translate3d(0, '10px', 0),
  },
  '66%': {
    animationTimingFunction: cubicBezier(0.2125, 0.61, 0.355, 1.0),
    transform: translate3d(0, '-10px', 0),
  },
  '100%': {
    animationTimingFunction: cubicBezier(0.2125, 0.61, 0.355, 1.0),
    transform: translate3d(0, '0px', 0),
  }
}

export default bounce
