import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'
import Moment from 'moment'
import {extendMoment} from 'moment-range'

const moment = extendMoment(Moment)
class Shift {
	constructor(data) {
		this.user = data.user || {}
		this.id = data.id || ''
		this.name = data.name || ''
		this.end_date = data.end_date && data.end_date != "None" && moment(data.end_date, moment.defaultFormat).toDate() || null
		//this.restriction_data = data.restriction_data || ''
		//this.deviation_data = data.deviation_data || {}
		//this.shift_duration = data.shift_duration || ''
		//this.hours_to_be_fulfilled = data.hours_to_be_fulfilled || ''
		//this.no_of_employees = data.no_of_employees || ''
		this.from = data.start_datetime && moment(data.start_datetime, moment.defaultFormat).toDate() || ''
		this.to = data.end_datetime && moment(data.end_datetime, moment.defaultFormat).toDate() || ''
		this.company_id = data.company_id || ''
		//this.on_going = data.on_going || ''
		//this.repeat = data.repeat || ''
		this.employeeIds = data.employee_ids || []
		this.expectedEmployees = data.no_of_employees || 0
		this.reoccuring = {days: (data.repeat && data.repeat?.weekdays) || [], endsOn: data.end_date && data.end_date != "None" && data.end_date || null, neverExpire: ((data.extra_data && data.extra_data.never_expire )|| null)}
		this.deviationThreshold = data.deviation_data || null
		this.punchInRestrictions =(data.restriction_data && data.restriction_data.punch_in) || null
		this.shiftStat = data.shiftStat || []
		this.timefence_end_time = data.timefence_end_time || null
		this.timefence_start_time = data.timefence_start_time || null
	}
}

export default Shift