import React from 'react'
import {Box, Button, Heading, Layer, Text} from 'grommet'
import {Close} from 'grommet-icons'


// CORE imports
import Divider from 'granite/components/Divider'


const RecurringShiftEditModal = ({
  editRecurringShift,
  RecurringShiftModalOpen,
  setRecurringShiftModalOpen,
  currentRecurringShiftEditModal,
  setRevert,
  shiftDetailsEdit,
  isSavingOnly,
  isSavingUpcoming,
  data,
  RecurringShift,
  setSubmitShift,
}) => {
  const closeRecurringShiftEditModal = (type) => {
    if(type == 'cross') setRevert(true)
    setSubmitShift((oldState) => {
      return {
        ...oldState,
        isSubmitting: false,
      }
    })
    setRecurringShiftModalOpen(false)
  }

  return (
        <Layer>
          <Box
            background="light-1"
            width="xlarge"
            round="small"
            overflow={{vertical: 'auto'}}
          >
            <Box
              direction="row"
              justify="between"
              align="center"
              pad={{top: 'small', bottom: 'xsmall', horizontal: 'medium'}}
              height={{min: 'auto'}}
            >
              <Heading level={3} color="dark-1" size="xsmall" margin="none">
                Edit Recurring Shift
              </Heading>
              <Button icon={<Close size="small" />} onClick={() => shiftDetailsEdit ? closeRecurringShiftEditModal() : closeRecurringShiftEditModal('cross')} />
            </Box>
            <Divider
              background="accent-1"
              margin={{bottom: 'small'}}
              height="vsmall"
            />
            <Box
              direction="column"
              gap="medium"
              pad={{top: 'small', horizontal: 'large'}}
            >
              <Text size="medium">Do you want to edit all recurring shift or only this shift?</Text>
              <Text size="xsmall"># "Only this" will delete this shift and create a new shift based on the entered date and time.</Text>
              <Text size="xsmall"># "All upcoming shifts" will update the date and time for all the shifts which are upcoming in the series.</Text>
              {/* TODO: disable button till necessory fields are not present */}
              <span style={{textAlign:'right'}}><Button
                primary
                alignSelf="end"
                color="accent-1"
                size="small"
                label={isSavingUpcoming ? "Saving..." : "All upcoming shifts"}
                onClick={editRecurringShift}
                margin={{bottom: 'large'}}
                disabled={isSavingUpcoming}
              />
              <Button
              primary
              style={{marginLeft:'20px'}}
              color="accent-1"
              size="small"
              label={isSavingOnly ? "Saving..." : "Only this"}
              onClick={currentRecurringShiftEditModal}
              margin={{bottom: 'large'}}
              disabled={isSavingOnly}
              />
              </span>
            </Box>
          </Box>
        </Layer>
  )
}


export default RecurringShiftEditModal