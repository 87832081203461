export const TOAST_MESSAGES = {
  EMPLOYEE_BALANCE_EMPTY: 'Employee balance can not be empty !!!',
  EMPLOYEE_BALANCE_NEGATIVE: 'Employee balance can not be negative !!!',
  FAILURE_FALLBACK: 'Something went wrong !!!',
  GET_DEPARTMENT_FAILURE_FALLBACK: 'Something went wrong, Departments are not fetched',
  GET_TEAM_FAILURE_FALLBACK: 'Something went wrong, Teams are not fetched',
  GET_TAGS_FAILURE_FALLBACK: 'Something went wrong, Tags are not fetched',
  GET_EMPLOYEE_FAILURE_FALLBACK: 'Something went wrong, Employees are not fetched',
  GET_PTO_POLICY_FAILURE_FALLBACK: 'Something went wrong, Pto policies are not fetched',
  CREATE_PTO_POLICY_SUCCESS: 'Policy Created successfully !!!',
  CREATE_PTO_POLICY_FAILURE_FALLBACK: 'Something went wrong, Policy is not created',
  UPDATE_PTO_POLICY_SUCCESS: 'Policy Updated successfully !!!',
  UPDATE_PTO_POLICY_FAILURE_FALLBACK: 'Something went wrong , Policy is not updated !!!',
  UPDATE_POLICY_BALANCE_SUCCESS: 'Employee Balance Updated Successfully !!!',
  GET_COMPANY_FAILURE_FALLBACK: 'Something went wrong, Companies are not fetched',
  GET_EMPLOYEE_LIST_FAILURE_FALLBACK: 'Something went wrong !!!',
  UPDATE_POLICY_BALANCE_FAILURE_FALLBACK: 'Something went wrong, Employee balance not updated !!!',
  EMPLOYEE_ADD_SUCCESS: 'Employees added successfully in policy !!!',
  EMPLOYEE_REMOVE_SUCCESS: 'Employees removed successfully from policy !!!',
  DELETE_HOLIDAY_SUCCESS: 'Holiday removed successfully from policy !!!',
  CREATE_HOLIDAY_SUCCESS: 'Holiday created successfully for policy !!!',
  DELETE_HOLIDAY_FAILURE_FALLBACK: 'Something went wrong !!!',
  UPDATE_HOLIDAY_SUCCESS: 'Holiday updated successfully from policy !!!',
};

export const ACCURAL_TAB_INFO_TEXT = {
  ACCURAL_TYPE: 'Select your accrual type. You may accrue yearly, hourly, per pay period, or not at all .',
  EVERY_PAY_PERIOD_PER:
    "Enter the total number of hours that should accrue. If 'per year' is selected, we'll automatically calculate the number of hours to add each pay period .",
  TOTAL_ACCURAL_HOURS: 'Enter the total number of hours that should accrue in one year .',
  BASED_ON_HOURS_WORKED_PER:
    'Enter the number of hours that will accrue when the employee has worked the specified number of hours .',
  USE_IT_OR_USE_IT:
    'If this box is checked and there is a positive balance. At the end of year it will get set to zero before accruing new hours. Warning: This is not recommended for CA users .',
  ACCURAL_HAPPENS:
    "Choose whether you'd like hours to accrue or reset on a team member's hire date, or on a particular date in the year. If you choose 'hire date' but do not actually set a hire date for a team member. The system will treat Jan 1st as their hire date .",
  ALLOW_ENTRIES_OUTSIDE_EMPLOYEE_CURRENT_ACCURAL_YEAR:
    "When this option is selected, the team member can enter time off for dates that fall outside the current accrual year. For example, if a team member's time off balance is set to renew on their hire date. July 15, and today is May 15, they will be able to enter time off for August 15 .",
  ACCURAL_TYPE_EVERY_PAY_PERIOD: 'If you have no pay period setup, the accrual would take place weekly',
};

export const HOLIDAY_VALIDATION_MESSAGES = {
  NAME_REQUIRED: 'Name is required',
  NUMBER_OF_HOURS_TYPE_ERROR: 'No. of Hours must be a number',
  NUMBER_OF_HOURS_MORE_THAN: 'No. of Hours cannot be negative',
  NUMBER_OF_HOURS_LESS_THAN: 'No. of Hours can not be greater than 24',
  NUMBER_OF_HOURS_INTEGER: 'No. of Hours should be integer',
  NUMBER_OF_HOURS_REQUIRED: 'No. of Hours is required',
  DATE_REQUIRED: 'Date is required',
};

export const ACCURAL_TAB_FORM_LABEL = {
  ACCURAL_TYPE: 'Accrual Type',
  ACCURAL_TYPE_PLACEHOLDER: 'Select Accrual Type',
  ALLOW_ENTRIES_OUTSIDE_EMPLOYEE_CURRENT_ACCURAL_YEAR: "Allow entries outside of a team member's current accrual year",
};

export const ACCURAL_VALIDATION_MESSAGES = {
  TOTAL_ACCURAL_HOURS_TYPE_ERROR: 'Accrual hours must be a number',
  TOTAL_ACCURAL_HOURS_REQUIRED: 'Accrual hours is required',
  TOTAL_ACCURAL_HOURS_MIN: 'Accrual hours must be positive',
  TOTAL_ACCURAL_HOURS_INT_ERROR: 'Accrual hours should be integer',
  BASED_ON_HOURS_WORKED_PER_TYPE_ERROR: 'Hours worked must be a number',
  BASED_ON_HOURS_WORKED_PER_MIN: 'Hours worked must be positive',
  BASED_ON_HOURS_WORKED_PER_INT_ERROR: 'Accrual hours should be integer',
};

export const POLICY_DETAILS_VALIDATION_MESSAGES = {
  POLICY_NAME_REQUIRED: 'Policy Name is required',
  POLICY_TYPE_REQUIRED: 'Policy Type is required',
};

export const BALANCE_SETTING_VALIDATION_MESSAGES = {
  WAITING_PERIOD_DAYS_TYPE_ERROR: 'Waiting period must be a number',
  WAITING_PERIOD_DAYS_MORE_THAN: 'Waiting period cannot be negative',
  WAITING_PERIOD_DAYS_LESS_THAN: 'Waiting period can not be greater than 365 days',
  WAITING_PERIOD_DAYS_INTEGER: 'Waiting period should be integer',
  CARRY_OVER_LIMIT_VALUE_TYPE_ERROR: 'Carry over limit must be a number',
  CARRY_OVER_LIMIT_VALUE_MORE_THAN: 'Carry over limit cannot be negative',
  CARRY_OVER_LIMIT_VALUE_LESS_THAN: 'Carry over limit can not be greater than 99999',
  CARRY_OVER_LIMIT_VALUE_INTEGER: 'Carry over limit should be integer',
  MAX_BALANCE_LIMIT_TYPE_ERROR: 'Maximum balance must be a number',
  MAX_BALANCE_LIMIT_MORE_THAN: 'Maximum balance cannot be negative',
  MAX_BALANCE_LIMIT_LESS_THAN: 'Maximum balance can not be greater than 99999',
  MAX_BALANCE_LIMIT_INTEGER: 'Maximum balance should be integer',
};

export const PTO_TABS = {
  POLICY_TAB: 'POLICY_TAB',
  ACCURAL_TAB: 'ACCURAL_TAB',
  HOLIDAY_TAB: 'HOLIDAY_TAB',
  BALANCE_TAB: 'BALANCE_TAB',
  EMPLOYEE_TAB: 'EMPLOYEE_TAB',
};
