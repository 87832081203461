// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'

// Application imports
import userApi from 'accounts/gateways/user-api'

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */
async function createOrganisationClicked({name, description, address}) {
  if (_.isNil(name)) {
    throw new GraniteError('Organisation name is invalid')
  }
  const data = await userApi.createOrganisation({name, description, address})
  return data
}

export {createOrganisationClicked}
