// Core imports
import GraniteError from 'utils/granite-error';
import GraniteAPIClient from 'utils/granite-client';

// Application imports
import Teams from 'team/entities/team';

/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */

async function fetchTags(params) {
  try {
    const { data } = await GraniteAPIClient().get('/job/tags/', {
      params,
    });
    const tags = data.map(items => new Teams(items));
    return { tags };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

export default {
  fetchTags,
};
