import {createSelector} from 'reselect'

import createReducer from 'utils/ducks/create-reducer'

// ActionsNames - <NOUN>/<VERB>_<NOUN>
const UPDATE_EMPLOYEES = 'organisations/employees/UPDATE_EMPLOYEES'

// Action creator name: <VERB><NOUN>
// Export actions
export function updateSidebar(accessData) {
  return {
    type: UPDATE_EMPLOYEES,
    data: accessData,
  }
}

// state name - <NOUN>
const defaultState = {}

// Reducers - <NOUN>_<VERB>_REDUCER
function UPDATE_EMPLOYEES_REDUCER(state, action) {
  return Object.assign({}, state, {
    [action.data.organisationId]: action.data.employees,
  })
}

const handlers = {
  [UPDATE_EMPLOYEES]: UPDATE_EMPLOYEES_REDUCER,
}

// Selectors
const employeesSelector = state => state.organisations.employees

export const employees = createSelector(
  employeesSelector,
  employees => employees,
)

// export reducers as default
export default createReducer(defaultState, handlers)
