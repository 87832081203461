// lib imports
import React, { useState, useEffect, useCallback } from 'react';
import { withTheme } from 'styled-components';
import { Box, Button } from 'grommet';
import Singleton from 'utils/singleton';
import queryString from 'query-string';
import ReactTable from 'react-table';

// CORE Imports
import SectionLoaderAtom from 'atoms/SectionLoader';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';

import EmployeeRemoveModal from 'pages/components/PTO/PtoPolicyEmployeeRemoveModal';
import EmployeeSettingFilter from 'pages/addOrEditPtoPolicy/component/EmployeeSettingFilter';

import { fetchTags } from 'tags/controllers/tags';
import { fetchTeams } from 'team/controllers/team';
import { fetchEmployees } from 'employee/controllers/employee';
import { fetchDepartments } from 'department/controllers/department';
import { fetchCompanies } from 'company/controllers/company';
import { getEmployeesList, updateEmployeeBalance, updatePtoPolicy } from 'pto/controllers/pto';

import { TOAST_MESSAGES, PTO_TABS } from 'pto/strings';
import useEmployeeData from 'pages/addOrEditPtoPolicy/hooks/useEmployeeData';
import { getEmployeeColumns } from 'pages/addOrEditPtoPolicy/component/columns';
import { EmployeeTableStyles } from 'pages/addOrEditPtoPolicy/styles';

import 'pages/addOrEditPtoPolicy/component/index.css';

const EmployeeSettingPage = ({
  setActiveStep,
  eventEmitter,
  data,
  policyId,
  submitting,
  setSubmitting,
  disabled,
  setLoader,
  location,
}) => {
  const [loading, setLoading] = useState(true);
  const [employeeLoader, setEmployeeLoader] = useState(true);
  const {
    defaultEmployees,
    updateInputBalance,
    companies,
    setCurrentPage,
    setCurrentPageSize,
    savePage,
    onDepartmentSelect,
    onTeamSelect,
    onFetchData,
    pageSizeChange,
    pageChange,
    currentPage,
    pageEmployeeSelection,
    currentPageSize,
    teamsOptions,
    employees,
    departmentsOptions,
    employeesList,
  } = useEmployeeData(eventEmitter, setLoading, policyId, setLoader, setEmployeeLoader);

  const [teamDisabled, setTeamDisabled] = useState(true);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [deleteableEmpId, setDeleteableEmpId] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [allEmployeesTick, setAllEmployeesTick] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [removeEmpConfirmModal, setRemoveEmpConfirmModal] = useState(false);

  const singleton = new Singleton();

  const previousPage = () => {
    if (data?.policy_type === 'PAID_HOLIDAYS') {
      GenericWindowPostMessage(PTO_TABS.HOLIDAY_TAB, { policyId: policyId });
      setActiveStep(1);
    } else if (data.policy_type === 'UNPAID_TIME') {
      GenericWindowPostMessage(PTO_TABS.POLICY_TAB, { policyId: policyId });
      GenericWindowPostMessage(PTO_TABS.POLICY_TAB, { policyId: policyId });
      setActiveStep(0);
    } else {
      GenericWindowPostMessage(PTO_TABS.BALANCE_TAB, { policyId: policyId });
      setActiveStep(2);
    }
  };

  const fetchEmpList = useCallback(() => {
    setCurrentPage(0);
    setCurrentPageSize(5);
    getEmployeesList(eventEmitter, {
      is_active: true,
      policyId,
      page_size: 5,
      page: 1,
    });
  }, [eventEmitter, policyId, setCurrentPage, setCurrentPageSize]);

  useEffect(() => {
    if (companies.length > 0) {
      const subCompaniesId = companies.map(item => item.id);
      const companiesId = JSON.stringify(subCompaniesId);
      fetchDepartments(eventEmitter, {
        paginate: false,
        get_active: true,
        get_active_teams: true,
        is_active: true,
        company_id: companiesId,
      });

      fetchTeams(eventEmitter, {
        paginate: false,
        company_id: companiesId,
        is_active: true,
      });

      fetchTags(eventEmitter, {
        company_id: companiesId,
        tag_type: 'JOB',
      });

      fetchEmployees(eventEmitter, {
        company_id: companiesId,
        is_active: true,
        paginate: false,
      });
    }
  }, [companies, eventEmitter]);

  useEffect(() => {
    const query = queryString.parse(location?.search);
    if (query.oAuthToken) {
      if (singleton.oAuthToken === null) {
        singleton.oAuthToken = query.oAuthToken;
      }
      if (policyId && policyId !== 'add') {
        fetchCompanies(eventEmitter, {
          paginate: false,
          company_id: +query.company_id,
        });
        fetchEmpList();
      } else {
        setLoader(false);
      }
    }
    // eslint-disable-next-line no-use-before-define
  }, [location, policyId, singleton.oAuthToken, fetchEmpList, eventEmitter, setLoader]);

  const handleSubmit = useCallback(
    (value, rowData, rowIndex) => {
      const parsedValue = parseFloat(parseFloat(value).toFixed(2));
      const employeeBalance = parseFloat(parseFloat(rowData?.employee_balance ?? 0).toFixed(2));
      if (parsedValue !== employeeBalance) {
        if (value === '') {
          GenericWindowPostMessage('FAILURE_TOAST', {
            toastMessage: TOAST_MESSAGES.EMPLOYEE_BALANCE_EMPTY,
          });
          return;
        }
        if (+value < 0) {
          GenericWindowPostMessage('FAILURE_TOAST', {
            toastMessage: TOAST_MESSAGES.EMPLOYEE_BALANCE_NEGATIVE,
          });
          return;
        }
        setLoading(true);
        updateInputBalance(parsedValue, rowIndex);

        const obj = {
          employee_id: rowData.employee_id,
          policy_id: parseInt(policyId),
          new_balance: parsedValue,
        };

        updateEmployeeBalance(eventEmitter, {
          params: obj,
          data: rowData,
        });
      }
    },
    // eslint-disable-next-line no-use-before-define
    [eventEmitter, policyId, setLoading, updateInputBalance],
  );

  const openRemoveEmpModal = id => {
    setDeleteableEmpId(id);
    setRemoveEmpConfirmModal(true);
  };

  const removeEmployee = () => {
    let employeesId = [];
    setRemoveEmpConfirmModal(false);
    employeesId.push(deleteableEmpId);
    setLoading(true);
    onSubmit(employeesId, 'remove');
  };

  const handleDepartmentChange = departments => {
    if (departments.length) {
      onDepartmentSelect({ selectedTeams, departments });
      setSelectedDepartments(departments);
      setTeamDisabled(false);
    } else {
      setSelectedTeams([]);
      setSelectedDepartments([]);
      setTeamDisabled(true);
      onDepartmentSelect({ selectedTeams, departments });
    }
  };
  const handleEmployeeSelect = employees => {
    setSelectedEmployee(employees);
  };

  const handleTeamChange = teams => {
    setSelectedTeams(teams);
    if (teams.length) {
      onTeamSelect({ teams, selectedDepartments });
    } else {
      onTeamSelect({ teams, selectedDepartments });
    }
  };

  const onSubmit = async (values, operationType) => {
    const params = {
      employee_ids: values,
      select_all_employees: operationType === 'add' ? allEmployeesTick : false,
      operation: operationType,
    };

    updatePtoPolicy(eventEmitter, params, policyId, operationType);
  };

  const handleAllEmployeeSelect = e => {
    setAllEmployeesTick(e.target.checked);
  };

  const assignEmployee = () => {
    let employeesId = [];
    if (allEmployeesTick) {
      setSubmitting(true);
      onSubmit(employeesId, 'add');
    } else if (selectedEmployee.length > 0) {
      setSubmitting(true);
      selectedEmployee.forEach(item => {
        defaultEmployees.forEach(subitem => {
          if (subitem.user.username === item.username) {
            employeesId.push(subitem.id);
          }
        });
      });
      data.employees &&
        data.employees.forEach(item => {
          employeesId.push(item.employee_id);
        });
      onSubmit(employeesId, 'add');
    } else {
      GenericWindowPostMessage('FAILURE_TOAST', {
        toastMessage: 'Please select employee to assign',
      });
    }
  };
  return employeeLoader ? (
    <SectionLoaderAtom active />
  ) : (
    <>
      <Box direction="column" gap="small" fill="vertical" width="full">
        <EmployeeSettingFilter
          handleDepartmentChange={handleDepartmentChange}
          departmentsOptions={departmentsOptions}
          handleTeamChange={handleTeamChange}
          teamsOptions={teamsOptions}
          handleEmployeeSelect={handleEmployeeSelect}
          employees={employees}
          teamDisabled={teamDisabled}
          handleAllEmployeeSelect={handleAllEmployeeSelect}
          allEmployeesTick={allEmployeesTick}
          submitting={submitting}
          assignEmployee={assignEmployee}
          disabled={disabled}
          data={data}
        />
        <Box pad="medium">
          <ReactTable
            data={employeesList}
            noDataText="No Records Found"
            manual
            loading={loading}
            minRows={1}
            onFetchData={onFetchData}
            onPageSizeChange={pageSizeChange}
            onPageChange={pageChange}
            page={currentPage}
            pages={pageEmployeeSelection}
            pageSize={currentPageSize}
            style={EmployeeTableStyles}
            columns={getEmployeeColumns(handleSubmit, openRemoveEmpModal, data?.policy_type)}
          />
        </Box>

        <Box pad="medium" direction="row" alignSelf="end" gap="small">
          <Button
            margin={{ right: 'medium' }}
            label="Previous"
            color="accent-1"
            size="medium"
            onClick={previousPage}
            primary
          />
          <Button label={'Save'} color="accent-1" onClick={savePage} primary disabled={disabled} />
        </Box>
      </Box>
      {removeEmpConfirmModal && (
        <EmployeeRemoveModal setRemoveEmpConfirmModal={setRemoveEmpConfirmModal} removeEmployee={removeEmployee} />
      )}
    </>
  );
};

export default withTheme(EmployeeSettingPage);
