// Lib Imports
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Box, Heading, Table, Text} from 'grommet'

// CORE Imports
import EventEmitter from 'utils/event-emitter'
import EMPLOYEES_LIST_MESSAGES from 'messages/employeesList'
import Breadcrumb from 'granite/components/Breadcrumb'
import generateBreadcrumbPath from 'granite/components/Breadcrumb/utils/path'
import Toast from 'granite/components/Toast'
import {
  DataGrid,
  DataGridHeader,
  DataGridBody,
  DataGridRow,
  DataGridTextCell,
} from 'granite/components/DataGrid'

// Application Imports
import * as UserDucks from 'accounts/ducks/user'
import * as OrganisationDucks from 'organisations/ducks/organisations'
import EMPLOYEE_LIST_EVENTS from './constants'
import {getEmployees} from 'organisations/gateways/employees-api'

const COLUMNS = [
  {
    property: 'actions',
    label: 'Actions',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.actions} editMode={editMode} />
    ),
  },
  {
    property: 'firstName',
    label: 'First Name',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.firstName} editMode={editMode} />
    ),
  },
  {
    property: 'lastName',
    label: 'Last Name',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.lastName} editMode={editMode} />
    ),
  },
  {
    property: 'username',
    label: 'Username',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.username} editMode={editMode} />
    ),
  },
  {
    property: 'title',
    label: 'Title',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.title} editMode={editMode} />
    ),
  },
  {
    property: 'employeeRole',
    label: 'Role',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.employeeRole} editMode={editMode} />
    ),
  },
  {
    property: 'phone_no',
    label: 'Phone',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.amount} editMode={editMode} />
    ),
  },
  {
    property: 'email',
    label: 'Email',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.amount} editMode={editMode} />
    ),
  },
  {
    property: 'is_active',
    label: 'Is Active',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.amount} editMode={editMode} />
    ),
  },
  {
    property: 'address',
    label: 'Address',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.amount} editMode={editMode} />
    ),
  },
  {
    property: 'modified_date_display',
    label: 'Last Activity',
    renderCell: (_cell, editMode) => (
      <DataGridTextCell data={_cell.amount} editMode={editMode} />
    ),
  },
]

function fetchEmployees(eventEmitter, organisation) {
  if (Boolean(organisation) === false) return
  getEmployees(organisation.id)
    .then(response => {
      eventEmitter.emit(EMPLOYEE_LIST_EVENTS.LIST_SUCCESS, response.employees)
    })
    .catch(err => {
      eventEmitter.emit(EMPLOYEE_LIST_EVENTS.LIST_FAILURE)
    })
}

const EmployeeList = ({
  dispatch,
  location,
  selectedOrganisation,
  userProfile,
}) => {
  const [employees, setEmployees] = useState([])
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [sortBy, setSortBy] = useState()
  const eventEmitter = new EventEmitter()
  // Internal utils methods
  const showToast = message => setToastData({open: true, message})
  const hideToast = () => setToastData({open: false, message: ''})

  /**
   * Effect: fetchEmployees list based on default filters
   * Deps: userProfile. Adding eventEmitter will cause this effect to keep fatching employees over n over
   */
  useEffect(() => fetchEmployees(eventEmitter, selectedOrganisation), [
    selectedOrganisation,
  ])

  /**
   * Effect: subscribe and listen to Rx events
   * Cleanup: unsubscribe to Rx observable
   * Deps: eventEmitter
   */
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case EMPLOYEE_LIST_EVENTS.LIST_SUCCESS:
          setEmployees(event.data)
          break
        case EMPLOYEE_LIST_EVENTS.LIST_FAILURE:
          showToast(EMPLOYEES_LIST_MESSAGES.LIST_FAILURE)
          setTimeout(() => {
            hideToast()
          }, 1000)
          break
        default:
          break
      }
    })
    return () => subscription.unsubscribe()
  }, [eventEmitter])

  const path = generateBreadcrumbPath(location.pathname)
  return (
    <Box pad="large" flex={{grow: 1}}>
      <Breadcrumb margin={{bottom: 'large'}} path={path} />
      <Heading level={2}>{EMPLOYEES_LIST_MESSAGES.HEADING}</Heading>
      <Text size="medium">{EMPLOYEES_LIST_MESSAGES.CAPTION}</Text>
      <Box
        direction="row"
        margin={{top: 'medium'}}
        overflow={{horizontal: 'auto', vertical: 'hidden'}}
      >
        <DataGrid columns={COLUMNS} data={employees}>
          <Table>
            <DataGridHeader background="white" sortBy={sortBy} />
            <DataGridBody>
              {employees.map(item => (
                <DataGridRow
                  key={`row-${item.username}`}
                  background="white"
                  data={item}
                />
              ))}
            </DataGridBody>
          </Table>
        </DataGrid>
      </Box>
      {toastData.open ? <Toast text={toastData.message} /> : null}
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
})

export default connect(mapStateToProps)(EmployeeList)
