const LOGIN_MESSAGES = {
  ONBOARDING_SUCCESS: 'Organisation created successfully',
  ONBOARDING_FAILURE: 'Oops something went wrong',
  NAME_LABEL: 'Company Name',
  DESCRIPTION_LABEL: 'Description',
  ADDRESS_LINE_1_LABEL: 'Address Line 1',
  ADDRESS_LINE_2_LABEL: 'City',
  NAME_PLACEHOLDER: 'Name',
  DESCRIPTION_PLACEHOLDER: 'Something nice about your company',
  ADDRESS_LINE_1_PLACEHOLDER: 'House Number',
  ADDRESS_LINE_2_PLACEHOLDER: 'City',
  SUBMIT_BUTTON_LABEL_SUBMITTING: 'Creating...',
  SUBMIT_BUTTON_LABEL: 'Create Organisation',
}

export default LOGIN_MESSAGES
