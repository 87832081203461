import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as ViewIcon} from './view.svg';

const ViewIconAtom = props => (
  <ViewIcon {...props} />
);

ViewIconAtom.defaultProps = {
  height: 16,
  width: 16,
};

ViewIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default ViewIconAtom;
