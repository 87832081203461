import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import EmployeeEntity from 'organisations/entities/employee'

async function getEmployees(organisationId) {
  const payload = {}
  try {
    const response = await GraniteAPIClient().get(
      `/employee?company_id=[${organisationId}]`,
      payload,
    )
    //TODO: return entities
    const {data} = response
    const employees = data.results.map(item => new EmployeeEntity(item))

    return {employees, count: data.count, organisationId}
  } catch (error) {
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

export {getEmployees}
