//FIXME: each word after home icon should contain path to words appearing before
import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Image, Button} from 'grommet'
import {Link} from 'react-router-dom'
import {FormNext} from 'grommet-icons/icons/FormNext'
import {Home} from 'grommet-icons/icons/Home'

const Breadcrumb = ({path, refresh, ...rest}) => {
  return (
    <Box direction="row" justify="between" margin={{vertical: 'small'}} {...rest}>
      <Box direction="row">
        {path.map((x, index) => (
          <Fragment key={index}>
            <Link to={x.path}>
              <Box margin={{right: 'small'}} direction="row">
                <Text
                  color={index === path.length - 1 && path.length > 1 ? 'dark-1' : '#0f2bb4'}
                  margin={{right: 'small'}}
                >
                  {x.label}
                </Text>
              </Box>
            </Link>
            {index !== path.length - 1 ? (
              <Box margin={{right: 'xsmall'}}>
                '/'
              </Box>
            ) : null}
          </Fragment>
        ))}
      </Box>
      <Box direction="row" margin={{right:'10px'}} >
        <Button plain as="div" onClick={refresh}>
          <Image
            alt="refresh"
            height="auto"
            width="20px"
            src={process.env.PUBLIC_URL + '/img/refresh.svg'}
          />
        </Button>
      </Box>
    </Box>
  )
}

Breadcrumb.defaultProps = {
  path: [],
}

Breadcrumb.propTypes = {
  path: PropTypes.array,
  refresh: PropTypes.func,
}

export default Breadcrumb
