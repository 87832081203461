// Lib imports
import React from 'react'
import {Box, TextInput, Anchor, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'

// Core imports
import Divider from 'granite/components/Divider'
import PasswordInput from 'granite/components/PasswordInput'
import FormField from 'granite/components/FormField'
import LOGIN_MESSAGES from 'messages/login'

// Application imports
import LOGIN_EVENTS from './../constants'

const LoginForm = ({eventEmitter, loginClicked}) => {
  // FIXME: Move strings to messages
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, LOGIN_MESSAGES.EMAIL_TOO_SHORT)
      .max(50, LOGIN_MESSAGES.EMAIL_TOO_LONG)
      .required(LOGIN_MESSAGES.EMAIL_REQUIRED),
    password: Yup.string()
      .min(6, LOGIN_MESSAGES.PASS_TOO_SHORT)
      .max(50, LOGIN_MESSAGES.PASS_TOO_LONG)
      .required(LOGIN_MESSAGES.PASS_REQUIRED),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    try {
      await loginClicked(values.email, values.password)
      eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS)
    } catch (err) {
      const errorMessage = err && err.errors.non_field;
      eventEmitter.emit(LOGIN_EVENTS.LOGIN_FAILURE, errorMessage)
      if (err.errors) setErrors(err.errors)
    }
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {/** FIXME: Move strings to messages */}
          <Box gap="medium" margin={{top:'1em'}}>
            <FormField
              name="email"
              label={LOGIN_MESSAGES.EMAIL_LABEL}
              error={touched.email && errors.email}
            >
              <TextInput
                placeholder={LOGIN_MESSAGES.EMAIL_PLACEHOLDER}
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </FormField>
            <FormField
              name="password"
              label={LOGIN_MESSAGES.PASS_LABEL}
              required
              error={touched.password && errors.password}
            >
              <PasswordInput
                placeholder={LOGIN_MESSAGES.PASS_PLACEHOLDER}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </FormField>
          </Box>
          <Box
            direction="row"
            justify="between"
            pad={{top:"small"}}
            gap="xsmall"
            margin={{top: 'small'}}
          >
            <Anchor
              href={
                process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL +
                '/accounts/forgot-password'
              }

              label={LOGIN_MESSAGES.FORGOT_PASS_LINK_LABEL}
              size="small"
              margin={{top: 'small'}}
            />
            <Button
              type="submit"
              color="accent-1"
              style={{'fontSize':'15px', padding:'0.4rem 1.4rem'}}
              label={
                isSubmitting
                  ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING
                  : LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL
              }
              disabled={isSubmitting}
              round="medium"
              primary
            />
          </Box>
          <Divider background="light-5" margin={{vertical: 'medium'}} />
          <Box direction="row" gap="xsmall" margin={{bottom:'5px'}}>
            <Text color="black" size="small">{LOGIN_MESSAGES.REGISTER_LINK_LABEL}</Text>
            <Anchor
              href={
                process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL +
                '/accounts/register'
              }
              label={LOGIN_MESSAGES.REGISTER_LINK_TEXT}
              size="small"
              target="__blank"
            />
          </Box>
          <Box direction="row" gap="xsmall">
            <Text color='black' size="small">{LOGIN_MESSAGES.KIOSK_LINK_LABEL}</Text>
            <Anchor
              href={process.env.REACT_APP_KIOSK_DASHBOARD_BASE_URL}
              label={LOGIN_MESSAGES.KIOSK_LINK_TEXT}
              size="small"
              target="__blank"
            />
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
