import React from 'react'
import styled, {keyframes, withTheme} from 'styled-components'
import bounce from 'granite/components/Animations/bounce'

const fbStretchAnimation = keyframes`${bounce}`

const InnerDiv = styled.div`
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  animation:0.6s ${fbStretchAnimation} infinite;

  background: #09819a;
`
const OuterDiv = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`
const FirstDiv = styled(InnerDiv)`
  left: 6px;
  animation-delay: -0.14s;

`
const SecondDiv = styled(InnerDiv)`
  left: 26px;
  animation-delay: -0.07s;
`
const ThirdDiv = styled(InnerDiv)`
  left: 45px;
  animation-delay: 0;
`

const SectionLoader = ({...rest}) => {
  return (
    <OuterDiv>
      <FirstDiv></FirstDiv>
      <SecondDiv></SecondDiv>
      <ThirdDiv></ThirdDiv>
    </OuterDiv>
  )
}

export default withTheme(SectionLoader)