import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {TableRow, TableCell, CheckBox} from 'grommet'
import {Edit} from 'grommet-icons/icons/Edit'
import {Save} from 'grommet-icons/icons/Save'
import {Trash} from 'grommet-icons/icons/Trash'
import {useDataGridContext} from 'granite/components/DataGrid/DataGridTable'

const StyledTableRow = styled(TableRow)`
  background-color: ${props => {
    return props.theme.global.colors[props.background]
  }};
  border-bottom: ${props => `1px solid ${props.theme.global.colors['brand-3p']}`};
`

function DataGridRow({
  data,
  isSelectable,
  isEditable,
  onRowEditSave,
  onRowEditDelete,
  ...rest
}) {
  const {
    columns,
    hasFirstColumn,
    selectedIds,
    toggleSelectedId,
  } = useDataGridContext()

  const isSelectedProp = selectedIds.indexOf(data.id) > -1 ? true : false

  const [isSelected, setIsSelected] = useState(isSelectedProp)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setIsSelected(isSelectedProp)
  }, [selectedIds])

  const onRowSelect = (ev, id) => {
    setIsSelected(ev.target.checked)
    toggleSelectedId(id)
  }

  const onEditClick = () => {
    setEditMode(true)
  }

  const onEditSave = () => {
    setEditMode(false)
    // combine updated data as rowData and pass to Parent editSave callback
    onRowEditSave && onRowEditSave()
  }

  const onEditTrash = () => {
    setEditMode(false)
    onRowEditDelete && onRowEditDelete()
  }

  const SelectModeUI = () => (
    <CheckBox checked={isSelected} onChange={ev => onRowSelect(ev, data.id)} />
  )

  const EditModeUI = () => (
    <>
      <Save onClick={onEditSave} />
      <Trash onClick={onEditTrash} />
    </>
  )

  const NonEditModeUI = () => (
    <>
      <Edit onClick={onEditClick} />
    </>
  )

  return (
    <StyledTableRow background={isSelected ? 'light-6' : ''} {...rest}>
      {hasFirstColumn ? (
        <TableCell scope="row">
          {isSelectable && <SelectModeUI />}

          {isEditable && editMode ? <EditModeUI /> : null}
          {isEditable && !editMode ? <NonEditModeUI /> : null}
        </TableCell>
      ) : null}
      {columns.map(col => {
        if (!col.visible) return
        return (
          <TableCell scope="row" key={`${data.id}-col-${col.property}`}>
            {col.renderCell(data, editMode)}
          </TableCell>
        )
      })}
    </StyledTableRow>
  )
}
export default DataGridRow
