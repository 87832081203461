import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import {Box, Button, Collapsible, Text} from 'grommet'
import {FormDown} from 'grommet-icons/icons/FormDown'
import {FormNext} from 'grommet-icons/icons/FormNext'
import {removeAuthToken} from 'granite/controllers/dashboardHeader'
import Cookies from 'universal-cookie';
import {getAuthToken} from 'utils/auth-singleton'

const MenuButton = ({
  children,
  label,
  id,
  icon,
  level,
  isActive,
  activeColor,
  isExpandable,
  isExpanded: defaultIsExpanded,
  hoverIndicatorOptions,
  onClick,
  isCollapsed,
  textColor,
  textTransform,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded)
  const handleClick = () => {
    setIsExpanded(!isExpanded)
    onClick(!isExpanded)
  }

  const cookie = new Cookies();

  useEffect(() => {
    startTimer();
    window.addEventListener('mousedown', pageClick, false);
    window.addEventListener('mouseup', pageClick, false);
    window.addEventListener('mousemove', pageClick, false);
    window.addEventListener('keypress', pageClick, false);
    window.addEventListener('touchmove', pageClick, false);
    window.addEventListener('onscroll', pageClick, false);

    return () => {
    window.clearTimeout(0);
    window.removeEventListener('mousedown', pageClick, false);
    window.removeEventListener('mouseup', pageClick, false);
    window.removeEventListener('mousemove', pageClick, false);
    window.removeEventListener('keypress', pageClick, false);
    window.removeEventListener('touchmove', pageClick, false);
    window.removeEventListener('onscroll', pageClick, false);
    }

  }, [])

 const startTimer = () => {
    const lastActivityTime = cookie.get('lastActivityTime');
    const currentTime = new Date();
    const currentDateString = currentTime.toString();
    let diff = currentTime - new Date(lastActivityTime);
    const oAuthToken =   getAuthToken();
    diff = diff/1000;
    diff = diff/60;
    if((diff < 20 && !isNaN(diff)) || (!lastActivityTime && oAuthToken) ){
        cookie.set('lastActivityTime', currentDateString, { path: '/' });
    }
    else if( diff >= 20){
      logOut()      
    }
  } 
  
  const pageClick = () => {
    startTimer();
  }

  const logOut = () => {
    cookie.remove('lastActivityTime', {path: '/'})
    removeAuthToken()    
  }

  return (
    <>
      <Box
        direction="row"
        align="center"
        justify="between"
        background={isActive ? activeColor : null}
        style={!isCollapsed ? {width: '100%', minHeight: '35px'} : null}
      >
        <Button
          onClick={handleClick}
          as="div"
          fill
          style={!isCollapsed && level===1 ? {width: '100%'} : null}
          hoverIndicator={
            isActive
              ? false
              : hoverIndicatorOptions
              ? hoverIndicatorOptions
              : true
          }
          {...rest}
        >
          <Box
            pad={{
              vertical: 'small',
              horizontal: isCollapsed ? 'medium' : 'large',
            }}
            direction="row"
            justify={isCollapsed ? 'center' : 'start'}
            style={{
              marginLeft: !isCollapsed ? `${16 * level}px` : '0px', 
              width: !isCollapsed && level===1? `100%` : `auto`,
            }}
          >
            <Button
              plain
              as="div"
              icon={icon}
              label={
                isCollapsed ? (
                  ''
                ) : (
                  <Text style={!isCollapsed  && label !== 'Dashboard' && label !== 'Overview' && label !== 'Employees' && label !== 'Tracking' && label !== 'Timecards' && label !== 'Job Center' && label !== 'Scheduling' && label !== 'Reports'&& level!==1?{'overflow' : 'hidden' , 'textOverflow' : 'ellipsis', 'maxWidth' : '120px', 'textTransform' : `${textTransform}`}:{'overflow' : 'hidden' , 'textOverflow' : 'ellipsis'} } color={isActive ? '#09819a' : textColor} size="small" >
                    {label}
                  </Text>
                )
              }
            />
            {!isCollapsed &&
              isExpandable &&
              (isExpanded ? <FormDown style={{width:"14px", height:"20px"}}/> : <FormNext style={{width:"14px", height:"14px", 'marginTop':'3px'}} />)}
          </Box>
        </Button>
      </Box>
      <Collapsible open={isExpanded}>{children}</Collapsible>
    </>
  )
}

MenuButton.defaultProps = {
  icon: null,
  isActive: false,
  activeColor: 'brand',
  isCollapsed: false,
  isExpandable: false,
  isExpanded: false,
  level: 0,
  onClick: () => {},
  textColor: 'text',
}

MenuButton.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]),
  isActive: PropTypes.bool,
  activeColor: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  level: PropTypes.number || PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
  textTransform: PropTypes.string,
}

export default MenuButton
