import React, {Children, cloneElement} from 'react'
import {Box, Text} from 'grommet'
import {withTheme} from 'styled-components'

const FormField = ({label, name, help, error, theme, children, ...rest}) => {
  const {formField} = theme
  const borderColor = theme.global.colors['status-critical']

  const normalizedChildren = children
    ? Children.map(children, child => {
        if (child) {
          return cloneElement(child, {
            style: {
              borderColor: error ? borderColor : null,
            },
            error,
          })
        }
        return child
      })
    : null

  return (
    <Box margin={error?{bottom:'0'}:{...formField.margin}} {...rest}>
      <Text as="label" htmlFor={name} color="#000000" style={{marginBottom:'8px'}} size="small" {...formField.label}>
        {label}
      </Text>
      {help && (
        <Text
          {...formField.help}
          color={formField.help.color[theme.dark ? 'dark' : 'light']}
        >
          {help}
        </Text>
      )}
      {normalizedChildren}
      {error && (
        <Text
          style={{marginBottom:'0'}}
          {...formField.error}
          color={theme.global.colors['status-critical']}
        >
          {error}
        </Text>
      )}
    </Box>
  )
}

export default withTheme(FormField)
