import React, { useState, useEffect } from 'react';
import { Box } from 'grommet';
import queryString from 'query-string';
import EventEmitter from 'utils/event-emitter';
import TimeOffPoliciesSlider from './component/timeOffPoliciesSlider';

import Table from './component/table';
import EmployeeRemoveModal from 'pages/components/PTO/PtoPolicyEmployeeRemoveModal';
import styles from './styles.module.scss';

import {
  getEmployeePolicy,
  getAccuralHistory,
  updateEmployeeBalance,
  updatePtoPolicy,
  fetchAllPtoPolicy,
} from 'pto/controllers/pto';
import './styles.module.scss';
import Singleton from 'utils/singleton';
import { PTO_POLICY_EVENTS } from 'pto/constants';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
let eventEmitter = new EventEmitter();

const PtoTimeOffPoliciesPage = props => {
  const {
    location: { search },
  } = props;

  const query = queryString.parse(search);

  const [loader, setLoader] = useState(query.view_timeOff_policies ? false : true);
  const [slider, setSlider] = useState(false);
  const [employeePolicy, setEmployeePolicy] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [accuralHistoryData, setAccuralHistoryData] = useState([]);
  const [policyId, setPolicyId] = useState();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [removeEmpConfirmModal, setRemoveEmpConfirmModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [companyPolicyList, setCompanyPolicyList] = useState([]);
  const singleton = new Singleton();
  const handleSubmit = (value, rowData, rowIndex) => {
    const parsedValue = parseFloat(parseFloat(value).toFixed(2));
    const employeeBalance = parseFloat(parseFloat(rowData?.employee_balance ?? 0).toFixed(2));
    if (parsedValue !== employeeBalance) {
      if (value === '') {
        GenericWindowPostMessage('FAILURE_TOAST', {
          toastMessage: 'Employee balance can not be empty !!!',
        });
        return;
      }
      if (+value < 0) {
        GenericWindowPostMessage('FAILURE_TOAST', {
          toastMessage: 'Employee balance can not be negative !!!',
        });
        return;
      }
      setLoading(true);
      setEmployeePolicy(oldData =>
        oldData.map((oldValue, index) => {
          if (index === rowIndex) {
            return {
              ...oldValue,
              employee_balance: parsedValue,
            };
          } else {
            return oldValue;
          }
        }),
      );

      const obj = {
        employee_id: parseInt(employeeId),
        policy_id: parseInt(rowData?.policy_id),
        new_balance: parsedValue,
      };

      updateEmployeeBalance(eventEmitter, {
        params: obj,
        data: rowData,
      });
    }
  };

  const getEmployeePolicyList = () => {
    setLoading(true);
    const query = queryString.parse(search);
    getEmployeePolicy(eventEmitter, {
      id: query.employee_id,
    });
    fetchAllPtoPolicy(eventEmitter, {
      employee_id: parseInt(query.employee_id),
      is_active: true,
    });
  };

  useEffect(() => {
    const query = queryString.parse(search);
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }
    if (!query.view_timeOff_policies) {
      setEmployeeId(query.employee_id);
      getEmployeePolicyList();
    }
    if (query.view_timeOff_policies) {
      setSlider(true);
      setLoader(false);
      setLoading(true);
      setEmployeeId(query.employee_id);
      setPolicyId(query.policy_id);
      getAccuralHistory(eventEmitter, {
        id: query.employee_id,
        policy_id: query.policy_id,
        pageSize: 10,
        pageNo: 1,
      });
    }
  }, [search]);

  useEffect(function init() {
    const observable = eventEmitter.getObservable();
    let subscription;
    listenObservable();

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_SUCCESS:
            setEmployeePolicy(event.data.policies);
            setLoader(false);
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Employee balance not updated !!!',
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.GET_ACCURAL_HISTORY_SUCCESS:
            setPageSize(event.data.accuralHistory.count);
            setAccuralHistoryData(event.data.accuralHistory.results);
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.GET_ACCURAL_HISTORY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong, Employee balance not updated !!!',
            });
            break;

          case PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_SUCCESS:
            GenericWindowPostMessage('SUCCESS_TOAST_BALANCE', {
              toastMessage: 'Employee Balance Updated Successfully !!!',
            });
            setLoading(false);
            break;

          case PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST_BALANCE', {
              toastMessage: event?.data?.errorMessage || 'Something went wrong, Employee balance not updated !!!',
            });
            setEmployeePolicy(oldData =>
              oldData.map((oldValue, index) => {
                if (oldValue?.employee_id === event?.data?.prvData?.employee_id) {
                  return {
                    ...oldValue,
                    balance: event?.data?.prvData?.balance,
                  };
                } else {
                  return oldValue;
                }
              }),
            );
            setTimeout(() => setLoading(false), 100);
            break;

          case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS:
            GenericWindowPostMessage('SUCCESS_TOAST_BALANCE', {
              toastMessage: 'Employee policy updated successfully !!!',
            });
            setLoading(false);
            setIsUpdating(false);
            setRemoveEmpConfirmModal(false);
            getEmployeePolicyList();
            break;

          case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST_BALANCE', {
              toastMessage: event.data || 'Something went wrong !!!',
            });
            setIsUpdating(false);
            setRemoveEmpConfirmModal(false);
            setLoading(false);
            break;
          case PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS:
            const arr = event.data.policies
              ? event.data.policies.map(item => {
                  return { label: item.policy_name, value: item.policy_id };
                })
              : [];
            // arr.push({label:'Loading...',value:0})
            setCompanyPolicyList(arr);
            break;

          case PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE:
            GenericWindowPostMessage('FAILURE_TOAST', {
              toastMessage: event.data || 'Something went wrong !!!',
            });
            break;

          default:
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, []);

  const removeEmployee = () => {
    setLoading(true);
    setIsUpdating(true);
    const params = {
      employee_ids: [employeeId],
      select_all_employees: false,
      operation: 'remove',
    };
    updatePtoPolicy(eventEmitter, params, policyId, 'viewTImeOff');
  };

  const openRemoveEmpModal = id => {
    setRemoveEmpConfirmModal(true);
    setPolicyId(id);
  };

  const getAccuralHistoryDetails = pageNo => {
    setLoading(true);
    getAccuralHistory(eventEmitter, {
      id: employeeId,
      policy_id: policyId,
      page_size: 10,
      page: pageNo,
      paginate: true,
    });
  };

  const assignPolicyToEmployee = policyId => {
    if (!policyId) {
      GenericWindowPostMessage('FAILURE_TOAST_BALANCE', {
        toastMessage: 'Please select at least one policy !!!',
      });
      return;
    }
    setLoading(true);
    setIsUpdating(true);
    const params = {
      employee_ids: [employeeId],
      select_all_employees: false,
      operation: 'add',
    };
    updatePtoPolicy(eventEmitter, params, policyId, 'viewTImeOff');
  };
  if (loader) {
    return (
      <div className={`${styles['historySliderLoader']}`} role="status">
        <span>Loading...</span>
      </div>
    );
  }

  return (
    <>
      <Box margin={{ left: 'medium', right: 'small' }} flex={{ grow: 1 }}>
        {!slider && (
          <Table
            data={employeePolicy.length ? employeePolicy : []}
            openRemoveEmpModal={openRemoveEmpModal}
            handleSubmit={handleSubmit}
            loading={loading}
            companyPolicy={companyPolicyList}
            assignPolicyToEmployee={assignPolicyToEmployee}
            isUpdating={isUpdating}
          />
        )}
        {slider && (
          <TimeOffPoliciesSlider
            data={accuralHistoryData}
            pageSize={pageSize}
            getAccuralHistoryDetails={getAccuralHistoryDetails}
            loading={loading}
            policyType={query?.policy_type}
          />
        )}
        {removeEmpConfirmModal && (
          <EmployeeRemoveModal
            setRemoveEmpConfirmModal={setRemoveEmpConfirmModal}
            removeEmployee={removeEmployee}
            isDeleting={isUpdating}
          />
        )}
      </Box>
    </>
  );
};

export default PtoTimeOffPoliciesPage;
