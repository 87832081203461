import React, {useEffect, useState} from 'react'
import {Select,ThemeContext} from 'grommet'
import _ from 'lodash';
import './styles.module.scss'



const SingleSelectAtom = (
 props
) => {
    const {
        field, layout, label, help, options,
        getOptions, onChange, value,disabled, ...rest
      } = props;
  const [optionsList, setOptions] = useState(options)
  const [defaultOption, setDefaultOptions] = useState({label:'Loading ...',value:0})
  
  useEffect(() => {
    setOptions(options)
  }, [options])
  
  const selectClass = {
    select: {
      background: disabled && '#070707',
      textOverflow:'ellipsis !important',
      icons:{
        color:disabled && 'white',
      },  
    },
  }
  
  const handleChange = val => {
      if(onChange)
      {
          onChange(val)
      }
  }

  return (
    <ThemeContext.Extend value={selectClass}>
    <Select
      options={optionsList}
      style={{textOverflow:'ellipsis'}}
      {...field}
      {...rest}
      emptySearchMessage="No result found"
      onChange={val => handleChange(val)}
      onSearch={text => {
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
        const exp = new RegExp(escapedText, 'i')
        setOptions(options.filter(o => exp.test(o.label)))
      }}
      menuContainerStyle={{ zIndex: 999 }}
      dropHeight="medium"
      //value={'3560'}
      searchPlaceholder='Search...'
      labelKey='label'
      valueKey={{key: 'value', reduce: true}}
      className={disabled && "select-disable"}
      disabled={disabled}
    />
    </ThemeContext.Extend>
  )
}

export default SingleSelectAtom
