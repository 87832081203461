import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class Date {
  constructor(data) {
    this.dateFormat =
      data.dateFormatDisplay === 'MM/DD/YYYY'
        ? 'm/d/Y'
        : data.dateFormatDisplay === 'DD/MM/YYYY'
        ? 'd/m/Y'
        : data.dateFormatDisplay === 'YYYY/MM/DD'
        ? 'Y/m/d'
        : data.dateFormatDisplay === 'YYYY/DD/MM'
        ? 'Y/d/m'
        : data.dateFormatDisplay === 'MMMM DD, YYYY'
        ? 'F d, Y'
        : data.dateFormatDisplay === 'MMM DD, YYYY'
        ? 'M d, Y'
        : data.dateFormatDisplay === 'ddd, DD MMM YYYY'
        ? 'D, d M Y'
        : ''
    this.dateFormatDisplay = data.dateFormatDisplay || ''
    this.timeFormat24Hrs = data.timeFormat24Hrs || ''
  }
}

export default Date
