// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'

// Application imports
import departmentApi from 'department/gateways/department-api'
import {DEPARTMENT_EVENTS} from 'pages/shiftManagement/constants'

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */
async function fetchDepartments(eventEmitter, params) {
	try {
		eventEmitter.emit(DEPARTMENT_EVENTS.SHOW_LOADER)
		const data = await departmentApi.fetchDepartments(params)
		eventEmitter.emit(DEPARTMENT_EVENTS.GET_DEPARTMENT_SUCCESS, data)
		eventEmitter.emit(DEPARTMENT_EVENTS.HIDE_LOADER)
	} catch (err) {
		eventEmitter.emit(DEPARTMENT_EVENTS.GET_DEPARTMENT_FAILURE, err)
	}
}

export {fetchDepartments}
