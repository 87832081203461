// Lib Imports
import React from 'react'
import {Box, TextInput, Anchor, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'

// CORE Imports
import PasswordInput from 'granite/components/PasswordInput'
import NumberInput from 'granite/components/NumberInput'
import FormField from 'granite/components/FormField'

// Application Imports
import REGISTER_EVENTS from '../constants'

const RegisterForm = ({eventEmitter, registerClicked}) => {
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required('Company Name is required'),
    company_phone: Yup.string()
      .required('Company Phone is required')
      .min(10, 'Not valid')
      .max(10, 'Not valid'),
    first_name: Yup.string().required('First Name is required'),
    email: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .email('Invalid email')
      .required('Email is required'),
    password: Yup.string()
      .max(50, 'Too Long!')
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
        'Atleast 6 char, One capital, One number',
      )
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must be same.')
      .required('Confirm Password is required'),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    try {
      await registerClicked(values)
      eventEmitter.emit(REGISTER_EVENTS.REGISTER_SUCCESS)
    } catch (e) {
      eventEmitter.emit(REGISTER_EVENTS.REGISTER_FAILURE)
      if (e.errors) setErrors(e.errors)
    }
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        confirm_password: '',
        company_name: '',
        company_phone: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="company_name"
            label="Company Name"
            placeholder="ABC Pvt Ltd"
            error={touched.company_name && errors.company_name}
          >
            <TextInput
              name="company_name"
              placeholder="Enter Company Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company_name}
            />
          </FormField>
          <FormField
            name="company_phone"
            label="Company Phone"
            placeholder="999999999"
            error={touched.company_phone && errors.company_phone}
          >
            <NumberInput
              name="company_phone"
              placeholder="Enter Company Phone"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company_phone}
            />
          </FormField>
          <FormField
            name="first_name"
            label="First Name"
            placeholder="John"
            error={touched.first_name && errors.first_name}
          >
            <TextInput
              name="first_name"
              placeholder="Enter First Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
            />
          </FormField>
          <FormField
            name="last_name"
            placeholder="Doe"
            label="Last Name"
            error={touched.last_name && errors.last_name}
          >
            <TextInput
              placeholder="Enter Last Name"
              name="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
            />
          </FormField>
          <FormField
            name="email"
            label="Email"
            error={touched.email && errors.email}
          >
            <TextInput
              placeholder="name@company.com"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
          <FormField
            name="password"
            label="Password"
            required
            error={touched.password && errors.password}
          >
            <PasswordInput
              placeholder="choose password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
          </FormField>
          <FormField
            name="confirm_password"
            label="Confirm Password"
            error={touched.confirm_password && errors.confirm_password}
          >
            <PasswordInput
              placeholder="Enter confirm password"
              name="confirm_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
            />
          </FormField>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            justify="between"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{vertical: 'small'}}
              label={isSubmitting ? 'Register in ...' : 'Register'}
              disabled={isSubmitting}
              primary
            />
            <Link to="/login">
              <Anchor
                as="span"
                label="Log in to existing account"
                size="small"
                margin={{vertical: 'small'}}
              />
            </Link>
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default RegisterForm
