import React from 'react'
import {Box, Button, Heading, Layer, Text} from 'grommet'
import {Close} from 'grommet-icons'

// CORE imports
import Divider from 'granite/components/Divider'


const EmployeeRemoveModal = ({
  removeEmployeeFromShift,
  selectEmployee,
  setselectEmployee,
  isDeleting
}) => {
  const closeEmployeeModal = () => {
    // setSubmitShift({isSubmitting: false})
    setselectEmployee(false)
  }
  return (
        <Layer>
          <Box
            background="light-1"
            width="xlarge"
            round="small"
            overflow={{vertical: 'auto'}}
          >
            <Box
              direction="row"
              justify="between"
              align="center"
              pad={{top: 'small', bottom: 'xsmall', horizontal: 'medium'}}
              height={{min: 'auto'}}
            >
              <Heading level={3} color="dark-1" size="xsmall" margin="none">
                Remove Employee
              </Heading>
              <Button icon={<Close size="small" />} onClick={closeEmployeeModal} />
            </Box>
            <Divider
              background="accent-1"
              margin={{bottom: 'small'}}
              height="vsmall"
            />
            <Box
              direction="column"
              gap="medium"
              pad={{top: 'small', horizontal: 'large'}}
            >
              <Text>Are you sure want to remove the Employee?</Text>
              <span style={{textAlign:'right'}}><Button
                primary
                alignSelf="end"
                color="accent-1"
                size="small"
                label={isDeleting ? "Saving..." : "Yes"}
                disabled={isDeleting}
                onClick={()=>removeEmployeeFromShift(selectEmployee)}
                margin={{bottom: 'large'}}
              />
              <Button
                primary
                style={{marginLeft:'20px'}}
                color="accent-1"
                size="small"
                label="No"
                onClick={closeEmployeeModal}
                margin={{bottom: 'large'}}
              /></span>
            </Box>
          </Box>
        </Layer>
  )
}


export default EmployeeRemoveModal
