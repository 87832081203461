import React, {useState} from 'react'
import {withTheme} from 'styled-components'
import {Box, TextInput, Button,Image} from 'grommet'
import {FormLock} from 'grommet-icons/icons/FormLock'
import {View} from 'grommet-icons/icons/View'
import PropTypes from 'prop-types'

const PasswordInput = ({error, theme, ...rest}) => {
  const [reveal, setReveal] = useState(false)
  const borderColor = theme.global.colors['status-critical']

  // FIXME: pad should not be used
  return (
    <Box
      direction="row"
      align="center"
      round="xsmall"
      border={{color: error ? borderColor : null}}
      background="white"
      pad="hair"
    >
      <TextInput plain type={reveal ? 'text' : 'password'} {...rest} />
      <Button
        as="div"
        icon={reveal ? <Image
          height="18"
          margin={{top:'3px'}}
          src={process.env.PUBLIC_URL + '/img/eye-close.svg'}
        /> : 
        <Image
          height="15"
          margin={{top:'3px'}}
          src={process.env.PUBLIC_URL + '/img/eye-open.svg'}
        />}
        onClick={() => setReveal(!reveal)}
        style={{padding: '3px 8px', 'borderRadius':'0px'}}
      />
    </Box>
  )
}

PasswordInput.defaultProps = {
  error: null,
  theme: null,
}

PasswordInput.propTypes = {
  error: PropTypes.object,
  theme: PropTypes.object,
}

export default withTheme(PasswordInput)
