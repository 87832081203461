// Core imports
import GraniteError from 'utils/granite-error';
import GraniteAPIClient from 'utils/granite-client';
import SurveyEntity from 'webView/entities/webView';
/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */

async function fetchFormResponse(id) {
  try {
    const  {data}  = await GraniteAPIClient().get(`/feedback/response/${id}/`);
    console.log('fdfdfsdf',data);
    if (data?.template) {
      const arr1 = [];
      let arr2 = [];
      let newAns = [];
      arr1.push(data?.template?.template_data?.questions);
      arr2.push(data?.response?.questions);
      let result = arr1.map((ques, qindex) => {
        return ques.map(q => ({
          ...q,
          response: arr2[qindex].find(ans => ans.sequence_of_configuration === q.sequence_of_configuration).answer,
        }));
      });
      result.forEach((res, resId) => {
        res.forEach(newi => {
          const e = new SurveyEntity(newi);
          newAns.push(e);
        });
      });

      return {
        results: newAns,
        surveyName: data?.template.template_data?.label,
        date: data?.create_date_display,
        empName: data?.user?.full_name,
      };
    } else {
      return { results: [] };
    }
  } catch (error) {
    console.log(error,'hhhhfgf')
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}
export default {
  fetchFormResponse,
};
