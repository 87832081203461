const DEPARTMENT_EVENTS = {
	GET_DEPARTMENT_SUCCESS: 'GET_DEPARTMENT_SUCCESS',
	GET_DEPARTMENT_FAILURE: 'GET_DEPARTMENT_FAILURE',
	SHOW_LOADER: 'SHOW_LOADER',
	HIDE_LOADER: 'HIDE_LOADER',
}

const TEAM_EVENTS = {
	GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
	GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
}

const COMPANY_EVENTS = {
	GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
	GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',
	GET_BASIC_SETTING_SUCCESS: 'GET_BASIC_SETTING_SUCCESS',
	GET_BASIC_SETTING_FAILURE: 'GET_BASIC_SETTING_FAILURE'
}

const USER_EVENTS = {
	GET_USER_SUCCESS: 'GET_USER_SUCCESS',
	GET_USER_FAILURE: 'GET_USER_FAILURE',
}

const EMPLOYEE_EVENTS = {
	GET_EMPLOYEE_SUCCESS: 'GET_EMPLOYEE_SUCCESS',
	GET_EMPLOYEE_FAILURE: 'GET_EMPLOYEE_FAILURE',
	GET_EMPLOYEE_DATA_SUCCESS: 'GET_EMPLOYEE_DATA_SUCCESS',
	GET_EMPLOYEE_DATA_FAILURE: 'GET_EMPLOYEE_DATA_FAILURE',
}

const SHIFT_EVENTS = {
	CREATE_SHIFT_SUCCESS: 'CREATE_SHIFT_SUCCESS',
	CREATE_SHIFT_FAILURE: 'CREATE_SHIFT_FAILURE',
	GET_ALL_SHIFTS_SUCCESS: 'GET_ALL_SHIFTS_SUCCESS',
	GET_ALL_SHIFTS_FAILURE: 'GET_ALL_SHIFTS_FAILURE',
	GET_SHIFT_DETAILS_SUCCESS: 'GET_SHIFT_DETAILS_SUCCESS',
	GET_SHIFT_DETAILS_FAILURE: 'GET_SHIFT_DETAILS_FAILURE',
	UPDATE_SHIFT_SUCCESS: 'UPDATE_SHIFT_SUCCESS',
	UPDATE_SHIFT_FAILURE: 'UPDATE_SHIFT_FAILURE',
	DELETE_SHIFT_SUCCESS: 'DELETE_SHIFT_SUCCESS',
	DELETE_SHIFT_FAILURE: 'DELETE_SHIFT_FAILURE',
	ADD_EMPLOYEE_TO_SHIFT_SUCCESS: 'ADD_EMPLOYEE_TO_SHIFT_SUCCESS',
	ADD_EMPLOYEE_TO_SHIFT_FAILURE: 'ADD_EMPLOYEE_TO_SHIFT_FAILURE',
	GET_SHIFTS_STATS_SUCCESS: 'GET_SHIFTS_STATS_SUCCESS',
	GET_SHIFTS_STATS_FAILURE: 'GET_SHIFTS_STATS_FAILURE',
	GET_SHIFT_DATA_SUCCESS: 'GET_SHIFT_DATA_SUCCESS',
	GET_SHIFT_DATA_FAILURE: 'GET_SHIFT_DATA_FAILURE',
	UPDATE_EMPLOYEE_SHIFT_MAP_SUCCESS: 'UPDATE_EMPLOYEE_SHIFT_MAP_SUCCESS',
	UPDATE_EMPLOYEE_SHIFT_MAP_FAILURE: 'UPDATE_EMPLOYEE_SHIFT_MAP_FAILURE',
	SHOW_LOADER: 'SHOW_LOADER',
	HIDE_LOADER: 'HIDE_LOADER',
}

export {TEAM_EVENTS, DEPARTMENT_EVENTS, COMPANY_EVENTS, EMPLOYEE_EVENTS, SHIFT_EVENTS, USER_EVENTS}
