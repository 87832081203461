import React from 'react'
import {Route} from 'react-router-dom'

import {getAuthToken} from 'utils/auth-singleton'
import LinearLayout from 'granite/components/LinearLayout'

const PublicRoute = ({component: Component, layout, ...rest}) => {
  const authToken = getAuthToken()
  if (authToken) {
    // Call getProfile async, so that we can display profile in Header
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (layout === 'PlainLayout') {
          return <Component {...props} />
        }
        return (
          <LinearLayout>
            <Component {...props} />
          </LinearLayout>
        )
      }}
    />
  )
}

export default PublicRoute
