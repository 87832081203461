const messages = {
    PAGE_HEADING: 'Time Off Policies',
    PAGE_SUB_HEADING: 'View and manage time off policies',
    ADD_POLICY_LABEL :'Add Pilicy',
    MANAGE_ADD_EDIT_POLICY_LABEL :'+ Add/Edit Employees',
    EDIT_POLICY_LABEL :'Add Policy',
    DELETE_POLICY_LABEL :'Delete Policy'
  }
  
  export default messages
  