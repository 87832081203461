import React from 'react'
import {Link} from 'react-router-dom'
import {Box, Button, Text} from 'grommet'
import PropTypes from 'prop-types'

const Alert = ({
  closeBtnLabel,
  onClose,
  id,
  link,
  message,
  showCloseBtn,
  type,
}) => {
  const mapTypeToColor = type => {
    let color = 'status-warning'
    switch (type) {
      case 'warning':
        color = 'status-warning'
        break
      case 'info':
        color = 'neutral-3'
        break
      case 'success':
        color = 'status-ok'
        break
      case 'danger':
        color = 'status-error'
        break
      case 'secondary':
        color = 'status-unknown'
        break
      default:
        break
    }
    return color
  }
  return (
    <Box
      direction="row"
      round="xsmall"
      pad="small"
      background={mapTypeToColor(type)}
    >
      <Link to={link}>
        <Box flex={{grow: 1}}>
          <Text color="light-1" size="alert">
            {message}
          </Text>
        </Box>
      </Link>
      {showCloseBtn && (
        <Button
          color="light-1"
          label={closeBtnLabel}
          onClose={() => onClose(id)}
          plain
        />
      )}
    </Box>
  )
}

Alert.defaultProps = {
  closeBtnLabel: 'X',
  showCloseBtn: true,
  link: '#',
  type: 'warning',
}

Alert.propTypes = {
  closeBtnLabel: PropTypes.string,
  showCloseBtn: PropTypes.bool,
  link: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(['warning', 'info', 'success', 'danger', 'secondary']),
}

export default Alert
