import React from 'react';
import ReactTable from 'react-table';
import { getPageSizeAndNo, setCookies } from 'utils/auth-singleton';

const GenericReactTable = ({
  data,
  loading,
  onRowClick,
  onFetchData,
  columns,
  setLoading,
  totalNoOfPage,
  tableName,
}) => {
  const pageChange = item => {
    setLoading(true);
    setCookies(`pageNo${tableName}`, item);
    onFetchData();
  };

  const pageSizeChange = (pageSize, pageIndex) => {
    setCookies(`pageSize${tableName}`, pageSize);
    setCookies(`pageNo${tableName}`, 0);
    setLoading(true);
    onFetchData();
  };

  return (
    <ReactTable
      data={data.length ? data : []}
      noDataText="No Records Found"
      manual
      page={getPageSizeAndNo(`pageNo${tableName}`) - 1}
      pages={totalNoOfPage}
      loading={loading}
      pageSize={getPageSizeAndNo(`pageSize${tableName}`)}
      getTrProps={onRowClick}
      onPageSizeChange={pageSizeChange}
      onPageChange={pageChange}
      minRows={1}
      style={{ background: '#FFF' }}
      columns={columns}
    />
  );
};

export default GenericReactTable;
