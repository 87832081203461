import React from 'react'
import {Box, Button} from 'grommet'
import {Formik, Field} from 'formik'
import * as Yup from 'yup'

import moment from 'moment'
import ErrorMessage from 'pages/components/PTO/ErrorMessage';

import DateTimePickerAtom from 'generics/DateTimePickerFormik'

const FilterComponent = ({applyFilter, dateFormat, dates}) => {
  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required('Start Date is required'),
    end_date: Yup.date().required('End Date is required'),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    const formattedStartDate = moment(values.start_date).format()
    const formattedEndDate = moment(values.end_date).format()
    if (formattedStartDate > formattedEndDate)
      return setErrors({start_date: 'Start date should be less than end date.'})

    setSubmitting(true)
    applyFilter(values)
  }

  return (
    <Formik
      initialValues={{
        start_date: moment(dates.startDate, "DD-MM-YYYY").toISOString() || '',
        end_date: moment(dates.endDate, "DD-MM-YYYY").toISOString() || '',
        status_type: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box direction="row" gap="small">
            <Box direction="row" gap="small">
            <ErrorMessage  error={ touched.start_date && errors.start_date }>
                <Field
                  id="start_date"
                  name="start_date"
                  placeholder="Start Date"
                  component={DateTimePickerAtom}
                  options={{dateFormat}}
                />
              </ErrorMessage>
              <ErrorMessage error={touched.end_date && errors.end_date}>
                <Field
                  id="end_date"
                  name="end_date"
                  placeholder="End Date"
                  component={DateTimePickerAtom}
                  options={{dateFormat}}
                />
              </ErrorMessage>
            </Box>
            <Box gap="small" direction="row" pad={{bottom: 'small'}}>
              <ErrorMessage>
                <Button
                  label="Apply"
                  color="accent-1"
                  pad="medium"
                  type="submit"
                  alignSelf="end"
                  primary
                />
              </ErrorMessage>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default FilterComponent
