// Lib Imports
import React from 'react'
import {Box, TextInput, Anchor, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'

// CORE Imports
import FormField from 'granite/components/FormField'

// Application Imports
import ACCOUNT_VERIFICATION_EVENTS from '../constants'

const VerificationForm = ({eventEmitter, verifyAccountClicked}) => {
  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Verification token is required'),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    try {
      await verifyAccountClicked(values)
      eventEmitter.emit(ACCOUNT_VERIFICATION_EVENTS.VERIFICATION_SUCCESS)
    } catch (e) {
      eventEmitter.emit(ACCOUNT_VERIFICATION_EVENTS.VERIFICATION_FAILURE)
      if (e.errors) setErrors(e.errors)
    }
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        token: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="token"
            label="Verification Token"
            error={touched.token && errors.token}
          >
            <TextInput
              name="token"
              placeholder="Enter Verification Token"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.token}
            />
          </FormField>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            justify="between"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{vertical: 'small'}}
              label={isSubmitting ? 'Veriyfing ...' : 'Verify'}
              disabled={isSubmitting}
              primary
            />
            <Link to="/register">
              <Anchor
                as="span"
                label="Create new account"
                size="small"
                margin={{vertical: 'small'}}
              />
            </Link>
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default VerificationForm
