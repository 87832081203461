import React from 'react'
import {Box, Button, Heading, Layer, Text, InfiniteScroll} from 'grommet'
import {Close} from 'grommet-icons'

// CORE imports
import Divider from 'granite/components/Divider'

const DeletePolicyModal = ({
  deletePolicy,
  setDeleteModal,
  isDeleting,
  deleteAbleData,
}) => {
  const closeDeleteModal = () => {
    setDeleteModal(false)
  }

  const EmployeeList = ({item}) => (
    <Text>
      <li>{item.employee_name}</li>
    </Text>
  )

  return(
    <Layer modal={true} position={'center'}>
        <Box
          background="light-1"
          width="large"
          round="small"
          overflow={{vertical: 'auto'}}
        >
          <Box
            direction="row"
            justify="between"
            align="center"
            pad={{top: 'small', bottom: 'xsmall', horizontal: 'medium'}}
            height={{min: 'auto'}}
          >
            <Heading level={3} color="dark-1" size="xsmall" margin="none">
            Inactivate Policy
            </Heading>
            <Button icon={<Close size="small" />} onClick={closeDeleteModal} />
          </Box>
          <Divider
            background="accent-1"
            margin={{bottom: 'small'}}
            height="vsmall"
          />
          <Box
            direction="column"
            gap="small"
            pad={{top: 'small', horizontal: 'large'}}
          >
            {deleteAbleData.enrolled_employees_count ? <>
              <Text gap="xxsmall">
                Annual leaves will be removed for all employees currently using
                this policy. Are you sure you want to do this ?
              </Text>
              <Text color="black" weight="bold">
                Employees affected
              </Text>
              <Box
                gap="xsmall"
                margin={{top: 'xxsmall', bottom: 'xxsmall', left: 'medium'}}
                overflow={{vertical: 'auto'}}
              >
                <InfiniteScroll
                  items={deleteAbleData.employee_list_names}
                  step={5}
                >
                  {item => <EmployeeList key={item} item={item} />}
                </InfiniteScroll>
              </Box>
            </>:<Text>Are you sure you want to mark this policy inactive?</Text>}
            <span style={{textAlign: 'right'}}>
              <Button
                primary
                alignSelf="end"
                color="accent-2"
                size="small"
                label={isDeleting ? 'Submitting...' : 'Yes'}
                disabled={isDeleting}
                onClick={() => {
                  deletePolicy()
                }}
                margin={{bottom: 'large'}}
              />
              <Button
                primary
                style={{marginLeft: '20px'}}
                color="accent-1"
                size="small"
                label="No"
                disabled={isDeleting}
                onClick={closeDeleteModal}
                margin={{bottom: 'large'}}
              />
            </span>
          </Box>
        </Box>
      </Layer>
  )
}

export default DeletePolicyModal
