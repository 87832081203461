// Lib Imports
import React from 'react'
import {Box, Button, Heading, Image, Text} from 'grommet'
import {withTheme} from 'styled-components'
import {Link} from 'react-router-dom'

// Application Imports
import messages from 'messages/home'
import {getAuthToken} from 'utils/auth-singleton'


const Home = ({theme}) => {
  const authToken = getAuthToken()
  return (
    <Box fill>
      <Box align="start" pad={{left: '50px', top:'13vw'}} gap="xxsmall">
        <Image
          height="85"
          width="130"
          style={{'marginTop':'5px'}}
          src={process.env.PUBLIC_URL + '/img/logo_blue.png'}
          alt="menu"
        />
         <Image
          height="27px"
          width="150px"
          src={process.env.PUBLIC_URL + '/img/brand_name.png'}
          alt="menu"
        />
        <Heading level={3} color="brand" margin={{top:'0.9em !important', bottom:'8px !important'}}>
          {messages.HEADING}
        </Heading>
        <Text size="medium" color="#3d556b" style={{'fontWeight': '400', 'fontSize':'15px' , 'margin':'5px 0'}}>
          {messages.SUB_HEADING}
        </Text>
        <Link to="/login" style={{'marginTop': '1.5em'}}>
          <Button primary color="accent-1" style={{'font-size': '15px'}} label={authToken ? messages.BTN_DASHBOARD_LABEL : messages.BTN_LABEL} />
        </Link>
      </Box>
    </Box>
  )
}

export default withTheme(Home)
