import React, { useEffect, useState, useCallback } from 'react';
import webViewApi from 'webView/gateways/webView-api';
import Singleton from 'utils/singleton';
import queryString from 'query-string';
import SectionLoaderAtom from 'atoms/SectionLoader';
import FormPreviewComponent from 'pages/webView/components/FormPreviewComponent';

const FormPreveiw = props => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const singleton = new Singleton();
  const {
    location: { search },
  } = props;
  const query = queryString.parse(search);

  const fetchData = useCallback(async id => {
    try {
      const data = await webViewApi.fetchFormResponse(id);
      setData(data);
    } catch (e) {
      console.log(e);
    }
    setLoader(false);
  }, []);

  useEffect(() => {
    if (singleton.oAuthToken === null) {
      singleton.oAuthToken = query.oAuthToken;
    }
    
    if(singleton.context === null){
      singleton.context = query.context;
    }

    fetchData(+query.responseId);
  }, []);

  if (loader) {
    return <SectionLoaderAtom active />;
  }
  return <FormPreviewComponent data={data} />;
};

export default FormPreveiw;
