import React, { useEffect, useState } from 'react';
import { Box, Text } from 'grommet';
import PropTypes from 'prop-types';
import SingleSelectFormComponent from 'atoms/SingleSlectFormComponent';
import MultiSelectFormComponent from 'atoms/MultiSelectFormComponent';
import FreeSelectFormComponent from 'atoms/FreeSelectFormComponent';
import FilePreviewComponent from 'atoms/FilePreviewComponent';

const FormPreviewComponent = ({ data }) => {
  const [formData, setData] = useState([]);

  useEffect(()=>{
    setData(data)
  },[data])

  return (
    <Box pad={'medium'}>
      <Box flex align='center' gap='small' pad={{bottom:'medium'}}>
        <Text color={'brand'} size='20px'>{formData.surveyName}</Text>
      </Box>
      {formData && formData?.results?.length ? (
        formData?.results.map((item, index) => (
          <Box className="mr-2" key={`form_index`} height={{ min: 'fit-content' }}>
            {(item.definition === 'SINGLE_SELECT' || item.definition === 'single_select') && (
              <Box>
                <SingleSelectFormComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
            {(item.definition === 'MULTI_SELECT' || item.definition === 'multi_select') && (
              <Box>
                <MultiSelectFormComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
            {(item.definition === 'FREE_TEXT' || item.definition === 'free_text') && (
              <Box>
                <FreeSelectFormComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
            {(item.definition === 'BOOLEAN' || item.definition === 'boolean') && (
              <Box>
                <SingleSelectFormComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
            {(item.definition === 'DATE_TIME_SELECT' || item.definition === 'date_time_select' || item.definition === 'DATE') && (
              <Box>
                <FreeSelectFormComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
            {(item.definition === 'TIME_SELECT' || item.definition === 'time_select') && (
              <Box>
                <FreeSelectFormComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
            {(item.definition === 'FILE_UPLOAD' || item.definition === 'SIGNATURE') && (
              <Box>
                <FilePreviewComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
            {(item.definition === 'NUMBER' ) && (
              <Box>
                <FreeSelectFormComponent data={item} index={index} mandatory={item.mandatory_question} />
                <hr className="mt-0 mb-2" />
              </Box>
            )}
          </Box>
        ))
      ) : (
        <Box flex justify="center" align="center">
          <Text>No Records Found</Text>
        </Box>
      )}
    </Box>
  );
};

FormPreviewComponent.propTypes = {
  data: PropTypes.object,
};
export default FormPreviewComponent;
