const LOGIN_MESSAGES = {
  HEADING: 'Sign in',
  INVALID_CREDENTIALS: 'Email & Password are required.',
  LOGIN_SUCCESS: 'Logged in successfully',
  LOGIN_FAILURE: "Email and Password don't match",
  EMAIL_TOO_SHORT: 'Too Short',
  EMAIL_TOO_LONG: 'Too Long',
  EMAIL_INVALID: 'Invalid Email',
  EMAIL_REQUIRED: 'Email is required',
  PASS_TOO_SHORT: 'Password is too short - should be 6 chars minimum.',
  PASS_TOO_LONG: 'Too Long!',
  PASS_REQUIRED: 'Password is required',
  EMAIL_LABEL: 'Email Address/Username',
  PASS_LABEL: 'Password',
  EMAIL_PLACEHOLDER: 'Email Address/Username',
  PASS_PLACEHOLDER: 'Password',
  SUBMIT_BUTTON_LABEL_SUBMITTING: 'Sign in...',
  SUBMIT_BUTTON_LABEL: 'Sign in',
  FORGOT_PASS_LINK_LABEL: 'Forgot Password?',
  REGISTER_LINK_LABEL: "Don't have an account?",
  REGISTER_LINK_TEXT: 'Start my free trial',
  KIOSK_LINK_LABEL: 'Looking for the kiosk?',
  KIOSK_LINK_TEXT: 'Click here',
}

export default LOGIN_MESSAGES
