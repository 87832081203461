import React from 'react';
import { Box, Text, Pagination } from 'grommet';
import styles from '../styles.module.scss';

import Divider from 'granite/components/Divider';

const TimeOffPoliciesSlider = ({ data, pageSize, getAccuralHistoryDetails, loading, policyType }) => {
  return (
    <Box height="full">
      <Box>
        <Box
          className={`${styles['slider-table-header-wrapper']}`}
          direction="row"
          gap="medium"
          height="xsmall"
          pad="small"
        >
          <Box width={policyType === 'PAID_HOLIDAYS' ? '40%' : '34%'} align="center">
            <Text className={`${styles['table-header-text']}`}>Date</Text>
          </Box>
          <Box width={policyType === 'PAID_HOLIDAYS' ? '60%' : '33%'} align="center">
            <Text className={`${styles['table-header-text']}`}>
              {policyType === 'PAID_HOLIDAYS' ? 'Name' : 'Accurals'}
            </Text>
          </Box>
          {policyType !== 'PAID_HOLIDAYS' && (
            <Box width="33%" align="center">
              <Text className={`${styles['table-header-text']}`}>
                Balance
              </Text>
            </Box>
          )}
        </Box>
        <Divider background="accent-1" margin={{ top: 'small', bottom: 'small' }} />
        {loading ? (
          <div className="newSliderLoadingActivities">
            <span>Loading...</span>
          </div>
        ) : (
          <Box>
            {data?.length ? (
              data.map((item, index) => {
                return (
                  <Box
                    className={`${styles['slider-table-content-wrapper']}`}
                    direction="row"
                    gap="medium"
                    height="xsmall"
                    background={index % 2 !== 0 ? '#F0F0F0' : 'white'}
                    justify="center"
                  >
                    <Box width={policyType === 'PAID_HOLIDAYS' ? '40%' : '34%'} align="center">
                      <Text className={`${styles['table-content-text']}`}>
                        {policyType === 'PAID_HOLIDAYS' ? item?.date_of_holiday : item.date_of_accural}
                      </Text>
                    </Box>
                    <Box width={policyType === 'PAID_HOLIDAYS' ? '60%' : '33%'} align="center">
                      <Text className={`${styles['table-content-text']}`}>
                        {policyType === 'PAID_HOLIDAYS' ? item?.name_of_holiday : item.leaves_accurred}
                      </Text>
                    </Box>
                    {policyType !== 'PAID_HOLIDAYS' && (
                      <Box width="33%" align="center">
                        <Text className={`${styles['table-content-text']}`}>{item?.employee_balance}</Text>
                      </Box>
                    )}
                  </Box>
                );
              })
            ) : (
              <div className="newSliderLoadingActivities">
                <span>No Records Found</span>
              </div>
            )}
          </Box>
        )}
        <Box justify="center" width="full" direction="row" pad={{ top: 'large' }} bottom="0px">
          {pageSize ? (
            <Pagination
              size="small"
              numberItems={pageSize}
              onChange={e => {
                getAccuralHistoryDetails(e.page);
              }}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default TimeOffPoliciesSlider;
