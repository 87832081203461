import PropTypes from 'prop-types'
import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from 'react'

const DataGridContext = createContext()

const DataGrid = ({columns, data, hasFirstColumn, children, ...rest}) => {
  const [selectedIds, setSelectedIds] = useState([])

  const toggleSelectedId = useCallback(
    id => {
      let ids = [...selectedIds]
      const index = ids.indexOf(id)
      if (index === -1) {
        ids.push(id)
      } else {
        ids.splice(index, 1)
      }
      setSelectedIds(ids)
    },
    [selectedIds],
  )

  const toggleSelectedAll = useCallback(
    select => {
      let ids = []
      if (select) {
        ids = data.map(item => item.id)
      }
      setSelectedIds(ids)
    },
    [selectedIds],
  )

  const value = useMemo(
    () => ({
      // check for [visible] key in columns, if there is no visible key then set its visible true
      columns: columns.map(col => {
        col.visible = col.hasOwnProperty('visible') ? col.visible : true
        return col
      }),
      data,
      hasFirstColumn,
      selectedIds,
      toggleSelectedId,
      toggleSelectedAll,
    }),
    [columns, selectedIds],
  )

  return (
    <DataGridContext.Provider value={value}>
      {children}
    </DataGridContext.Provider>
  )
}

function useDataGridContext() {
  const context = useContext(DataGridContext)
  if (!context) {
    throw new Error(
      `DataGrid children components cannot be rendered outside the DataGrid component`,
    )
  }
  return context
}

DataGrid.defaultProps = {
  columns: [],
  data: [],
  hasFirstColumn: false,
  children: null,
}

DataGrid.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  hasFirstColumn: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export {DataGrid, useDataGridContext}
