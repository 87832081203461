import React from 'react'
import {Box, Button, Heading, Layer, Text} from 'grommet'
import {Close} from 'grommet-icons'

// CORE imports
import Divider from 'granite/components/Divider'

const EmployeeRemoveModal = ({removeEmployee, setRemoveEmpConfirmModal , isDeleting=false}) => {
  const closeDeleteModal = () => {
    setRemoveEmpConfirmModal(false)
  }
  return (
    <Layer modal={true} position={'top'}>
      <Box
        background="light-1"
        round="small"
        overflow={{vertical: 'auto'}}
      >
        <Box
          direction="row"
          justify="between"
          align="center"
          pad={{top: 'small', bottom: 'xsmall', horizontal: 'medium'}}
          height={{min: 'auto'}}
        >
          <Heading level={3} color="dark-1" size="xsmall" margin="none">
            Remove Employee
          </Heading>
          <Button icon={<Close size="small" />} onClick={closeDeleteModal} />
        </Box>
        <Divider
          background="accent-1"
          margin={{bottom: 'small'}}
          height="vsmall"
        />
        <Box
          direction="column"
          gap="small"
          pad={{top: 'small', horizontal: 'large'}}
        >
          <Text>
            Are you sure you want to remove the employee from this policy ?
          </Text>
          <span style={{textAlign: 'right'}}>
            <Button
              primary
              alignSelf="end"
              color="accent-2"
              size="small"
              label = {isDeleting ?'Removing ...':'Yes'}
              disabled ={isDeleting}
              onClick={() => {
                removeEmployee()
              }}
              margin={{bottom: 'large'}}
            />
            <Button
              primary
              style={{marginLeft: '20px'}}
              color="accent-1"
              size="small"
              label="No"
              disabled={isDeleting}
              onClick={closeDeleteModal}
              margin={{bottom: 'large'}}
            />
          </span>
        </Box>
      </Box>
    </Layer>
  )
}

export default EmployeeRemoveModal
