import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class Employees {
	constructor(data) {
		this.activated = data.activated || ''
		this.activation_code = data.activation_code || ''
		this.address_line_one = data.address_line_one || ''
		this.address_line_two = data.address_line_two || ''
		this.city = data.city || ''
		this.company_id = data.company_id || ''
		this.company_name = data.company_name || ''
		this.country = data.country || ''
		this.department_name = data.department_name || ''
		this.email = data.email || ''
		this.employee_id = data.employee_id || ''
		this.employee_role = data.employee_role || ''
		this.id = data.id || ''
		this.is_active = data.is_active || ''
		this.kiosk_user = data.kiosk_user || ''
		this.modified_date_display = data.modified_date_display || ''
		this.offline_mode = data.offline_mode || ''
		this.phone_ext = data.phone_ext || ''
		this.phone_no	 = data.phone_no || ''
		this.state = data.state || ''
		this.team_name = data.team_name || ''
		this.title = data.title || ''
		this.user = data.user || {}
		this.user_id = data.user_id || ''
		this.username = data.username || ''
		this.wage = data.wage || ''
		this.zip_code = data.zip_code || ''
		this.status = 'early'
	}
}

export default Employees
