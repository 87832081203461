const messages = {
  HEADING: 'Scheduling',
  SUB_HEADING: 'View and manage employee shifts',
  SHIFT_MODAL_HEADING: 'Shift Details',
  SHIFT_MODAL_TITLE_LABEL: 'Shift Name :',
  SHIFT_MODAL_START_TIME_LABEL: 'Start Date and Time :',
  SHIFT_MODAL_END_TIME_LABEL: 'End Date and Time :',
  SHIFT_MODAL_EMPLOYEES_LABEL: 'Employees :',
  SHIFT_MODAL_NUM_EMP_LABEL: 'Allocated Employees :',
  SHIFT_MODAL_P_IN_LABEL: 'Punch-in Restrictions :',
  SHIFT_MODAL_REOCCURING_LABEL: 'Reoccurs on :',
  SHIFT_MODAL_DEVIATIONS_LABEL: 'Early/Late Arrival Tracking :',
  SHIFT_MODAL_EMP_NAME_LABEL: 'Name',
  SHIFT_MODAL_EMP_MOBILE_LABEL: 'Mobile',
  SHIFT_MODAL_EMP_DEPT_LABEL: 'Dept-Team',
  SHIFT_MODAL_EMP_PUNCH_IN_RESTRICTIONS: 'Punch-in Restrictions',
  SHIFT_MODAL_EMP_ACTIONS_LABEL: 'Actions',
  SHIFT_MODAL_SEARCH_PLACEHOLDER: 'Search Employee and add',
  SHIFT_MODAL_EDIT_SHIFT_LABEL: 'Edit',
  SHIFT_MODAL_DELETE_SHIFT_LABEL: 'Delete',
  SHIFT_MODAL_NO_EARLIER_THAN: 'No earlier than',
  SHIFT_MODAL_NO_LATER_THAN: 'No later than',
  SHIFT_MODAL_NA: 'NA',
  ADD_SHIFT_MODAL_HEADING: 'Add Shift',
  EDIT_SHIFT_MODAL_HEADING: 'Edit Shift',
  ADD_SHIFT_MODAL_SHIFT_NAME_PLACEHOLDER: 'Shift Name',
  ADD_SHIFT_MODAL_ESTIMATED_EMP_PLACEHOLDER: 'Minimum No. Of Employees',
  ADD_SHIFT_MODAL_SHIFT_BTN_LABEL: 'Submit',
  MERGE_MODAL_TITLE: 'Overlapping Events',
  MERGE_MODAL_SELECT_EXISTING_SHIFT: 'Select Shift to add employee to',
  MERGE_MODAL_SELECT_EXISTING_BTN_LABEL: 'Submit',
  MERGE_MODAL_NEW_SHIFT_NAME_PLACEHOLDER: 'Enter Shift Name',
  MERGE_MODAL_NEW_SHIFT_BTN_LABEL: 'Create New Shift',
  MERGE_MODAL_SELECT_EXISTING_PLACEHOLDER: 'Select a shift',
  PAST_DATE_SELECT_TOAST_MESSAGE: "Can't create shift for past",
  PAST_DATE_ADD_EMPLOYEE_TO_SHIFT: "Can't add employee to a shift for past date",
  CURRENT_SHIFT_ADD_EMPLOYEE_TO_SHIFT: "Cant't add employee to a shift for present dates",
  SHIFT_MODAL_TIMEFENCE_START_TIME_LABEL: 'Timefence Start Date and Time :',
  SHIFT_MODAL_TIMEFENCE_END_TIME_LABEL: 'Timefence End Date and Time :',
  SHIFT_MODAL_GRACE_PERIOD_LABEL: 'Grace Period :',
  SHIFT_MODAL_ASSIGN_BUTTON_LABEL: 'Assign',

}

export default messages
