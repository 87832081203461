// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'

// Application imports
import companyApi from 'company/gateways/company-api'
import {COMPANY_EVENTS} from 'pages/shiftManagement/constants'

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */
async function fetchCompanies(eventEmitter, params) {
	try {
		const data = await companyApi.fetchCompanies(params)
		eventEmitter.emit(COMPANY_EVENTS.GET_COMPANY_SUCCESS, data)
	} catch (err) {
		eventEmitter.emit(COMPANY_EVENTS.GET_COMPANY_FAILURE, err)
	}
}

async function getBasicSettings(eventEmitter,params){
	try{
		const data = await companyApi.getBasicSettings(params)
		eventEmitter.emit(COMPANY_EVENTS.GET_BASIC_SETTING_SUCCESS,data)
	} catch(err) {
		eventEmitter.emit(COMPANY_EVENTS.GET_BASIC_SETTING_FAILURE,err)
	}
}

export {fetchCompanies,getBasicSettings}
