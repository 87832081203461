// Lib Imports
// TODO: remove moment and moment-range(if possible)
import React from 'react'
import {Box , Text} from 'grommet'

// CORE imports
import {Heading} from 'granite/components/Typography';
import Breadcrumb from 'granite/components/Breadcrumb';
import messages from 'messages/shiftManagement'

function HeaderComponent({refresh}) {

  return (
      <Box margin={{top:"0"}}>
        <Breadcrumb
          path={[
            {
              label: 'Scheduling',
              path: '/shift-management',
            }
          ]}
          refresh={refresh}
        />
        <Heading color="dark-1" level="3" weight={400} margin={{top:"1vw !important", bottom:'0'}}>
          {messages.HEADING}
        </Heading>
        <Text color="#001e2e" size="small" weight="300">
          {messages.SUB_HEADING}
        </Text>
      </Box>
     
  )
}

export default HeaderComponent;

