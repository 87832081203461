// Core imports
import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'

// Application imports
import User from 'accounts/entities/user'
import Organisation from 'organisations/entities/organisation'

/**
 *  User Login api call implementation.
 *
 * @param {string} username
 * @param {string} password
 *
 * @returns {string} authorization token if user login was successful.
 * @throws {GraniteError} if user credentials are invalid.
 */
async function login(username, password) {
  const payload = {
    email: username,
    password,
  }
  const response = await GraniteAPIClient().post('/work_shift/company/login/', payload)
  return response.data
}

/**
 *  Register api call implementation.
 *
 * @param {Object} user data.
 * @param {string} user.first_name - First name of the user.
 * @param {string} user.last_name - Last name of the user.
 * @param {string} user.password - User password.
 *
 * @returns {string} 'success' as string message.
 * @throws {GraniteError} if user data is invalid.
 */
async function register({email, first_name, last_name, password, ...data}) {
  const payload = {
    email,
    first_name,
    last_name,
    password,
  }
  try {
    const response = await GraniteAPIClient().post(
      '/company/register/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

/**
 *  User getProfile api call implementation.
 *
 * @returns {User} user entity
 * @throws {GraniteError} if xhr request fails
 */
async function myProfile() {
  try {
    const {data} = await GraniteAPIClient().get('/t2b/accounts/me/')
    const userData = {
      username: data.username,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      organisation: [data.company],
      timezone : data.timezone
    }
    const user = new User(userData)
    // TODO: update it while switching to granite api
    const organisations = [new Organisation(data.company)]

    return {organisations, user}
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

/**
 *  UpdateProfile api call implementation.
 *
 * @param {string} email
 * @param {string} first_name
 * @param {string} last_name
 * @param {string} username
 *
 * @returns {object} updated profile
 * @throws {GraniteError} if xhr request fails
 */
async function updateMyProfile(email, first_name, last_name, username) {
  try {
    const userData = {
      first_name,
      last_name,
      email,
      username,
    }
    const response = await GraniteAPIClient().put('/t2b/accounts/me/', userData)
    return response.data
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

/**
 *  request OTP api call implementation.
 *
 * @param {string} email
 *
 * @throws {GraniteError} if xhr request fails
 */
async function requestToken(email) {
  try {
    const payload = {email}
    await GraniteAPIClient().post('/t2b/accounts/forgot-password/', payload)
  } catch (error) {
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

/**
 *  submit OTP api call implementation.
 *
 * @param {string} token
 *
 * @returns {string} uid
 * @throws {GraniteError} if xhr request fails
 */
async function submitToken(token) {
  const payload = {
    token,
  }
  try {
    const response = await GraniteAPIClient().post(
      '/t2b/accounts/forgot-password/submit/',
      payload,
    )
    return response.data.uid
  } catch (error) {
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

/**
 *  Reset password api call implementation.
 *
 * @param {string} uid
 * @param {string} password
 * @param {string} re_password
 *
 * @throws {GraniteError} if xhr request fails
 */
async function resetPassword(uid, password, re_password) {
  const payload = {
    uid,
    password,
    re_password,
  }
  try {
    await GraniteAPIClient().post(
      '/t2b/accounts/forgot-password/reset/',
      payload,
    )
  } catch (error) {
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

/**
 *  User ChangePassword api call implementation.
 *
 * @param {string} password
 * @param {string} newPassword
 * @param {string} re_password
 *
 * @throws {GraniteError} if api call fails
 */
async function changePassword(password, newPassword, re_password) {
  try {
    const payload = {
      current_password: password,
      password: newPassword,
      re_password,
    }
    await GraniteAPIClient().post('/t2b/accounts/password/change/', payload)
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    // FIXME: show proper error
    throw new GraniteError(errorMessage)
  }
}

export default {
  changePassword,
  login,
  register,
  myProfile,
  resetPassword,
  requestToken,
  submitToken,
  updateMyProfile,
}
