// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'

// Application imports
import employeeApi from 'employee/gateways/employee-api'
import {EMPLOYEE_EVENTS} from 'pages/shiftManagement/constants'

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */
async function fetchEmployees(eventEmitter, params) {
	try {
		const data = await employeeApi.fetchEmployees(params)
		eventEmitter.emit(EMPLOYEE_EVENTS.GET_EMPLOYEE_SUCCESS, data)
	} catch (err) {
		eventEmitter.emit(EMPLOYEE_EVENTS.GET_EMPLOYEE_FAILURE, err)
	}
}

async function fetchEmployeesData(eventEmitter, params) {
	try {
		const data = await employeeApi.fetchEmployeesData(params)
		eventEmitter.emit(EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_SUCCESS, data)
	} catch (err) {
		eventEmitter.emit(EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_FAILURE, err)
	}
}


export {fetchEmployees, fetchEmployeesData}
