import GraniteError from 'utils/granite-error'
import {resetAuthToken} from 'utils/auth-singleton'

function removeAuthToken() {
  try {
    resetAuthToken()
  } catch (error) {
    throw new GraniteError(error)
  }
}

export {removeAuthToken}
