import React from 'react'
import {Box, DropButton} from 'grommet'
import Avatar from 'granite/components/Avatar'
import MenuButton from 'granite/components/MenuButton'
import PropType from 'prop-types'


const DropMenu = ({hoverIndicatorOptions, label, icon, renderItems, isCollapsed, ...rest}) => {
  return (
    <Box style={{'width' : '100%'}}>
      <DropButton
        dropAlign={{top: 'bottom', right: 'right'}}
        dropContent={renderItems()}
        elevation="medium"
      >
        <MenuButton
          isCollapsed={isCollapsed}
          label={label}
          style={{'width' : '100%'}}
          hoverIndicatorOptions={hoverIndicatorOptions}
          icon={icon}
          textColor="light-1"
        />
      </DropButton>
    </Box>
  )
}

DropMenu.defaultProps = {
  isCollapsed: true,
  profile: {},
  renderItems: null,
}

DropMenu.propTypes = {
  isCollapsed: PropType.bool,
  profile: PropType.object,
  renderItems: PropType.func,
}

export default DropMenu
