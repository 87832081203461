// Core imports
import GraniteError from 'utils/granite-error';
import GraniteAPIClient from 'utils/granite-client';

// Application imports
import Departments from 'department/entities/department';

/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */

async function fetchDepartments(params) {
  try {
    const { data } = await GraniteAPIClient().get('/company/departments/', {
      params,
    });
    const departments = data.map(items => new Departments(items));
    // TODO: update it while switching to granite api
    return { departments };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

export default {
  fetchDepartments,
};
