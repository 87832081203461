const PTO_POLICY_EVENTS = {
  GET_PTO_POLICY_SUCCESS: 'GET_PTO_POLICY_SUCCESS',
  GET_PTO_POLICY_FAILURE: 'GET_PTO_POLICY_FAILURE',
  DELETE_PTO_POLICY_SUCCESS: 'DELETE_PTO_POLICY_SUCCESS',
  DELETE_PTO_POLICY_FAILURE: 'DELETE_PTO_POLICY_FAILURE',
  CREATE_PTO_POLICY_SUCCESS: 'CREATE_PTO_POLICY_SUCCESS',
  CREATE_PTO_POLICY_FAILURE: 'CREATE_PTO_POLICY_FAILURE',
  UPDATE_PTO_POLICY_SUCCESS: 'UPDATE_PTO_POLICY_SUCCESS',
  UPDATE_PTO_POLICY_FAILURE: 'UPDATE_PTO_POLICY_FAILURE',
  GET_PTO_REQUEST_LIST_SUCCESS: 'GET_PTO_REQUEST_LIST_SUCCESS',
  GET_PTO_REQUEST_LIST_FAILURE: 'GET_PTO_REQUEST_LIST_FAILURE',
  GET_PTO_REQUEST_SUCCESS: 'GET_PTO_REQUEST_SUCCESS',
  GET_PTO_REQUEST_FAILURE: 'GET_PTO_REQUEST_FAILURE',
  ADD_TIME_OFF_SUCCESS: 'ADD_TIME_OFF_SUCCESS',
  ADD_TIME_OFF_FAILURE: 'ADD_TIME_OFF_FAILURE',
  GET_EMPLOYEE_POLICY_SUCCESS: 'GET_PTOEMPLOYEE_POLICY_SUCCESS',
  GET_EMPLOYEE_POLICY_FAILURE: 'GET_EMPLOYEE_POLICY_FAILURE',
  GET_EMPLOYEE_LIST_SUCCESS: 'GET_PTOEMPLOYEE_LIST_SUCCESS',
  GET_EMPLOYEE_LIST_FAILURE: 'GET_EMPLOYEE_LIST_FAILURE',
  GET_ACCURAL_HISTORY_SUCCESS: 'GET_ACCURAL_HISTORY_SUCCESS',
  GET_ACCURAL_HISTORY_FAILURE: 'GET_ACCURAL_HISTORY_FAILURE',
  UPDATE_POLICY_BALANCE_SUCCESS: 'UPDATE_POLICY_BALANCE_SUCCESS',
  UPDATE_POLICY_BALANCE_FAILURE: 'UPDATE_POLICY_BALANCE_FAILURE',
  UPDATE_TIME_OFF_SUCCESS: 'UPDATE_TIME_OFF_SUCCESS',
  UPDATE_TIME_OFF_FAILURE: 'UPDATE_TIME_OFF_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  DELETE_HOLIDAY_SUCCESS: 'DELETE_HOLIDAY_SUCCESS',
  DELETE_HOLIDAY_FAILURE: 'DELETE_HOLIDAY_FAILURE',
  UPDATE_HOLIDAY_SUCCESS: 'UPDATE_HOLIDAY_SUCCESS',
  UPDATE_HOLIDAY_FAILURE: 'UPDATE_HOLIDAY_FAILURE',
  GET_HOLIDAY_LIST_SUCCESS: 'GET_HOLIDAY_LIST_SUCCESS',
  GET_HOLIDAY_LIST_FAILURE: 'GET_HOLIDAY_LIST_FAILURE',
  CHANGE_TABS: 'CHANGE_TABS',
  FAILURE_TOAST: 'FAILURE_TOAST',
  SUCCESS_TOAST: 'SUCCESS_TOAST',
};

const TAGS_EVENTS = {
  GET_TAGS__SUCCESS: 'GET_TAGS__SUCCESS',
  GET_TAGS_FAILURE: 'GET_TAGS_FAILURE',
};

const STATUS_OPTIONS = [
  { id: '', name: 'All' },
  { id: 'true', name: 'Active' },
  { id: 'false', name: 'Inactive' },
];

const ACCURAL_TYPE_VALUES = {
  MANUAL: 'MANUAL',
  YEARLY: 'YEARLY',
  EVERY_PAY_PERIOD: 'EVERY_PAY_PERIOD',
  BASED_ON_HOURS_WORKED: 'BASED_ON_HOURS_WORKED',
};

const ACCURAL_TYPE_OPTIONS = [
  { label: 'Manual', value: ACCURAL_TYPE_VALUES.MANUAL },
  { label: 'Yearly', value: ACCURAL_TYPE_VALUES.YEARLY },
  { label: 'Every Pay Period', value: ACCURAL_TYPE_VALUES.EVERY_PAY_PERIOD },
  { label: 'Based on Hours Worked', value: ACCURAL_TYPE_VALUES.BASED_ON_HOURS_WORKED },
];

export { PTO_POLICY_EVENTS, TAGS_EVENTS, STATUS_OPTIONS, ACCURAL_TYPE_OPTIONS, ACCURAL_TYPE_VALUES };
