import {createSelector} from 'reselect'

import createReducer from 'utils/ducks/create-reducer'

// ActionsNames - <NOUN>/<VERB>_<NOUN>
const RESET_STATE = 'organisations/RESET_STATE'
const UPDATE_ORGANISATIONS = 'organisations/UPDATE_ORGANISATIONS'
const UPDATE_SELECTED_ORGANISATION =
  'organisations/UPDATE_SELECTED_ORGANISATION'

// Action creator name: <VERB><NOUN>
// Export actions
export function resetState() {
  return {
    type: RESET_STATE,
  }
}

export function updateOrganisations(organisationsData) {
  return {
    type: UPDATE_ORGANISATIONS,
    data: organisationsData,
  }
}
export function updateSelectedOrganisation(selectedOrganisation) {
  return {
    type: UPDATE_SELECTED_ORGANISATION,
    data: selectedOrganisation,
  }
}

// state name - <NOUN>
const defaultState = {
  organisations: [],
  selectedOrganisation: undefined,
}

// Reducers - <NOUN>_<VERB>_REDUCER
function UPDATE_ORGANISATIONS_REDUCER(state, action) {
  const organisations = action.data

  return Object.assign({}, state, {
    organisations,
  })
}
function UPDATE_SELECTED_ORGANISATION_REDUCER(state, action) {
  return Object.assign({}, state, {
    selectedOrganisation: action.data,
  })
}

const handlers = {
  [UPDATE_ORGANISATIONS]: UPDATE_ORGANISATIONS_REDUCER,
  [UPDATE_SELECTED_ORGANISATION]: UPDATE_SELECTED_ORGANISATION_REDUCER,
}

// Selectors
const organisationsSelector = state => state.organisations.organisations

export const organisations = createSelector(
  organisationsSelector,
  organisations => organisations.organisations,
)
export const selectedOrganisation = createSelector(
  organisationsSelector,
  organisations => organisations.selectedOrganisation,
)

// export reducers as default
export default createReducer(defaultState, handlers)
