import React from 'react'
import PropTypes from 'prop-types'
import {Box} from 'grommet'
import styled, {css, withTheme} from 'styled-components'

const HorizantalDivider = styled(Box)`
  display: block;
  clear: both;
  width: ${props =>
    props.width ? props.theme.global.size[props.width] : '100%'};
  height: ${props =>
    props.height ? props.theme.global.size[props.height] : '1px'};
  };
  ${'' /* margin-top: ${props => props.theme.global.edgeSize.small}; */}
  ${'' /* margin-bottom: ${props => props.theme.global.edgeSize.small}; */}
  ${
    '' /* background: ${props =>
    props.color ? props.color : props.theme.global.colors['dark-5']}; */
  }
`
const VerticalDivider = styled(Box)`
  position: relative;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  ${'' /* margin-left: ${props => props.theme.global.edgeSize.small}; */}
  ${'' /* margin-right: ${props => props.theme.global.edgeSize.small}; */}
  background: ${props =>
    props.color ? props.color : props.theme.global.colors['dark-5']};

  ${props =>
    props.type === 'vertical' &&
    css`
      align-self: ${props => (props.align ? props.align : 'center')};
    `}
`

const Divider = ({type, ...rest}) => {
  return type === 'vertical' ? (
    <VerticalDivider type={type} {...rest} />
  ) : (
    <HorizantalDivider type={type} {...rest} />
  )
}

Divider.defaultProps = {
  type: 'horizontal',
  background: 'dark-4',
}

Divider.propTypes = {
  color: PropTypes.string,
  type: PropTypes.oneOf(['horizontal', 'vertical']),
}

export default withTheme(Divider)
