// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'
import {setAuthToken} from 'utils/auth-singleton'

// Application imports
import userApi from 'accounts/gateways/user-api'
import {USER_EVENTS} from 'pages/shiftManagement/constants'

/**
 * Login Page - Implements of user login action.
 *
 * @param {string} username
 * @param {string} password
 *
 * @returns {json} json object representing logged in user profile.
 * @throws {GraniteError} if credentials are invalid.
 */
async function loginClicked(username, password) {
  if (_.isNil(username) || _.isNil(password)) {
    throw new GraniteError('Credentials are invalid')
  }
  const data = await userApi.login(username, password)
  setAuthToken(data.token)
  return data
}

/**
 * ChangePassword Page - Implimentation of user change password action.
 *
 * @param {object} data - old and new password
 * @param {string} data.password - current password of user
 * @param {string} data.newPassword - new password 
 * @param {string} data.re_password - new password confirm 
 *
 * @throws {GraniteError} if required keys are invalid.
 */
async function changePasswordClicked({password, newPassword, re_password}) {
  const {changePassword} = userApi
  if (_.isNil(password) || _.isNil(newPassword) || _.isNil(re_password)) {
    throw new GraniteError('Password and New Password are required')
  }
  return await changePassword(password, newPassword, re_password)
}

/**
 * ForgotPassword Page - Implimentation of user request OTP action.
 *
 * @param {object} data
 * @param {string} data.email - email address of user
 *
 * @throws {GraniteError} if required keys are invalid.
 */
async function requestTokenClicked({email}) {
  const {requestToken} = userApi
  if (_.isNil(email)) {
    throw new GraniteError('Email is required')
  }
  await requestToken(email)
}

/**
 * ForgotPassword Page - Implements of submit OTP action.
 *
 * @param {object} data
 * @param {string} data.token - OTP
 *
 * @returns {string} uid
 * @throws {GraniteError} if required keys are missing.
 */
async function submitTokenClicked({token}) {
  const {submitToken} = userApi
  if (_.isNil(token)) {
    throw new GraniteError('Token is required')
  }

  return await submitToken(token)
}

/**
 * ResetPassword Page - Implimentation of resetpassword action.
 *
 * @param {object} data
 * @param {string} data.uid - uid sent from backend after submitting OTP
 * @param {string} data.password - new password of user
 * @param {string} data.re_password - new password confirm
 *
 * @throws {GraniteError} if required keys are missing.
 */
async function resetPasswordClicked({uid, password, re_password}) {
  const {resetPassword} = userApi
  if (_.isNil(uid) || _.isNil(password) || _.isNil(re_password)) {
    throw new GraniteError('Uid, password and re_password is required')
  }
  await resetPassword(uid, password, re_password)
}

/**
 * Register Page - Implimentation of register action.
 *
 * @param {object} values
 *
 * @throws {GraniteError} if required keys are missing.
 */
async function registerClicked(values) {
  const {register} = userApi
  if (_.isNil(values.password)) {
    throw new GraniteError('Credentials are invalid')
  }
  const token = await register(values)
  setAuthToken(token)
}

/**
 * Profile Page - Implementation of updateProfile action.
 *
 * @param {object} user profile data
 * @param {string} data.email - user email
 * @param {string} data.first_name - user first_name
 * @param {string} data.last_name - user last_name
 * @param {string} data.username - username
 *
 * @returns {object} updatedProfile
 * @throws {GraniteError} if required keys are missing.
 */
async function updateProfileClicked({email, first_name, last_name, username}) {
  const {updateMyProfile} = userApi
  if (_.isNil(email) && _.isNil(first_name) && _.isNil(username)) {
    throw new GraniteError('Credentials are invalid')
  }
  const response = await updateMyProfile(email, first_name, last_name, username)
  return response
}

async function verifyAccountClicked(values) {
}

async function myProfile(eventEmitter){
  try{
    const data = await userApi.myProfile()
    eventEmitter.emit(USER_EVENTS.GET_USER_SUCCESS,data)
  } catch(err) {
    eventEmitter.emit(USER_EVENTS.GET_USER_FAILURE,err)
  }
}

export {
  changePasswordClicked,
  loginClicked,
  registerClicked,
  resetPasswordClicked,
  requestTokenClicked,
  submitTokenClicked,
  updateProfileClicked,
  verifyAccountClicked,
  myProfile,
}
