// Lib Imports
import React, {useEffect, useState} from 'react'
import {Box, Heading} from 'grommet'
import {connect} from 'react-redux'

// CORE Imports
import EventEmitter from 'utils/event-emitter'
import PROFILE_MESSAGES from 'messages/profile'
import Toast from 'granite/components/Toast'

// Application Imports
import PROFILE_EVENTS from './constants'
import ProfileForm from './components/ProfileForm'
import {updateProfileClicked} from 'accounts/controllers/user'

const Profile = ({dispatch, user}) => {
  let eventEmitter = new EventEmitter()
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)

  useEffect(function init() {
    const observable = eventEmitter.getObservable()
    let subscription
    listenObservable()

    function listenObservable() {
      subscription = observable.subscribe(event => {
        switch (event.type) {
          case PROFILE_EVENTS.PROFILE_POST_SUCCESS:
            setToastData({
              open: true,
              message: PROFILE_MESSAGES.PROFILE_POST_SUCCESS,
            })
            setTimeout(() => {
              setToastData({
                open: false,
                message: '',
              })
              dispatch({
                type: 'accounts/user/UPDATE_PROFILE',
                data: event.data,
              })
            }, 1000)
            break
          case PROFILE_EVENTS.PROFILE_POST_FAILURE:
            setToastData({
              open: true,
              message: PROFILE_MESSAGES.PROFILE_POST_FAILURE,
            })
            setTimeout(() => {
              setToastData({
                open: false,
                message: '',
              })
            }, 1000)
            break
          case PROFILE_EVENTS.PROFILE_FAILURE:
            setToastData({
              open: true,
              message: PROFILE_MESSAGES.PROFILE_FAILURE,
            })
            setTimeout(() => {
              setToastData({
                open: false,
                message: '',
              })
            }, 1000)
            break
          default:
        }
      })
    }

    return function cleanUp() {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <Box flex={{grow: 1}} pad="large">
      <Heading level={1}>Edit User Profile</Heading>
      <Heading level={2}>Edit & update your Profile details</Heading>
      <ProfileForm
        updateProfileClicked={updateProfileClicked}
        eventEmitter={eventEmitter}
        user={user}
      />
      {toastData.open ? <Toast text={toastData.message} /> : null}
    </Box>
  )
}

const mapStateToProps = state => ({user: state.accounts.user.profile})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile)
