import {createSelector} from 'reselect'

import createReducer from 'utils/ducks/create-reducer'

// ActionsNames - <NOUN>/<VERB>_<NOUN>
const RESET_STATE = 'accounts/user/RESET_STATE'
const UPDATE_PROFILE = 'accounts/user/UPDATE_PROFILE'
const UPDATE_PERMISSIONS = 'accounts/user/UPDATE_PERMISSIONS'

// Action creator name: <VERB><NOUN>
// Export actions
export function resetState() {
  return {
    type: RESET_STATE,
  }
}

export function updateProfile(profileData) {
  return {
    type: UPDATE_PROFILE,
    data: profileData,
  }
}
export function updateUserPermissions(permissionsData) {
  return {
    type: UPDATE_PERMISSIONS,
    data: permissionsData,
  }
}

// state name - <NOUN>
const defaultState = {
  auth_token: '',
  profile: {},
  permissions: null,
}

// Reducers - <NOUN>_<VERB>_REDUCER
function UPDATE_PROFILE_REDUCER(state, action) {
  return Object.assign({}, state, {
    profile: action.data,
  })
}
function UPDATE_PERMISSIONS_REDUCER(state, action) {
  return Object.assign({}, state, {
    permissions: action.data,
  })
}

const handlers = {
  [UPDATE_PROFILE]: UPDATE_PROFILE_REDUCER,
  [UPDATE_PERMISSIONS]: UPDATE_PERMISSIONS_REDUCER,
}

// Selectors
const userSelector = state => state.accounts.user

export const profile = createSelector(
  userSelector,
  user => user.profile,
)
export const permissions = createSelector(
  userSelector,
  user => user.permissions,
)
export const organisations = createSelector(
  userSelector,
  user => user.organisations,
)
export const selectedOrganisation = createSelector(
  userSelector,
  user => user.organisations && user.organisations.find(org => org.selected),
)

// export reducers as default
export default createReducer(defaultState, handlers)
