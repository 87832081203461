// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'

// Application imports
import tagsApi from 'tags/gateways/tags-api'
import {TAGS_EVENTS} from 'pto/constants'

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */
async function fetchTags(eventEmitter, params) {
	try {
		const data = await tagsApi.fetchTags(params)
		eventEmitter.emit(TAGS_EVENTS.GET_TAGS_SUCCESS, data)
	} catch (err) {
		eventEmitter.emit(TAGS_EVENTS.GET_TAGS_FAILURE, err)
	}
}

export {fetchTags}
