// Lib imports
import React from 'react'
import {Box, TextInput, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'

// Core imports
import FormField from 'granite/components/FormField'
import ONBOARDING_MESSAGES from 'messages/onBoarding'

// Application imports
import ONBOARDING_EVENTS from './../constants'

const LoginForm = ({eventEmitter, submitClicked}) => {
  // FIXME: Move strings to messages
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Company Name is required'),
    description: Yup.string(),
    address: Yup.object().shape({
      house_number: Yup.string(),
      city: Yup.string(),
    }),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    try {
      await submitClicked(values)
      eventEmitter.emit(ONBOARDING_EVENTS.ONBOARDING_SUCCESS)
    } catch (e) {
      eventEmitter.emit(ONBOARDING_EVENTS.ONBOARDING_FAILURE)
      if (e.errors) setErrors(e.errors)
    }
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        address: {house_number: '', city: ''},
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormField
              name="name"
              label={ONBOARDING_MESSAGES.NAME_LABEL}
              error={touched.name && errors.name}
            >
              <TextInput
                placeholder={ONBOARDING_MESSAGES.NAME_PLACEHOLDER}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
            </FormField>
            <FormField
              name="description"
              label={ONBOARDING_MESSAGES.DESCRIPTION_LABEL}
              error={touched.description && errors.description}
            >
              <TextInput
                placeholder={ONBOARDING_MESSAGES.DESCRIPTION_PLACEHOLDER}
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
              />
            </FormField>
            <FormField
              name="address.house_number"
              label={ONBOARDING_MESSAGES.ADDRESS_LINE_1_LABEL}
              error={
                touched.address &&
                touched.address.house_number &&
                errors.address &&
                errors.address.house_number
              }
            >
              <TextInput
                placeholder={ONBOARDING_MESSAGES.ADDRESS_LINE_1_PLACEHOLDER}
                name="address.house_number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address.house_number}
              />
            </FormField>
            <FormField
              name="address.city"
              label={ONBOARDING_MESSAGES.ADDRESS_LINE_2_LABEL}
              error={
                touched.address &&
                touched.address.city &&
                errors.address &&
                errors.address.city
              }
            >
              <TextInput
                placeholder={ONBOARDING_MESSAGES.ADDRESS_LINE_2_PLACEHOLDER}
                name="address.city"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address.city}
              />
            </FormField>
            <Box
              fill="horizontal"
              direction="column"
              elevation="none"
              justify="between"
              pad="none"
              gap="xsmall"
            >
              <Button
                type="submit"
                alignSelf="start"
                margin={{vertical: 'small'}}
                label={
                  isSubmitting
                    ? ONBOARDING_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING
                    : ONBOARDING_MESSAGES.SUBMIT_BUTTON_LABEL
                }
                disabled={isSubmitting}
                primary
              />
            </Box>

            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
          </form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
