import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

function Employee(data) {
  this.username = data.username
  this.firstName = data.user.first_name || ''
  this.lastName = data.user.last_name || ''
  this.email = data.email || ''
  this.title = data.title || ''
  this.employeeRole = data.employee_role || ''
  this.phoneNumber = data.phone_no || ''
  this.isActive = data.is_active || ''
  this.lastActivity = data.modified_date_display || ''
  this.address = data.address_line_one + ', ' + data.address_line_two || ''
}

export default Employee
