// Lib Imports
import React from 'react'
import {Box, Button, Text} from 'grommet'

const StatCard = ({caption, icon, stat}) => {
  return (
    <Box
      align="center"
      background="white"
      basis="medium"
      elevation="small"
      gap="small"
      pad="large"
      margin="medium"
      height="medium"
      round="xsmall"
    >
      <Box
        margin={{bottom: 'medium'}}
        round="full"
        overflow="hidden"
        background="brand"
      >
        <Button icon={icon} onClick={() => {}} />
      </Box>
      <Box>
        <Text textAlign="center" size="42px" weight={600}>
          {stat}
        </Text>
      </Box>
      <Box>
        <Text textAlign="center" size="small">
          {caption}
        </Text>
      </Box>
    </Box>
  )
}

export default StatCard
