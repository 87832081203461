import React from 'react';
import { Card, Box, Text, TextInput } from 'grommet';
import PropTypes from 'prop-types';
const FreeSelectFormComponent = ({ data, index }) => {
  return (
    <>
      <Card border>
        <Box direction="row" gap="small" pad={'small'}>
          <Box direction="row">
            {data.behaviour === 'MANDATORY' && <Text color={'red'}>*</Text>}
            <Text>{index + 1}.</Text>
          </Box>

          <Text>{data.question}</Text>
        </Box>
        <Box pad={{ left: 'medium', bottom: 'medium' ,right:'medium'}}>
          <TextInput
            type="textarea"
            id={`${index}`}
            name={`${index}`}
            onChange={()=>{}}
            value={data.response && data.response.length ? data.definition ==='DATE' ?data.response[0].date : data.response[0].value :null }
            disabled
          />
        </Box>
      </Card>
    </>
  );
};

FreeSelectFormComponent.propTypes = {
  data: PropTypes.object,
  index : PropTypes.any
};
export default FreeSelectFormComponent;
