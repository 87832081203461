import React from 'react'
import {Link} from 'react-router-dom'
import {Box, Image} from 'grommet'

const Header = () => {
  return (
    <Box
      fill="horizontal"
      height="50px"
      direction="row"
      align="center"
      as="header"
      elevation="small"
      justify="between"
      pad={{horizontal: 'xxlargePercentage', vertical: 'medium'}}
      flex={{shrink: 0}}
    >
      <Link to="/">
        <Image
          alt="logo"
          height="30px"
          src={process.env.PUBLIC_URL + '/img/logo.png'}
        />
      </Link>
      <Box direction="row" align="center" gap="medium">
        <Link to="/login">Sign In</Link>
      </Box>
    </Box>
  )
}

export default Header
