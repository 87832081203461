const mapEventToShift = ({id, title, start, end, extendedProps}) => {
  const employeeIds = extendedProps.employees && extendedProps.employees.length && extendedProps.employees.map(emp => emp && emp.id)
  const punchedInIds = []
  extendedProps.employees && extendedProps.employees.length && extendedProps.employees.forEach(emp => {
    if (emp && emp.isPresent) punchedInIds.push(emp.id)
  })


  return {
    id,
    name: title,
    from: start,
    to: end,
    employeeIds,
    punchedInIds,
    end_date: extendedProps.end_date,
    reoccuring: extendedProps.reoccuring,
    deviationThreshold: extendedProps.deviationThreshold,
    punchInRestrictions: extendedProps.punchInRestrictions,
    expectedEmployees: extendedProps.expectedEmployees,
    timefence_end_time: extendedProps.timefence_end_time,
    timefence_start_time: extendedProps.timefence_start_time,
  }
}

const defaultDays = [
  {label: 'Mon', val: 0, selected: false},
  {label: 'Tue', val: 1, selected: false},
  {label: 'Wed', val: 2, selected: false},
  {label: 'Thu', val: 3, selected: false},
  {label: 'Fri', val: 4, selected: false},
  {label: 'Sat', val: 5, selected: false},
  {label: 'Sun', val: 6, selected: false},
]

export {defaultDays, mapEventToShift}
