import React from 'react'
import {Image} from 'grommet'
import _ from 'lodash/fp'

// FIXME: icons should be sent by backend
function insertIcon({label}) {
  switch (label) {
    case 'dashboard':
      return (
        <Image
          alt="dashboard"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/overview.svg'}
        />
      )
    case 'employee.list':
      return (
        <Image
          alt="employees"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/employees.svg'}
        />
      )
    case 'activity':
      return (
        <Image
          alt="tracking"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/tracking.svg'}
        />
      )
    case 'timecards.timecard':
      return (
        <Image
          alt="timecards"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/timecards.svg'}
        />
      )
    case 'job':
      return (
        <Image
          alt="job center"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/job.svg'}
        />
      )
     case 'reports.list':
      return (
        <Image
          alt="reports"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/reports.svg'}
        />
      )
    case 'report':
      return (
        <Image
          alt="reports"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/Records.svg'}
        />
      )
    case 'job.list':
      return (
        <Image
          alt="job dashboard"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/Job Dashboard.svg'}
        />
      )
    case 'customer.list':
      return (
        <Image
          alt="customers"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/Customer.svg'}
        />
      )
    case 'task.list':
      return (
        <Image
          alt="task"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/Task.svg'}
        />
      )
    case 'geofences.list':
      return (
        <Image
          alt="geofences"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/Geofence.svg'}
        />
      )
    case 'scheduling':
      return (
        <Image
          alt="scheduling"
          height="auto"
          width="18px"
          src={process.env.PUBLIC_URL + '/img/Shift-Management.svg'}
        />
      )
    case 'premium':
      return (
        <Image
          alt="scheduling"
          height="auto"
          width="18px"
          src={process.env.PUBLIC_URL + '/img/PremiumFeature.svg'}
        />
      )
    case 'jobs.new.list': 
      return (
        <Image
          alt="job center"
          height="auto"
          width="17px"
          src={process.env.PUBLIC_URL + '/img/ManageJob.svg'}
        /> ) 
    default:      
      break
  }
}

function insertRoute({label}) {
  switch (label) {
    case 'dashboard':
      return '/dashboard'
    case 'employee.list':
      return '/employee/list'
    case 'activity':
      return '/activity'
    case 'timecards.timecard':
      return '/timecard/custom/list'
    case 'job':
      return '/job'
     case 'reports.list':
      return '/job/lists/jobreports'
    case 'report':
      return '/report'
    case 'job.list':
      return '/job/list'
    case 'customer.list':
      return '/customers/list'
    case 'task.list':
      return '/job/lists/task'
    case 'geofences.list':
      return '/geofence/job/lists'
    case 'scheduling':
      return '/shift-management'
    case 'activity.tracking.current':
      return '/activity/tracking/current'
    case 'activity.tracking.historic':
      return '/activity/tracking/historic'
    case 'jobs.new.list':
      return '/jobs/new/list'     
    default:
      break
  }
}

function Sidemenu(data) {
  this.children = data.children.map(child => new Sidemenu(child))
  this.sequence = data.sequence
  this.isExternalLink = data.isExternalLink
  this.path = data.isExternalLink
    ? data.link + insertRoute({label: data.name})
    : insertRoute({label: data.name})
  this.label = data.label
  this.isActive = data.is_active
  this.icon = insertIcon({label: data.name})
  this.submenus = null
  if (data.children.length > 0) {
    this.submenus = data.children.map(sub => new Sidemenu(sub))
  }
  this.id = data.id
  this.open = false

  this.getchildren = function() {
    return this.children
  }
  this.getlabel = function() {
    return this.label
  }
  this.isActive = function() {
    return this.isActive
  }
  this.isExternalLink = function() {
    return this.isExternalLink
  }
  this.geticon = function() {
    return this.icon
  }
  this.getsubmenus = function() {
    return this.submenus
  }
  this.getpath = function() {
    return this.path
  }
  this.getsequence = function() {
    return this.sequence
  }

  this.toString = function() {
    return 'sideMenu'
  }

  this.toJSON = function() {
    const data = {
      sequence: this.sequence,
      path: this.path,
      label: this.label,
      isActive: this.isActive,
      icon: this.icon,
      submenus: this.submenus,
      id: this.id,
    }
    return data
  }

  this.copy = function(obj, properties = {}) {
    const existingProps = obj.toJSON()
    const updatedProps = Object.assign(existingProps, properties)
    const {username, ...rest} = updatedProps
    return new Sidemenu(username, rest)
  }
}

export default Sidemenu
