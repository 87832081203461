import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class Team {
	constructor(data) {
		this.company = data.company || ''
		this.create_date = data.create_date || ''
		this.created_by_user = data.created_by_user || ''
		this.department = data.department || ''
		this.department_display_name = data.department_display_name || ''
		this.description = data.description || ''
		this.id = data.id || ''
		this.is_active = data.is_active || ''
		this.is_deleted = data.is_deleted || ''
		this.modified_date = data.modified_date || ''
		this.name = data.name || ''
	}
}

export default Team
