import React from 'react'
import {Box, Button, Heading, Layer, Text} from 'grommet'
import {Close} from 'grommet-icons'

// CORE imports
import Divider from 'granite/components/Divider'


const ShiftDeleteModal = ({
  deleteShift,
  layer,
  setLayer,
  setSubmitShift,
  isDeleting,
  deleteRecurringShift,
  shiftData,
}) => {
  const closeDeleteModal = () => {
    setSubmitShift({isSubmitting: false})
    setLayer(false)
  }

  return (
        <Layer>
          <Box
            background="light-1"
            width="xlarge"
            round="small"
            overflow={{vertical: 'auto'}}
          >
            <Box
              direction="row"
              justify="between"
              align="center"
              pad={{top: 'small', bottom: 'xsmall', horizontal: 'medium'}}
              height={{min: 'auto'}}
            >
              <Heading level={3} color="dark-1" size="xsmall" margin="none">
                Delete Shift
              </Heading>
              <Button icon={<Close size="small" />} onClick={closeDeleteModal} />
            </Box>
            <Divider
              background="accent-1"
              margin={{bottom: 'small'}}
              height="vsmall"
            />
            <Box
              direction="column"
              gap="medium"
              pad={{top: 'small', horizontal: 'large'}}
            >
              <Text>Are you sure want to delete this shift?</Text>
              {/* TODO: disable button till necessory fields are not present */}
              <span style={{textAlign:'right'}}><Button
                primary
                alignSelf="end"
                color="accent-1"
                size="small"
                label={isDeleting ? "Saving..." : "Yes"}
                disabled={isDeleting}
                onClick={() => shiftData.on_going ? deleteRecurringShift('deleteShift') : deleteShift()}
                margin={{bottom: 'large'}}
              />
              <Button
                primary
                style={{marginLeft:'20px'}}
                color="accent-1"
                size="small"
                label="No"
                onClick={closeDeleteModal}
                margin={{bottom: 'large'}}
              /></span>
            </Box>
          </Box>
        </Layer>
  )
}


export default ShiftDeleteModal
