// Lib Imports
import React from 'react'
import {Box, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'

// CORE Imports
import FormField from 'granite/components/FormField'
import PasswordInput from 'granite/components/PasswordInput'

const ResetPasswordForm = ({onSubmit}) => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Password is too short - should be 6 chars minimum.')
      .max(50, 'Too Long!')
      .required('Password is required'),
    re_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must be same.')
      .required('Password is required'),
  })

  return (
    <Formik
      initialValues={{
        password: '',
        re_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="password"
            label="Choose new password"
            required
            error={touched.password && errors.password}
          >
            <PasswordInput
              placeholder="Enter new password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
          </FormField>
          <FormField
            name="re_password"
            label="Confirm new password"
            required
            error={touched.re_password && errors.re_password}
          >
            <PasswordInput
              placeholder="Confirm password"
              name="re_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.re_password}
            />
          </FormField>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            justify="between"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{vertical: 'small'}}
              label={isSubmitting ? 'Saving...' : 'Save'}
              disabled={isSubmitting}
              primary
            />
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
