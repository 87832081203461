// lib imports
import React, {useRef, useState, useEffect} from 'react'
import {
  Box,
  Text,
  TextInput,
  Button,
  Keyboard,
  InfiniteScroll,
} from 'grommet'
import {Close, Search} from 'grommet-icons'

// CORE Imports
import Avatar from 'granite/components/Avatar'
import MultiSelect from 'granite/components/MultiSelect'

const Filters = ({
  departments,
  employees: defaultEmployees,
  defaultTeams,
  defaultShifts,
  setShifts,
  externalEventsContainerRef,
  getEmployeeShifts,
  view,
  onDepartmentSelect,
  onTeamSelect,
}) => {
  const [teams, setTeams] = useState(defaultTeams)
  const [selectedDepartments, setSelectedDepartments] = useState([])
  const [selectedTeams, setSelectedTeams] = useState([])
  let [employees, setEmployees] = useState(defaultEmployees)
  const [searchTerm, setSearchTerm] = useState('')
  const searchInputRef = useRef()
  const [a, setA] = useState([])
  const [teamDisabled, setTeamDisabled] = useState(true)
  useEffect(() => {
    setTeams(defaultTeams)
  }, [defaultTeams])

  useEffect(() => {
    setEmployees(defaultEmployees)
  }, [defaultEmployees])

  const handleDepartmentChange = departments => {
    if (departments.length) {
      onDepartmentSelect({selectedTeams, departments})
      setSelectedDepartments(departments)
      setTeamDisabled(false)
    } else {
      setSelectedTeams([])
      setSelectedDepartments([])
      setTeams(defaultTeams)
      setTeamDisabled(true)
      onDepartmentSelect({selectedTeams, departments})
      return
    }
  }

  const handleTeamChange = teams => {
    setSelectedTeams(teams)
    if (teams.length) {
      onTeamSelect({teams, selectedDepartments})
    } else {
       onTeamSelect({teams, selectedDepartments})
    }
  }

  const handleSearchResetBtnClick = () => setSearchTerm('')
  const handleSearchBtnClick = () => {
    const {
      current: {value},
    } = searchInputRef
    setSearchTerm(value)
  }

  const handleEmployeeClick = employeeId => {
    let alreadySelected = false
    setA(
      employees.map(emp => {
        if (emp.id === employeeId) {
          if (emp.isSelected) {
            alreadySelected = true
          }
          emp.isSelected = !emp.isSelected
          return emp
        }
        emp.isSelected = false
        return emp
      }),
    )
    if (alreadySelected) {
      getEmployeeShifts(employeeId, false)
    } else {
      getEmployeeShifts(employeeId, true)
    }
  }

  if (searchTerm) {
    employees = employees.filter(emp =>
      emp.user.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
    )
  }

  return (
    <Box
      direction="column"
      gap="small"
      fill="vertical"
      height={{max: `${view}px`}}
    >
      <Box
        direction="column"
        height={{min: '270px'}}
        basis="auto"
        gap="small"
        background="light-1"
        pad="medium"
      >
        <Text color="dark-1" size="medium">
          Select Department
        </Text>
        <MultiSelect
          labelKey="name"
          options={departments}
          onChange={handleDepartmentChange}
          placeholder="Select Department"
          dropheight="Department"
        />
        <Text color="dark-1" size="medium">
          Select Team
        </Text>
        <MultiSelect
          labelKey="name"
          options={teams}
          onChange={handleTeamChange}
          placeholder={<Text color={teamDisabled && 'white'}>Select Team</Text>}
          dropheight="Team"
          disabled={teamDisabled}
          clearTeam={teamDisabled}
        />
        <Text color="dark-1" margin={{top: '0.6rem'}} size="medium">
          Search Employee
        </Text>
        <Box
          direction="row"
          align="center"
          border={{color: 'light-3'}}
          style={{borderWidth: '1px'}}
          round="xsmall"
        >
          <Keyboard onEnter={handleSearchBtnClick}>
            <TextInput
              placeholder="Search Employee"
              ref={searchInputRef}
              plain
              value={searchTerm}
              onChange={event => setSearchTerm(event.target.value)}
            />
          </Keyboard>
          {searchTerm ? (
            <Button
              icon={<Close size="small" color="dark-2" />}
              onClick={handleSearchResetBtnClick}
              focusIndicator={false}
            />
          ) : (
            <Button
              style={{
                background: '#ddd',
                padding: '5px 15px',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
              icon={<Search size="small" color="#09819a" />}
              onClick={handleSearchBtnClick}
              focusIndicator={false}
              fill="vertical"
            />
          )}
        </Box>
      </Box>
      <Box
        direction="column"
        background="light-1"
        gap="small"
        basis="full"
        ref={externalEventsContainerRef}
      >
        <Text
          color="dark-1"
          size="medium"
          margin={{horizontal: 'medium', top: 'small', bottom: 'small'}}
        >
          Employee List
        </Text>

        {employees && employees.length ? (
          <Box
            overflow={{vertical: 'auto'}}
            pad={{horizontal: 'medium'}}
            height="large"
          >
            <InfiniteScroll items={employees} step={5}>
              {(emp, index) => (
                <Box
                  className="dragable-event"
                  name={emp.user.full_name}
                  data={emp.id}
                  align="start"
                  direction="row"
                  key={emp.id}
                  margin={{bottom: 'small'}}
                  style={{cursor: 'pointer', alignItems: 'center'}}
                  flex={{shrink: 0}}
                  onClick={() => handleEmployeeClick(emp.id)}
                >
                  <Avatar
                    round="full"
                    size="xxsmall"
                    width={{min: '32px'}}
                    border={emp.isSelected && {color: 'brand', size: 'small'}}
                  >
                    <Text color="light-1" size="small">
                      {emp.user.full_name.charAt(0).toUpperCase()}
                    </Text>
                  </Avatar>
                  <Text
                    color="dark-2"
                    size="xsmall"
                    margin={{left: 'xsmall'}}
                    truncate={true}
                  >
                    {emp.user.full_name.charAt(0).toUpperCase() +
                      emp.user.full_name.slice(1)}
                  </Text>
                </Box>
              )}
            </InfiniteScroll>
          </Box>
        ) : (
          <Box overflow={{vertical: 'auto'}} pad={{horizontal: 'medium'}}>
            <Text
              color="dark-1"
              size="large"
              margin={{top: '150px'}}
              textAlign="center"
            >
              No Records Found
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Filters
