import {css} from 'styled-components'
const baseSpacing = 30

const theme = {
  anchor: {
    color: {dark: 'accent-1', light: '#0f2bb4'},
    fontWeight: 400,
  },
  button: {
    color: 'white',
    extend: {
      margin: {bottom: '1rem'},
      borderRadius: '0.25rem',
      lineHeight: 1.25,
      fontWeight:400,
      
    },
    padding: {
      horizontal: '1rem',
      vertical: '0.5rem',
    },
  },
  formField: {
    label: {
      size: '0.875rem',
      margin: {horizontal: 'none', top: 'small'},
      color: 'dark-1',
      weight: 400,
    },
    error: {
      margin: {horizontal: 'none', vertical: 'small'},
      weight: 400,
      size: '0.687rem',
    },
  },
  global: {
    anchor: {
      color: {light: '#09819a'},
      fontWeight: 400,
    },
    animation: {
    //  duration: '0.2s',
    },
    borderSize: {
      xsmall: '0.5px',
      small: '2px',
      medium: '4px',
      large: '6px',
      xlarge: '12px',
    },
    drop: {
      shadowSize: 'large',
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '0.125rem',
      xsmall: '0.25rem',
      small: '0.5rem',
      medium: '1rem',
      large: '1.5rem',
      largePercentage: '2.5%',
      xlarge: '4rem',
      xlargePercentage: '4%',
      xxlarge: '8rem',
      xxlargePercentage: '8%',
    },
    hover: {
      background: {
        color: '#001e2e',
      },
    },
    size: {
      hair: '1px',
      vsmall: '2px',
      xxsmall: '32px',
      xsmall: '64px',
      small: '128px',
      medium: '256px',
      large: '384px',
      xlarge: '512px',
      xxlarge: '768px',
      full: '100%',
    },
    colors: {
      brand: '#09819a',
      white: '#FFFFFF',
      border: '#CBCBCB',
      secondary: 'rgb(68,68,68)',
      hover: '#001e2e',
      'accent-1': '#09819a',
      'accent-2': '#ED4631',
      'accent-3': '#FF6060',
      'brand-3p': '#ccdef04f',
      'brand-20p': '#ccdef0',
      'brand-light4': '#3d556b',
      'comp-1': '#2d6e16',
      'comp-2': '#6e162d',
      'dark-1': '#242424',
      'dark-2': '#707070',
      'dark-3': '#646464',
      'light-1': '#FFFFFF',
      'light-2': '#EFEFEF',
      'light-3': '#a2a8a4',
      'light-4': '#dddddd',
      'light-5': 'rgba(0,0,0,0.1)',
      'non-reocuuring-color': '#04D4F0',
      placeholder: 'rgba(0, 0, 0, 0.4)',
      text: {dark: 'rgb(99, 108, 114)', light: 'rgb(99, 108, 114)'},
      selected: 'rgb(0, 115, 157)',
      okColor:'#46B147',  
      redColor: '#FF0000',   
    },
    focus: {
      border: {
        color: 'light-3',
      },
    },
    font: {
      family:
        '"MuseoSans",Roboto!important;',
      /* size: '16px',
	height: '20px', */
    },
    input: {
      padding: `${baseSpacing / 4}px`,
      weight: 400,
      fontSize: '14px',
      color: 'dark-1',
    },
  },
  heading: {
    responsiveBreakpoint: 'xsmall',
    extend: {
      marginTop: 0,
    },
    font: {
      weight: 400,
      family:
        'MuseoSans',
    },
    level: {
      1: {
        font: {
          weight: 900,
        },
        // default size to heading level 1
        medium: {
          size: '3rem',
          maxWidth: 'none',
        },
      },
      3: {
        font: {
          weight: 400,
        },
        medium: {
          maxWidth: 'none',
          size:'2.1rem'
        },
      },
      4: {
        medium: {
          size: '1.5rem'
        }
      }
    },
  },
  paragraph: {
    // default size to paragraph
    medium: {
      size: '1.125rem',
      height: '1.5rem',
      maxWidth: 'none',
    },
    extend: {
      fontWeight: 300,
    },
  },
  select: {
    background: 'transparent',
  },
  text: {
    alert: {
      size: '0.8rem',
      maxWidth: 'none',
    },
    medium: {
      size: '14px',
      height: '1.5rem',
      maxWidth: 'none',
    },
    heroDesc: {
      size: '1.875rem',
      height: 'normal',
      maxWidth: 'none',
    },
  },
  textInput: {
    extend: {
      background: 'white',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'dark-1',
    },
  },
  maskedInput: {
    extend: {
      background: 'white',
      fontSize: '14px',
      lineHeight: '20px',
      color: 'dark-1',
    },
  },
  checkBox: {
    size: '20px',
    hover: {
      border: {
        color: {
          light: 'dark-3',
          dark: 'dark-3',
        },
      },
    },
    check: {
      thickness: '2px',
      radius: '2px',
    },
    border: {
      width: '1px',
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    toggle: {
      background: {light: 'light-4'},
      color: {
        light: 'light-1',
      },
      size: '38px',
      knob: {
        extend: `
        height: 18px;
          box-shadow: 0px 2px 2px 2px rgba(0,0,0,0.12),
           0px 2px 2px 0px rgba(0,0,0,0.24);
        `,
      },
      extend: ({checked}) => `
        ${checked && checkboxCheckStyle}
      `,
    },
  },
  calendar: {
    medium: {
      fontSize: '14px',
      lineHeight: 1,
      daySize: '35px',
    },
    day: {
      extend: {},
    },
    heading: {
      level: '5',
    },
  },
}
const checkboxCheckStyle = css`
  background-color: #09819a;
  border-color: #09819a;
`

export default theme
