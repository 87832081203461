import React from 'react'
import {withTheme} from 'styled-components'
import {Box, Button, MaskedInput} from 'grommet'
import PropTypes from 'prop-types'
import {FormUp, FormDown} from 'grommet-icons'

const NumberInput = ({
  step,
  error,
  max,
  min,
  onChange: userOnChange,
  placeholder,
  incBtnIcon = (
    <FormUp color="brand" size="15px" style={{height: '100%', width: '100%'}} />
  ),
  decBtnIcon = (
    <FormDown
      color="brand"
      size="15px"
      style={{height: '100%', width: '100%'}}
    />
  ),
  btnWrapperProps = {
    background: 'light-2',
    basis: 'xxsmall',
    height: '35px',
  },
  showButtons,
  theme,
  type,
  val,
  ...rest
}) => {
  const borderColor = theme.global.colors['status-critical']

  const isTypePhone = type === 'phone'

  let masks = []
  if (isTypePhone) {
    masks = [
      {fixed: '('},
      {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: 'xxx',
      },
      {fixed: ')'},
      {fixed: ' '},
      {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: 'xxx',
      },
      {fixed: '-'},
      {
        length: 4,
        regexp: /^[0-9]{1,4}$/,
        placeholder: 'xxxx',
      },
    ]
  } else
  {
    masks.push({
      regexp: /^[0-9]*$/,
      placeholder: placeholder,
    })
  }

  const validateAndSetValue = (event, val) => {
    if (isTypePhone) {
      userOnChange(event, val)
    } else {
      val = parseInt(val)

      if (isNaN(val)) {
        userOnChange(event, 0)
      }

      if (val <= max && val >= min) {
        userOnChange(event, val)
      }
    }
  }

  return (
    <Box
      direction="row"
      align="center"
      round="xsmall"
      border={{color: error ? borderColor : null}}
      pad="none"
      overflow="hidden"
    >
      <MaskedInput
        mask={masks}
        onChange={event => {
          const value = event.target.value
          validateAndSetValue(event, value)
        }}
        plain
        value={val + ''}
        {...rest}
      />
      {!isTypePhone && showButtons && (
        <Box {...btnWrapperProps}>
          <Button
            icon={incBtnIcon}
            style={{height: '17px'}}
            focusIndicator={false}
            onClick={event => {
              val === ''
                ? validateAndSetValue(event, 1)
                : validateAndSetValue(event, val + step)
            }}
            plain
          />
          <Button
            focusIndicator={false}
            style={{height: '17px'}}
            icon={decBtnIcon}
            onClick={event => {
              val === ''
                ? validateAndSetValue(event, -1)
                : validateAndSetValue(event, val - step)
            }}
            plain
          />
        </Box>
      )}
    </Box>
  )
}

NumberInput.defaultProps = {
  error: null,
  step: 1,
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
  onChange: () => {},
  placeholder: '',
  showButtons: false,
  theme: null,
  type: null,
}

NumberInput.propTypes = {
  error: PropTypes.object,
  step: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showButtons: PropTypes.bool,
  theme: PropTypes.object,
  type: PropTypes.oneOf(['phone']),
}

export default withTheme(NumberInput)
