import React, {useState} from 'react'
import {Box, Button, Heading, Layer, Select, Text, TextInput, Image} from 'grommet'
import {Close} from 'grommet-icons'
import PropTypes from 'prop-types'

// application imports
import Divider from 'granite/components/Divider'
import Toast from 'granite/components/Toast'
import NumberInput from 'granite/components/NumberInput'

const defaultToastData = {visible: false, text: '', background: 'accent-1'}

const ShiftMergeModal = ({messages, data, setData, saveShift, addEmployee, addEmployeeToRecurringShift,shiftData}) => {
  const [seletedShift, setSelectedShift] = useState('')
  const [newShiftName, setNewShiftName] = useState('')
  const [newShiftEstimatedEmp, setEstimatedEmp] = useState('')
  const {shift, overlappingShifts} = data
  const [toastData, setToastData] = useState(defaultToastData)

  const closeModal = () => setData({shift: null, overlappingShifts: []})
  const handleNewShiftCreateClick = () => {
    saveShift({newName: newShiftName, expectedEmployees: newShiftEstimatedEmp},'dragAddEmployee')
    setNewShiftName('')
    closeModal()
    setEstimatedEmp('')
  }
  const handleMergeBtnClick = () => {
    const {employeeIds} = shift
    const employeeId = employeeIds[0]
    if(seletedShift){
      if(seletedShift.reoccuring.endsOn || seletedShift.reoccuring.neverExpire){
        addEmployeeToRecurringShift("dragAddEmployee",seletedShift.id, employeeId, seletedShift.from)
        setSelectedShift({shift: null, overlappingShifts: []})
      }
      else{
        addEmployee(seletedShift.id, employeeId, seletedShift.from, false, 'dragAddEmployee')
        setSelectedShift({shift: null, overlappingShifts: []})
        closeModal()
      }
    }else{
       setToastData({
          visible: true,
          text: "Shift name cannot be empty",
          background: 'accent-2',
        })
        setTimeout(
          () => setToastData({visible: false, text: '', background: 'accent-1'}),
          3000,
        );
    }
  }

  return (
    <Box>
        <Layer>
          <Box
            background="light-1"
            width="large"
            round="small"
            gap="small"
            overflow="auto"
          >
            <Box
              direction="row"
              justify="between"
              align="center"
              pad={{top:'small', horizontal: 'medium'}}
              height={{min: 'auto'}}
            >
              <Heading level={3} color="dark-1" size="xsmall" margin="none">
                {messages.MERGE_MODAL_TITLE}
              </Heading>
              <Button icon={<Close size="small" />} onClick={closeModal} />
            </Box>
            <Divider background="accent-1" margin={{bottom: 'small'}} height="vsmall"/>
            <Box gap="small" pad={{top:'small', horizontal: 'large'}}>
              <Text color='dark-1' size="small">
                {messages.MERGE_MODAL_SELECT_EXISTING_SHIFT}
              </Text>
              <Select
                options={overlappingShifts}
                value={seletedShift}
                plain={false}
                style={{paddingLeft: "6px"}}
                icon={<Image
                  alt="job center"
                  src={process.env.PUBLIC_URL + '/img/dropdown-arrow.png'}
                />}
                labelKey="name"
                onChange={({option}) => {setSelectedShift(option); setNewShiftName('');setEstimatedEmp('');}}
                placeholder={messages.MERGE_MODAL_SELECT_EXISTING_PLACEHOLDER}
              />
              <Button
                fill="horizontal"
                label={messages.MERGE_MODAL_SELECT_EXISTING_BTN_LABEL}
                primary
                color="accent-1"
                margin={{top:'small',bottom: 'medium'}}
                disabled={Boolean(newShiftName)}
                onClick={handleMergeBtnClick}
              />
            <Divider background="light-5" margin={{bottom: 'medium'}} />
              <Text color='dark-1' size="small" textAlign="center">
                OR
              </Text>
              <TextInput
                value={newShiftName}
                onChange={event => {setNewShiftName(event.target.value); setSelectedShift('')}}
                placeholder={messages.MERGE_MODAL_NEW_SHIFT_NAME_PLACEHOLDER}
              />
              <Box style={{minHeight:'35px'}}>
                <NumberInput
                val={newShiftEstimatedEmp}
                min={0}
                max={99999999}
                placeholder={messages.ADD_SHIFT_MODAL_ESTIMATED_EMP_PLACEHOLDER}
                onChange={event => {
                  const val = event.target.value
                  const num = parseInt(val)
                  if (isNaN(num)) setEstimatedEmp('')
                  else setEstimatedEmp(parseInt(event.target.value))
                }}
              />
              </Box>
              <Button
                primary
                fill="horizontal"
                color="accent-1"
                label={messages.MERGE_MODAL_NEW_SHIFT_BTN_LABEL}
                onClick={handleNewShiftCreateClick}
                disabled={!Boolean(newShiftName)}
                margin={{top:'small', bottom: 'large'}}
              />
            </Box>
          </Box>
        </Layer>
    {toastData.visible && (
      <Toast
        onClose={() =>
        setToastData({visible: false, text: '', background: 'accent-1'})
        }
        text={toastData.text}
        background={toastData.background}
      />
    )}
    </Box>
  )
}

ShiftMergeModal.defaultProps = {
  messages: {},
}

ShiftMergeModal.propTypes = {
  data: PropTypes.object,
  messages: PropTypes.object,
}

export default ShiftMergeModal
