import React, { useCallback, useState, useEffect } from 'react';
import { Box, TextInput, Button, Text } from 'grommet';
import { Formik, Field } from 'formik';
import Cookies from 'universal-cookie';
import ReactTable from 'react-table';
import moment from 'moment';
import PropTypes from 'prop-types';

import CheckedIcon from 'pages/addOrEditPtoPolicy/icons/CheckedIcon.svg';
import FormField from 'granite/components/FormField';
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage';
import DateTimePickerAtom from 'generics/DateTimePickerFormik';
import HolidayRemoveModal from 'pages/addOrEditPtoPolicy/component/HolidayRemoveModel';
import { PTO_POLICY_EVENTS } from 'pto/constants';

import { deleteHoliday, getHolidaysList, updateHoliday, updatePtoPolicy } from 'pto/controllers/pto';
import { getHolidayColumns } from 'pages/addOrEditPtoPolicy/component/columns';
import { deepEqual, HOLIDAY_INITIAL_VALUES, HOLIDAY_VALIDATION_SCHEMA } from 'pages/addOrEditPtoPolicy/helper/utils';
import { PTO_TABS, TOAST_MESSAGES } from 'pto/strings';

const cookies = new Cookies();

const HolidayCalender = ({
  data,
  eventEmitter,
  submitting,
  setSubmitting,
  setActiveStep,
  dateFormat,
  dateFormatDisplay,
  policyId,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageHolidaySelection, setPageHolidaySelection] = useState();

  const [currentPageSize, setCurrentPageSize] = useState(
    cookies.get('pageSizeHolidayTab') !== 'undefined' ? cookies.get('pageSizeHolidayTab') : 5,
  );
  const [deleteHolidayModal, setDeleteHolidayModal] = useState({ show: false, data: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [oldValues, setOldValues] = useState({});

  const [holidayList, setHolidayList] = useState([]);
  const [fetchState, setFetchState] = useState(null);

  const toggleDeleteLayer = useCallback(params => {
    if (params?.show) setDeleteHolidayModal(params);
    else setDeleteHolidayModal({ show: false, data: null });
  }, []);

  const previousPage = () => {
    GenericWindowPostMessage(PTO_TABS.POLICY_TAB, { policyId: data?.id });
    setActiveStep(0);
  };

  const handleNextPage = () => {
    GenericWindowPostMessage(PTO_TABS.EMPLOYEE_TAB, { policyId: data?.id });
    setActiveStep(2);
  };

  const onSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitting(true);
    setErrors({});
    let payload;
    if (values?.edit) {
      setLoading(true);
      payload = {
        old_data: { ...oldValues, date: moment(oldValues?.date).format('YYYY-MM-DD') },
        new_data: { ...values, date: moment(values?.date).format('YYYY-MM-DD'), edit: undefined },
      };
      updateHoliday(eventEmitter, payload, data.id);
    } else {
      payload = {
        holiday_config: { ...values, date: moment(values?.date).format('YYYY-MM-DD') },
      };
      updatePtoPolicy(eventEmitter, payload, data.id, 1);
    }
    resetForm();
    setSubmitting(false);
  };

  const pageChange = useCallback(item => {
    cookies.set('holidayTab', +item + 1, { path: '/' });
    item < 0 ? setCurrentPage(item + 1) : setCurrentPage(item);
  }, []);

  const pageSizeChange = useCallback((pageSize, pageIndex) => {
    cookies.set('pageSizeHolidayTab', pageSize, { path: '/' });
    cookies.set('holidayTab', pageIndex, { path: '/' });
    setCurrentPageSize(pageSize);
    setCurrentPage(pageIndex);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDeleting(true);
    let payload = {
      holiday_remove_name: deleteHolidayModal?.data?.name,
    };
    deleteHoliday(eventEmitter, payload, data.id);
  }, [data, deleteHolidayModal, eventEmitter]);

  const getHolidayList = useCallback(
    params => {
      setLoading(true);
      getHolidaysList(eventEmitter, {
        paginate: true,
        policyId,
        page_size: params?.pageSize || currentPageSize,
        page: params?.page + 1 || currentPage + 1,
      });
    },
    [currentPage, currentPageSize, eventEmitter, policyId, setLoading],
  );

  const fetchHolidayList = useCallback(
    params => {
      const { page: nextPage, pageSize, sorted } = params || {};
      const nextFetch = { page: nextPage, pageSize, sorted };
      const prevFetch = fetchState;
      if (!deepEqual(nextFetch, prevFetch)) {
        setFetchState(nextFetch);
        getHolidayList(nextFetch);
      }
    },
    [fetchState, getHolidayList],
  );

  useEffect(
    function init() {
      const observable = eventEmitter.getObservable();
      let subscription;
      listenObservable();

      function listenObservable() {
        subscription = observable.subscribe(event => {
          switch (event.type) {
            case PTO_POLICY_EVENTS.DELETE_HOLIDAY_SUCCESS:
              GenericWindowPostMessage('SUCCESS_TOAST', {
                toastMessage: TOAST_MESSAGES.DELETE_HOLIDAY_SUCCESS,
              });
              setIsDeleting(false);
              toggleDeleteLayer();
              getHolidayList();
              break;

            case PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS:
              GenericWindowPostMessage('SUCCESS_TOAST', {
                toastMessage: TOAST_MESSAGES.CREATE_HOLIDAY_SUCCESS,
              });
              getHolidayList();
              setLoading(false);
              break;
            case PTO_POLICY_EVENTS.DELETE_HOLIDAY_FAILURE:
              GenericWindowPostMessage('FAILURE_TOAST', {
                toastMessage: event.data || TOAST_MESSAGES.DELETE_HOLIDAY_FAILURE_FALLBACK,
              });
              setIsDeleting(false);
              break;

            case PTO_POLICY_EVENTS.UPDATE_HOLIDAY_SUCCESS:
              GenericWindowPostMessage('SUCCESS_TOAST', {
                toastMessage: TOAST_MESSAGES.UPDATE_HOLIDAY_SUCCESS,
              });
              getHolidayList();
              setLoading(false);
              break;
            case PTO_POLICY_EVENTS.UPDATE_HOLIDAY_FAILURE:
              GenericWindowPostMessage('FAILURE_TOAST', {
                toastMessage: event.data || TOAST_MESSAGES.GET_EMPLOYEE_LIST_FAILURE_FALLBACK,
              });
              setLoading(false);
              break;
            case PTO_POLICY_EVENTS.GET_HOLIDAY_LIST_SUCCESS:
              let pageSize = cookies.get('pageSizeHolidayTab') !== undefined ? cookies.get('pageSizeHolidayTab') : 5;
              setPageHolidaySelection(Math.ceil(event.data.count / pageSize));
              setHolidayList(event.data.results);
              setLoading(false);
              break;
            case PTO_POLICY_EVENTS.GET_HOLIDAY_LIST_FAILURE:
              setHolidayList([]);
              GenericWindowPostMessage('FAILURE_TOAST', {
                toastMessage: event.data || TOAST_MESSAGES.GET_EMPLOYEE_LIST_FAILURE_FALLBACK,
              });
              setLoading(false);
              break;
            default:
          }
        });
      }
      return () => subscription?.unsubscribe();
    },
    [eventEmitter, fetchHolidayList, getHolidayList, setLoading, toggleDeleteLayer],
  );

  return (
    <Formik
      initialValues={HOLIDAY_INITIAL_VALUES}
      validationSchema={HOLIDAY_VALIDATION_SCHEMA}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setValues }) => (
        <form onSubmit={handleSubmit}>
          <Box pad={{ top: 'medium', left: 'medium' }}>
            <Text size="14px" color="black">
              Paid Holiday
            </Text>
          </Box>
          <Box
            width="100%"
            pad={{
              top: 'small',
              left: 'medium',
              right: 'medium',
              bottom: 'xlarge',
            }}
            gap="medium"
            direction="row"
          >
            <FormField name="date" label="Date*" error={touched.date && errors.date} normal>
              <Field
                id="date"
                name="date"
                placeholder="Date*"
                component={DateTimePickerAtom}
                options={{ dateFormat }}
                value={values?.date}
                disabled={disabled}
              />
            </FormField>
            <FormField name="name" label="Name*" error={touched.name && errors.name}>
              <TextInput
                name="name"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                style={{ opacity: '1.0', border: '1px solid red' }}
                value={values?.name}
                placeholder='Enter Name'
              />
            </FormField>
            <FormField
              name="number_of_hours"
              label="No. of Hours*"
              error={touched.number_of_hours && errors.number_of_hours}
            >
              <TextInput
                name="number_of_hours"
                id="number_of_hours"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                style={{ opacity: '1.0', border: '1px solid red' }}
                value={values?.number_of_hours}
                placeholder='0'
              />
            </FormField>
            <Button type="submit" disabled={loading || disabled} style={{ paddingTop: '28px' }}>
              <img src={CheckedIcon} alt="checked-icon" width={'18px'} height={'18px'} />
            </Button>
          </Box>
          <Box pad="medium">
            <ReactTable
              data={holidayList || data?.holiday_config || []}
              noDataText="No Records Found"
              manual
              loading={loading}
              minRows={1}
              onFetchData={fetchHolidayList}
              onPageSizeChange={pageSizeChange}
              onPageChange={pageChange}
              page={currentPage}
              pages={pageHolidaySelection}
              pageSize={currentPageSize}
              columns={getHolidayColumns(setValues, dateFormatDisplay, setOldValues, toggleDeleteLayer, disabled)}
            />
          </Box>
          <Box pad={{ left: 'xlarge' }} direction="row" justify="end">
            <Button
              margin={{ right: 'medium' }}
              label="Previous"
              color="accent-1"
              onClick={previousPage}
              disabled={submitting}
              primary
            />
            <Button
              onClick={handleNextPage}
              label={!submitting ? 'Next' : 'Submitting...'}
              color="accent-1"
              disabled={submitting}
              primary
            />
          </Box>
          {deleteHolidayModal?.show && (
            <HolidayRemoveModal
              toggleDeleteLayer={toggleDeleteLayer}
              handleDelete={handleDelete}
              isDeleting={isDeleting}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

HolidayCalender.propTypes = {
  data: PropTypes.object,
  updatePtoPolicy: PropTypes.func,
  eventEmitter: PropTypes.object,
  submitting: PropTypes.bool,
  setSubmitting: PropTypes.func,
  disabled: PropTypes.bool,
  setActiveStep: PropTypes.func,
  dateFormat: PropTypes.string,
  loading: PropTypes.bool,
  dateFormatDisplay: PropTypes.string,
  setLoading: PropTypes.bool,
  policyId: PropTypes.oneOfType(['string', 'number']),
};

export default HolidayCalender;
