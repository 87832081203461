import React, {useState} from 'react'
import {Link, matchPath} from 'react-router-dom'
import {Anchor as GrommetAnchor, Box, Keyboard} from 'grommet'
import styled from 'styled-components'

import MenuButton from 'granite/components/MenuButton'

const Anchor = styled(GrommetAnchor)`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`

const getFlatChildrenIds = children =>
  children.reduce((flatChildren, item) => {console.log('item', item.getpath())

    if (item.getchildren()) {
      flatChildren.push(item.id || item.label)
      flatChildren = flatChildren.concat(getFlatChildrenIds(item.getchildren()))
    }
    return flatChildren
  }, [])

const VerticalMenu = ({data, hoverIndicatorOptions, isCollapsed, pathname}) => {
  const [expandedItems, setExpandedItems] = useState([])

  const onMenuChange = (item, expanded) => {
    let newExpandedItems = [...expandedItems]
    if (expanded) {
      const toBeCollapsedIds = getFlatChildrenIds([item])
      newExpandedItems = newExpandedItems.filter(
        itemId => toBeCollapsedIds.indexOf(itemId) < 0,
      )
    } else {
      newExpandedItems.push(item.id || item.label)
    }
    setExpandedItems(newExpandedItems)
  }
  const renderItem = (item, level = 0) => {
    const itemId = item.id || item.getlabel()
    const isExpanded = expandedItems.includes(itemId)
    const itemKey = `item_${itemId}_${level}`
    const isActive = Boolean(matchPath(pathname, {path: '/' + item.getpath()})) || item.getlabel()==="Scheduling"

    const content = (
      <MenuButton
        label={item.getlabel()}
        id={itemId}
        icon={item.geticon()}
        level={level}
        isActive={isActive}
        activeColor="white"
        isExpandable={item.getchildren() && item.getchildren().length > 0}
        isExpanded={isExpanded}
        isCollapsed={isCollapsed}
        hoverIndicatorOptions={hoverIndicatorOptions}
        onClick={() => onMenuChange(item, isExpanded)}
        textColor="light-1"
      />
    )

    return (
      <Box key={itemKey}>
        {item.getchildren().length > 0 ? (
          <Keyboard
            onDown={() => this.onMenuChange(item, false)}
            onRight={() => this.onMenuChange(item, false)}
            onUp={() => this.onMenuChange(item, true)}
            onLeft={() => this.onMenuChange(item, true)}
          >
            {content}
          </Keyboard>
        ) : item.isExternalLink() ? (
          <Anchor href={item.getpath()}>{content}</Anchor>
        ) : (
          <Link
            to={item.isExternalLink() ? item.getpath() : '/' + item.getpath()}
          >
            {content}
          </Link>
        )}
        {item.getchildren() &&
          isExpanded && !isCollapsed &&
          item.getchildren().map(child => renderItem(child, level + 1))}
        {item.getchildren().length > 0 &&
          isExpanded && isCollapsed
          && renderChild(item.getchildren())}
      </Box>
    )
  }

  const renderChild = (children) =>{
    return(
      <Box style={{position:'absolute', left:'100%', backgroundColor: 'white', zIndex: '999' , 'minWidth': '200px', border: '1px solid rgba(0,0,0,0.2)', fontSize: '14px'}}>
      {children.map(child=>
        <Anchor style={{ color: '#707070', 'fontWeight': '500', padding: '10px'}} href={child.getpath()}>{child.getlabel()}</Anchor>
      )}
      </Box>
    )
  }

  return (
    <Box flex={{grow: 1}}>{data && data.map(item => renderItem(item))}</Box>
  )
}

export default VerticalMenu
