import React, {useState} from 'react'
import {Text, TextInput} from 'grommet'

function DataGridTextCell({data, rawData = data, editMode = false, ...rest}) {
  const [inputValue, setInputValue] = useState(rawData)

  return (
    <>
      {editMode ? (
        <TextInput
          value={inputValue}
          onChange={event => setInputValue(event.target.value)}
        />
      ) : (
        <Text color="text" {...rest}>{data}</Text>
      )}
    </>
  )
}

export default DataGridTextCell
