import PropTypes from 'prop-types'
import React from 'react'
import {Box, Button, Text, Layer} from 'grommet'
import {FormClose} from 'grommet-icons'

const Toast = ({text, textColor, background, onClose,position="bottom"}) => {
  return (
    <Layer
      position={position}
      modal={false}
      margin={{vertical: 'medium', horizontal: 'small'}}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad={{vertical: 'xsmall', horizontal: 'small'}}
        background={background ? background : '#4cbcb7'}
      >
        <Box align="center" direction="row" gap="xsmall">
          <Text color={textColor}>{text}</Text>
        </Box>
        {onClose ? (
          <Button
            icon={<FormClose color={textColor} />}
            onClick={onClose}
            plain
          />
        ) : null}
      </Box>
    </Layer>
  )
}

Toast.defaultProps = {
  text: '',
  textColor: 'light-1',
  background: null,
  onClose: () => console.log('Toast Closed'),
}

Toast.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
  background: PropTypes.string,
  onClose: PropTypes.func,
}

export default Toast
