import React from 'react';
import { Box, Button, Heading, Layer, Text } from 'grommet';
import { Close } from 'grommet-icons';
import PropTypes from 'prop-types';

// CORE imports
import Divider from 'granite/components/Divider';

const HolidayRemoveModal = ({ toggleDeleteLayer, handleDelete, isDeleting }) => {
  return (
    <Layer modal={true} position={'top'}>
      <Box background="light-1" round="small" overflow={{ vertical: 'auto' }}>
        <Box
          direction="row"
          justify="between"
          align="center"
          pad={{ top: 'small', bottom: 'xsmall', horizontal: 'medium' }}
          height={{ min: 'auto' }}
        >
          <Heading level={3} color="dark-1" size="xsmall" margin="none">
            Remove Holiday
          </Heading>
          <Button icon={<Close size="small" />} onClick={toggleDeleteLayer} />
        </Box>
        <Divider background="accent-1" margin={{ bottom: 'small' }} height="vsmall" />
        <Box direction="column" gap="small" pad={{ top: 'small', horizontal: 'large' }}>
          <Text>Are you sure you want to remove this holiday from this policy ?</Text>
          <span style={{ textAlign: 'right' }}>
            <Button
              primary
              alignSelf="end"
              color="accent-2"
              size="small"
              label={isDeleting ? 'Removing ...' : 'Yes'}
              disabled={isDeleting}
              onClick={handleDelete}
              margin={{ bottom: 'large' }}
            />
            <Button
              primary
              color="accent-1"
              size="small"
              label="No"
              disabled={isDeleting}
              onClick={toggleDeleteLayer}
              margin={{ left:'20px', bottom: 'large' }}
            />
          </span>
        </Box>
      </Box>
    </Layer>
  );
};

HolidayRemoveModal.propTypes = {
  toggleDeleteLayer: PropTypes.func,
  handleDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
};

export default HolidayRemoveModal;
