// Lib imports
import React, {useState, useEffect, useCallback} from 'react'
import {withRouter} from 'react-router-dom'
import {Box, Heading} from 'grommet'

// Core imports
import Toast from 'granite/components/Toast'
import EventEmitter from 'utils/event-emitter'
import ONBOARDING_MESSAGES from 'messages/onBoarding'

// Application imports
import {createOrganisationClicked} from 'organisations/controllers/user'
import OnBoardingForm from './components/OnBoardingForm'
import ONBOARDING_EVENTS from './constants'

const OnBoarding = ({dispatch, history}) => {
  const eventEmitter = new EventEmitter()
  const defaultToastData = {open: false, message: ''}

  // Define/Connect state
  const [toastData, setToastData] = useState(defaultToastData)

  // Internal utils methods
  const showToast = message => setToastData({open: true, message})
  const hideToast = () => setToastData({open: false, message: ''})
  const goToDashboard = useCallback(() => history.push('/dashboard'), [history])

  /**
   * Effect: subscribe and listen to Rx events
   * Cleanup: unsubscribe to Rx observable
   * Deps: eventEmitter, goToDashboard
   */
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case ONBOARDING_EVENTS.LOGIN_SUCCESS:
          showToast(ONBOARDING_MESSAGES.ONBOARDING_SUCCESS)
          setTimeout(() => {
            hideToast()
            goToDashboard()
          }, 1000)
          break
        case ONBOARDING_EVENTS.LOGIN_FAILURE:
          showToast(ONBOARDING_MESSAGES.ONBOARDING_FAILURE)
          setTimeout(() => {
            hideToast()
          }, 1000)
          break
        default:
          break
      }
    })
    return () => subscription.unsubscribe()
  }, [eventEmitter, goToDashboard])

  return (
    <Box
      align="center"
      background="brand-3p"
      flex="grow"
      justify="center"
      margin="none"
      pad="medium"
    >
      <Box width="large" animation="fadeIn" pad="medium">
        <Heading color="dark-1" level={1} margin={{bottom: 'small'}}>
          Getting Started
        </Heading>
        <Heading level={2} margin={{bottom: 'large'}}>
          Fill in the required information to for a good experience
        </Heading>
        <OnBoardingForm
          onSubmit={createOrganisationClicked}
          eventEmitter={eventEmitter}
        />
      </Box>
      {toastData.open ? <Toast text={toastData.message} /> : null}
    </Box>
  )
}

export default withRouter(OnBoarding)
