import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class Organisation {
  constructor({id, name, email, ...data}) {
    if (
      _.isNil(id) ||
      _.isNil(name) ||
      !_.isString(name) ||
      _.isNil(email) ||
      !_.isString(email)
    ) {
      throw new GraniteError('Organisation must have name and email')
    }
    this.id = id
    this.name = name
    this.email = email || ''
  }
}

export default Organisation
