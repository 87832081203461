// Lib imports
// import _ from 'lodash/fp';
import moment from 'moment';

// Application imports
import ptoApi from 'pto/gateways/pto-api';
import { PTO_POLICY_EVENTS, ACCURAL_TYPE_VALUES } from 'pto/constants';
import GraniteError from 'utils/granite-error';

// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */

async function fetchAllPtoPolicy(eventEmitter, params) {
  try {
    eventEmitter.emit(PTO_POLICY_EVENTS.SHOW_LOADER);
    const data = await ptoApi.fetchAllPtoPolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS, data);
    eventEmitter.emit(PTO_POLICY_EVENTS.HIDE_LOADER);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE, errorMessage);
    eventEmitter.emit(PTO_POLICY_EVENTS.HIDE_LOADER);
  }
}

async function fetchPtoRequestList(eventEmitter, params) {
  try {
    const data = await ptoApi.fetchPtoRequestList(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_LIST_FAILURE, errorMessage);
  }
}

async function fetchPtoRequest(eventEmitter, params) {
  try {
    const data = await ptoApi.fetchPtoRequest(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_SUCCESS, data);
  } catch (err) {

    const errorMessage = err && err?.errors?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_FAILURE, errorMessage);
  }
}

async function fetchPtoTimeCardRequest(eventEmitter, params) {
  try {
    const data = await ptoApi.fetchPtoTimeCardRequest(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_REQUEST_FAILURE, errorMessage);
  }
}

async function fetchPtoPolicy(eventEmitter, params) {
  try {
    if (!params.policyId) {
      eventEmitter.emit(PTO_POLICY_EVENTS.HIDE_LOADER);
      return;
    }

    const data = await ptoApi.fetchPtoPolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_PTO_POLICY_FAILURE, errorMessage);
  }
}
async function getEmployeePolicy(eventEmitter, params) {
  try {
    const data = await ptoApi.getEmployeePolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_POLICY_FAILURE, errorMessage);
  }
}

async function getAccuralHistory(eventEmitter, params) {
  try {
    const data = await ptoApi.getAccuralHistory(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_ACCURAL_HISTORY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_ACCURAL_HISTORY_FAILURE, errorMessage);
  }
}

async function getEmployeesList(eventEmitter, params) {
  try {
    const data = await ptoApi.getEmployeesList(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_EMPLOYEE_LIST_FAILURE, errorMessage);
  }
}

async function deletePtoPolicy(eventEmitter, params) {
  try {
    const data = await ptoApi.deletePtoPolicy(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.DELETE_PTO_POLICY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.DELETE_PTO_POLICY_FAILURE, errorMessage);
  }
}

async function createPtoPolicy(eventEmitter, values, oauthToken) {
  try {
    const data = await ptoApi.createPtoPolicy({ values, authToken: oauthToken });
    eventEmitter.emit(PTO_POLICY_EVENTS.CREATE_PTO_POLICY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.CREATE_PTO_POLICY_FAILURE, errorMessage);
  }
}

async function addTimeOff(eventEmitter, params) {
  try {
    const data = await ptoApi.addTimeOff(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.ADD_TIME_OFF_SUCCESS, data);
    return data;
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.ADD_TIME_OFF_FAILURE, errorMessage);
  }
}

async function updateTimeOff(eventEmitter, params) {
  try {
    const data = await ptoApi.updateTimeOff(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_SUCCESS, data);
    return data;
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_FAILURE, errorMessage);
  }
}

async function updateTimeCardTimeOff(eventEmitter, params) {
  try {
    const data = await ptoApi.updateTimeCardTimeOff(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_TIME_OFF_FAILURE, errorMessage);
  }
}

async function updatePtoPolicy(eventEmitter, params, id, key) {
  try {
    const data = await ptoApi.updatePtoPolicy({ params }, id);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS, { data, key });
    if (key !== 'viewTImeOff') {
      fetchPtoPolicy(eventEmitter, { policyId: id });
    }
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_FAILURE, errorMessage);
  }
}

/**
 *
 * used to save accural data in pto policy section
 *
 * @param {object} eventEmitter : used to emit success and failure events
 * @param {object} param : object contains values for payload
 * @param {number} id :pk of the policy
 * @param {*} key : flag used to fetch policies
 *
 * @emits UPDATE_PTO_POLICY_SUCCESS : emits the data on success of save api
 * @emits UPDATE_PTO_POLICY_FAILURE : emits on failure of api for error toast
 */
async function saveAccuralPolicy(eventEmitter, param, id, key) {
  try {
    const params =
      param.accural_type === ACCURAL_TYPE_VALUES.MANUAL
        ? { accural_type: param.accural_type ? param.accural_type : '' }
        : param.accural_type === ACCURAL_TYPE_VALUES.YEARLY
        ? {
            accural_type: param.accural_type ? param.accural_type : '',
            accural_config: {
              total_accural_hours: parseInt(param.total_accural_hours),
            },
            accural_date:
              param.accural_on_hire_date === 'true' ? null : moment(param.accural_date).format('YYYY-MM-DD'),
            accural_on_hire_date: param.accural_on_hire_date === 'true' ? true : false,
            allow_entries_outside_employee_current_accural_year:
              param.allow_entries_outside_employee_current_accural_year,
            use_or_lose_it: param.use_it_or_lose_it,
          }
        : param.accural_type === ACCURAL_TYPE_VALUES.EVERY_PAY_PERIOD
        ? {
            accural_type: param.accural_type ? param.accural_type : '',
            accural_config: {
              total_accural_hours: parseInt(param.total_accural_hours),
              every_pay_period_per: param.every_pay_period_per,
              accure_day_wise: parseInt(param.accure_day_wise),
            },
            accural_date:
              param.accural_on_hire_date === 'true' ? null : moment(param.accural_date).format('YYYY-MM-DD'),

            accural_on_hire_date: param.accural_on_hire_date === 'true' ? true : false,
            use_or_lose_it: param.use_it_or_lose_it,
          }
        : {
            accural_type: param.accural_type ? param.accural_type : '',
            accural_config: {
              total_accural_hours: parseInt(param.total_accural_hours),
              every_pay_period_per: param.every_pay_period_per,
              based_on_hours_worked_per: parseInt(param.based_on_hours_worked_per),
              accure_day_wise: parseInt(param.accure_day_wise),
            },
            use_or_lose_it: param.use_it_or_lose_it,
            accural_date:
              param.accural_on_hire_date === 'true' ? null : moment(param.accural_date).format('YYYY-MM-DD'),
            accural_on_hire_date: param.accural_on_hire_date === 'true' ? true : false,
          };

    const data = await ptoApi.updatePtoPolicy({ params }, id);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_SUCCESS, { data, key });
    if (key !== 'viewTImeOff') {
      fetchPtoPolicy(eventEmitter, { policyId: id });
    }
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_PTO_POLICY_FAILURE, errorMessage);
  }
}

async function getHolidaysList(eventEmitter, params) {
  try {
    const data = await ptoApi.getHolidaysList(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_HOLIDAY_LIST_SUCCESS, data);
  } catch (err) {
    const errResp = err?.response ? err?.response?.data?.message : err;
    let errMsg = new GraniteError(errResp);
    const errorMessage = errMsg && errMsg?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.GET_HOLIDAY_LIST_FAILURE, errorMessage);
  }
}

async function updateHoliday(eventEmitter, params, id) {
  try {
    const data = await ptoApi.updateHoliday({ params }, id);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_HOLIDAY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_HOLIDAY_FAILURE, errorMessage);
  }
}

async function deleteHoliday(eventEmitter, params, id) {
  try {
    const data = await ptoApi.updatePtoPolicy({ params }, id);
    eventEmitter.emit(PTO_POLICY_EVENTS.DELETE_HOLIDAY_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.DELETE_PTO_POLICY_FAILURE, errorMessage);
  }
}

async function updateEmployeeBalance(eventEmitter, params) {
  try {
    const data = await ptoApi.updateEmployeeBalance(params);
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_SUCCESS, data);
  } catch (err) {
    const errorMessage = err && err?.errors?.non_field;
    eventEmitter.emit(PTO_POLICY_EVENTS.UPDATE_POLICY_BALANCE_FAILURE, { errorMessage, prvData: params.data });
  }
  //fetchPtoPolicy(eventEmitter, {policyId: id, authToken: oauthToken}
}

export {
  fetchAllPtoPolicy,
  deletePtoPolicy,
  createPtoPolicy,
  updatePtoPolicy,
  fetchPtoPolicy,
  fetchPtoRequestList,
  fetchPtoRequest,
  addTimeOff,
  getEmployeePolicy,
  updateTimeOff,
  getEmployeesList,
  getAccuralHistory,
  updateEmployeeBalance,
  updateTimeCardTimeOff,
  fetchPtoTimeCardRequest,
  deleteHoliday,
  updateHoliday,
  getHolidaysList,
  saveAccuralPolicy,
};
