import React from 'react';
import { Card, Box, Text } from 'grommet';
// sport PreviewPane from 'granite-admin/core/components/Documents/components/PreviewPane';
import PropTypes from 'prop-types';
const FilePreviewComponent = ({ data, index }) => {
  return (
    <>
      <Card border>
        <Box direction="row" gap="small" pad={'small'}>
          <Box direction="row">
            {data.behaviour === 'MANDATORY' && <Text color={'red'}>*</Text>}
            <Text>{index + 1}.</Text>
          </Box>

          <Text>{data.question}</Text>
        </Box>
        <Box pad={'medium'} height={'200px'}>
          <iframe
            src={data?.response[0]?.value}
            title='formPreview'
            
          />
        </Box>
      </Card>
    </>
  );
};

FilePreviewComponent.propTypes = {
  data: PropTypes.object,
  index: PropTypes.any,
};
export default FilePreviewComponent;
