// lib imports
import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import Moment from 'moment'
import {extendMoment} from 'moment-range'
import {withTheme} from 'styled-components'
import Cookies from 'universal-cookie';

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import './index.css'

const moment = extendMoment(Moment)
let eventDropInfoFunc = {}

const cookies = new Cookies();
let typeView = 'timeGridWeek';

// NOTE: event here refers to fullcalendar event, shift is our business entity
const Calendar = ({
  shifts,
  setShifts,
  employees,
  mapEventToShift,
  fullcalendarConfig,
  theme,
  updateDragShift,
  editRecurring,
  revertFunc,
  setRevert,
  date_format,
  shiftManagement,
  selectedDate
}) => {
  const calendarComponentRef = useRef()
  const [calendarEvents, setCalendarEvents] = useState([])
  // NOTE: don't use useState variables with this component, fullcalendar starts to act funny

  // hooks
  const initEvents = () => {

    const calendarApi = calendarComponentRef &&calendarComponentRef.current && calendarComponentRef.current.getApi()
   let allCalendarEvents=shifts && shifts.length && shifts.map(shift => {
      const eventObjects = mapShiftToEvents(shift)
      return eventObjects[0]
      // eventObjects && eventObjects.length && eventObjects.length > 0 && eventObjects.forEach(event => {
      //   const fullcalendarEvent = calendarApi && calendarApi.addEvent(event)
      // })
    })
   if(allCalendarEvents) 
   {
   allCalendarEvents.push({
    groupId: 'future',
    start: Date.now(),
    end: new Date('December 17, 5000'),
    rendering: 'background',
    backgroundColor: 'transparent',
  })
  calendarApi && calendarApi.addEventSource(allCalendarEvents)
    //Shift Management calendar view type saved to retain the selected view after refresh
  }
  //calendarApi && calendarApi.adEventSource(allCalendarEvents)
    //Shift Management calendar view type saved to retain the selected view after refresh
    if(shiftManagement && calendarApi ){
      typeView=calendarApi.state.viewType;
    }

    // NOTE: add background event to allow dragging on only future dates
    /*calendarApi && calendarApi.addEvent({
      groupId: 'future',
      start: Date.now(),
      end: new Date('December 17, 5000'),
      rendering: 'background',
      backgroundColor: 'transparent',
    })*/

    return function cleanup() {
      //cleanup :clear all events
      // const allFullcalendarEvents = getEvents()
      // allFullcalendarEvents.forEach(event => event.remove())
  
      if(calendarApi) {
      const allEventSources = calendarApi.getEventSources()
       if(allEventSources) allEventSources.forEach(i => i?.remove())
      }

    }
    
  }
  useEffect(initEvents, [shifts, calendarComponentRef])

  useEffect(() => {
    const calendarApiVal = shiftManagement && calendarComponentRef && calendarComponentRef.current && calendarComponentRef.current.getApi() 
    selectedDate && calendarApiVal.gotoDate(selectedDate)
  }, [selectedDate, shiftManagement])

  useEffect(() => {
      cookies.set('viewType', typeView,"SameSite=None")
    }, [typeView])


  const getColorForEvent = shift => {
    const {from, to, employeesData} = shift
    const okColor = theme.global.colors['okColor']
    const warningColor = theme.global.colors['accent-1']
    const disabledColor = theme.global.colors['light-3']
    const upcomingEventColor = theme.global.colors['neutral-3']
    const nonreocurringevent = theme.global.colors[`non-reocuuring-color`]  
    const minimumEmployeeColor = theme.global.colors['redColor']  

    const isFutureEvent = moment(from).isAfter(Date.now())
    const isPastEvent = moment(Date.now()).isAfter(to)
    let areAllEmployeesPresent = true

    const shiftRange = moment().range(moment(from), moment(to))
    const isCurrentDateInBetween = shiftRange.contains(Date.now())
    
    employeesData && employeesData.length && employeesData.length > 0 && employeesData.forEach(emp => {

      if (emp && emp.status === 'absent') areAllEmployeesPresent = false
    })

    if( shift.reoccuring && shift.reoccuring.days.length === 0 && !isPastEvent && !isCurrentDateInBetween){
      return nonreocurringevent
    } else if (isFutureEvent && !shift.reoccuring) {
      return nonreocurringevent
    } else if (isFutureEvent) {
      return upcomingEventColor
    } else if (isPastEvent) {
      return disabledColor
    } else {
      return areAllEmployeesPresent ? okColor : warningColor
    }
  }

  const getEditableConfig = shift => {
    const {from} = shift

    const isPastEvent = moment(Date.now()).isAfter(from)
    if (isPastEvent) {
      return {editable: false, startEditable: false, durationEditable: false}
    }
    return {}
  }

  //function returns showEmployeeDot when minimum match would be false otherwise return doNotShowEmployeeDot
  const getEmployeeColorDot = shift => {
    const {from, to, employeesData} = shift

    const isFutureEvent = moment(from).isAfter(Date.now())
    const isPastEvent = moment(Date.now()).isAfter(to)
    let areAllEmployeesPresent = true

    const shiftRange = moment().range(moment(from), moment(to))
    const isCurrentDateInBetween = shiftRange.contains(Date.now())
    
    employeesData && employeesData.length && employeesData.length > 0 && employeesData.forEach(emp => {
      if (emp && emp.status === 'absent') areAllEmployeesPresent = false
    })

    if(shift.shiftStat && shift.shiftStat.length && shift.shiftStat[0].minimum_match==false){
      return 'showEmployeeDot'
    } else if( shift.reoccuring && shift.reoccuring.days.length === 0 && !isPastEvent && !isCurrentDateInBetween){
      return 'doNotshowEmployeeDot'
    } else if (isFutureEvent && !shift.reoccuring) {
      return 'doNotshowEmployeeDot'
    } else if (isFutureEvent) {
      return 'doNotshowEmployeeDot'
    } else if(isPastEvent){
      if(!shift.shiftStat.length && shift.expectedEmployees >0){
        return 'showEmployeeDot'
      }
    } else if(areAllEmployeesPresent) {
      if(shift.shiftStat && shift.shiftStat.length && shift.shiftStat[0].minimum_match==false){
        return 'showEmployeeDot'
      }else if(!shift.shiftStat.length && shift.expectedEmployees >0){
        return 'showEmployeeDot'
      }
      else return 'doNotshowEmployeeDot';
    }
  }

  const mapShiftToEvents = shift => {
    if (!shift.reoccuring || !shift.reoccuring.endsOn || !shift.reoccuring.neverExpire)
      return [mapShiftToEvent(shift)]
    return [mapShiftToEvent(shift)]
  }

  const mapShiftToEvent = ({
    id,
    name,
    from,
    to,
    employeeIds,
    end_date,
    reoccuring,
    deviationThreshold,
    punchInRestrictions,
    expectedEmployees,
    shiftStat,
    timefence_start_time,
    timefence_end_time,
  }) => {
    const editableConfig = getEditableConfig({from})
    const employeesData = employeeIds || []

    const color = getColorForEvent({from, to, employeesData, reoccuring, shiftStat, expectedEmployees})

    const employeeDot = getEmployeeColorDot({shiftStat, expectedEmployees, from, to, employeesData})

    const event = {
      // NOTE: all events are only draggable on future
      constraint: 'future',
      id,
      title: name,
      start: moment(from, moment.defaultFormat).toDate(),
      backgroundColor: color,
      borderColor: color,
      end: moment(to, moment.defaultFormat).toDate(),
      extendedProps: {
        employees: employeesData,
        deviationThreshold,
        punchInRestrictions,
        expectedEmployees,
        reoccuring,
        shiftStat,
        end_date,
        timefence_start_time,
        timefence_end_time,
        isMinimumMatch: employeeDot,
      },
      ...editableConfig,
    }

    return event
  }

  const getEvents = () => {
    const calendarApi = calendarComponentRef &&calendarComponentRef.current && calendarComponentRef.current.getApi()
    const allEvents = calendarApi && calendarApi.getEvents()
    return allEvents
  }

  const eventDrop = eventDropInfo => {
    eventDropInfoFunc = eventDropInfo
    // called after drag stops
    const newFullcalendarEvent = eventDropInfo.event
    const oldFullcalendarEvent = eventDropInfo.oldEvent
    const shift = mapEventToShift(newFullcalendarEvent)
    const oldShift = mapEventToShift(oldFullcalendarEvent)
    if(shift && shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire)) editRecurring('eventDrop', shift, oldShift)
    else {
   const deviation_data = shift.deviationThreshold && (shift.deviationThreshold.punch_in_before !== null || shift.deviationThreshold.punch_in_after !== null) ? {punch_in_before: shift.deviationThreshold.punch_in_before || 0, punch_in_after: shift.deviationThreshold.punch_in_after || 0} : null; 
    updateDragShift({
      id: shift.id,
      name: shift.name,
      no_of_employees: shift.expectedEmployees,
      restriction_data: {punch_in: shift.punchInRestrictions || null},
      deviation_data: deviation_data,
      on_going: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) ? true : false,
      repeat: {weekdays: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) && shift.reoccuring.days},
      from: shift.from,
      to: shift.to ? shift.to : moment(shift.from).add(1, 'days'),
      end_date: shift.reoccuring && shift.reoccuring.endsOn && moment(shift.end_date).format('YYYY-MM-DD'),
      timefence_end_time: shift.from,
      timefence_start_time:  moment(shift.from).subtract(30, 'minutes').toDate(),
      drag: true
    })
    }
  }

  if(revertFunc) {
    eventDropInfoFunc.revert()
    setRevert(false)
  }

  const eventResize = eventResizeInfo => {
    // NOTE: this method is called after user stops resizing event
    eventDropInfoFunc = eventResizeInfo
    const newFullcalendarEvent = eventResizeInfo.event
    const oldFullcalendarEvent = eventResizeInfo.prevEvent
    const shift = mapEventToShift(newFullcalendarEvent)
    const oldShift = mapEventToShift(oldFullcalendarEvent)
    if(shift && shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire)) editRecurring('resize', shift, oldShift)
    else {
      const deviation_data = shift.deviationThreshold && (shift.deviationThreshold.punch_in_before !== null || shift.deviationThreshold.punch_in_after !== null) ? {punch_in_before: shift.deviationThreshold.punch_in_before || 0, punch_in_after: shift.deviationThreshold.punch_in_after || 0} : null; 
      updateDragShift({
        id: shift.id,
        name: shift.name,
        no_of_employees: shift.expectedEmployees,
        restriction_data: {punch_in: shift.punchInRestrictions || null},
        deviation_data: deviation_data,
        on_going: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) ? true : false,
        repeat: {weekdays: shift.reoccuring && (shift.reoccuring.endsOn || shift.reoccuring.neverExpire) && shift.reoccuring.days},
        from: shift.from,
        to: shift.to,
        end_date: shift.reoccuring && shift.reoccuring.endsOn && moment(shift.end_date).format('YYYY-MM-DD'),
        timefence_end_time: shift.from,
        timefence_start_time:  moment(shift.from).subtract(30, 'minutes').toDate(),
        drag: false
      })
    }
  }

  const mapNamesToPlugins = names => {
    return names.map(name => {
      switch (name) {
        case 'dayGridPlugin':
          return dayGridPlugin
        case 'timeGridPlugin':
          return timeGridPlugin
        case 'interactionPlugin':
          return interactionPlugin
        case 'momentPlugin':
          return momentPlugin
        default:
          break
      }
    })
  }

  fullcalendarConfig.plugins = mapNamesToPlugins(fullcalendarConfig.plugins)
  return (
    <FullCalendar
      events={calendarEvents}
      eventDrop={eventDrop}
      eventResize={eventResize}
      ref={calendarComponentRef}
      {...fullcalendarConfig}
      buttonText ={{ today: 'Jump to Today'}}
      allDayText = 'All-day'
      eventTimeFormat= {{
        hour: '2-digit',
        minute: '2-digit',
        meridiem: 'lowercase'
      }}
      slotLabelFormat={{
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute:true,
        meridiem: 'lowercase'
      }}
      views= {{
        dayGridMonth: { 
          titleFormat: "MMMM, YYYY"
        },
        timeGridWeek: {
          titleFormat: date_format
        },
        timeGridDay: {
          titleFormat: date_format
        }
      }}
      // TODO: remove props below this comment nowIndicator after default props is fixed
      nowIndicator={true}
      height="auto"
      eventLimit={2}
    />
  )
}

// FIXME: default props not working as expected
Calendar.defaultProps = {
  fullcalendarConfig: {
    defaultView: 'timeGridDay',
    header: {left: 'prev', center: 'title', right: 'next'},
    nowIndicator: true,
    plugins: ['dayGridPlugin', 'timeGridPlugin', 'interactionPlugin', 'momentPlugin'],
    rendererDelay: 10,
    eventDurationEditable: false,
    editable: false,
    dropable: false,
    eventStartEditable: false,
    eventLimit: 2,
  },
}

Calendar.propTypes = {
  shifts: PropTypes.arrayOf(PropTypes.object),
  setShifts: PropTypes.func,
  employees: PropTypes.arrayOf(PropTypes.object),
  mapEventToShift: PropTypes.func,
  fullcalendarConfig: PropTypes.shape({
    defaultView: PropTypes.oneOf([
      'dayGridMonth',
      'timeGridWeek',
      'timeGridDay',
      'listWeek',
      'dayGridWeek',
    ]),
    header: PropTypes.object,
    nowIndicator: PropTypes.bool,
    plugins: PropTypes.arrayOf(PropTypes.string),
    rendererDelay: PropTypes.number,
    eventDurationEditable: PropTypes.bool,
    editable: PropTypes.bool,
    dropable: PropTypes.bool,
    eventStartEditable: PropTypes.bool,
    eventLimit: PropTypes.number,
    eventClick: PropTypes.func,
    eventReceive: PropTypes.func,
    dateClick: PropTypes.func,
    eventRender: PropTypes.func,
    date_format: PropTypes.string,
  }),
}
export default withTheme(Calendar)
