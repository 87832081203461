// lib imports
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Box} from 'grommet'

// CORE imports
import Alert from 'granite/components/Alert'
import * as UserDucks from 'accounts/ducks/user'

// Application imports
import ALERT_MESSAGES from 'messages/alerts'

function initAlerts(setAlerts, selectedOrganisation) {
  if (selectedOrganisation) {
    const alerts = []
    if (selectedOrganisation.is_email_verified) {
      alerts.push({
        message: ALERT_MESSAGES.EMAIL_NOT_VERIFIED,
        showCloseBtn: false,
        link: '/verify-email',
      })
    }
    if (selectedOrganisation.is_phone_verified) {
      alerts.push({
        message: ALERT_MESSAGES.PHONE_NOT_VERIFIED,
        showCloseBtn: false,
        link: '/verify-phone',
      })
    }
    setAlerts(alerts)
  }
}

const Alerts = ({selectedOrganisation, userProfile}) => {
  const [alerts, setAlerts] = useState([])
  useEffect(() => initAlerts(setAlerts, selectedOrganisation), [
    selectedOrganisation,
  ])

  const handleClose = alertId =>
    setAlerts(alerts.filter(alert => alert.id !== alertId))

  if (alerts.length === 0) return null
  return (
    <Box gap="small" pad={{horizontal: 'large', vertical: 'medium'}}>
      {alerts.map(alert => (
        <Alert key={alert.message} {...alert} onClose={handleClose} />
      ))}
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  selectedOrganisation: UserDucks.selectedOrganisation(state),
})

export default connect(mapStateToProps)(Alerts)
