import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CheckBox, Select, Text , ThemeContext } from 'grommet';
import { FormClose } from 'grommet-icons';
import _ from 'lodash';
import styled from 'styled-components';

const maxSelectedOptionsToRender = 5;

const MultiSelectDropDown = ({
  labelKey,
  onChange: userOnChange,
  defaultSelectedOptions,
  options,
  placeholder,
  value,
  onBlur,
  dropheight,
  isAssignButtonLoading,
  shiftData,
  ...rest
}) => {
  let areOptionsString = true;
  if (options[0]) {
    areOptionsString = typeof options[0] === 'string';
  }

  const selectedOptionRef = useRef(null);

  useEffect(() => {
    selectedOptionRef.current.onclick = onBlur;
  }, [onBlur]);
  const [optionsList, setOptions] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOptions);

  useEffect(() => {
    if (!_.isEqual(options, optionsList)) setOptions(options);
    const filteredSelected = selectedOptions.filter((elem) => options.find(({ id }) => elem.id === id && elem.id !== 0));
    setSelectedOptions(filteredSelected);
  }, [options]);

  useEffect(() => {
    if (
      (defaultSelectedOptions.length && !selectedOptions.length) ||
      !_.isEqual(_.sortBy(selectedOptions, 'pk'), _.sortBy(defaultSelectedOptions, 'pk'))
    )
      setSelectedOptions(defaultSelectedOptions);
  }, [defaultSelectedOptions]);

  const clearSelectedOptions = () => {
    userOnChange([]);
    setSelectedOptions([]);
    setOptions(options);
  };

  //to clear the selection after submission
  useEffect(() => {
    clearSelectedOptions();
  }, [isAssignButtonLoading]);

  const dropText = {
    select: {
      options: {
        text:{
         wordBreak: 'break-all',    
        },
      },
      step: '5',
    }
  }

  const renderOption = option => {
    return (
      <Box direction="row" align="center" pad="0.7rem" flex={false}>
        <CheckBox
          checked={          
            selectedOptions.find(selectedOpt => selectedOpt['id'] === option['id']) ? true : false
          }
          label={areOptionsString ? <Text size="small" style={{maxWidth: '110px'}} truncate>{option}</Text> : <Text size="small" style={{maxWidth: '110px'}} truncate>{option[labelKey]}</Text>}
          /*onChange={() => {}}*/
        />
      </Box>
    );
  };


  const renderSelectedOptions = () => {
    return (
      <Box direction="row" gap="xsmall" pad={{ left: 'small', vertical: '9px' }} align="center" flex>
        <Box
          background="brand"
          round="medium"
          align="center"
          justify="center"
          pad={{ horizontal: 'xsmall' }}
          style={{ minWidth: '21px' }}
        >
          {selectedOptions && selectedOptions.find(i=>i.full_name == 'Select All') ? <Text size="small">{selectedOptions.length-1}</Text> : <Text size="small">{selectedOptions.length}</Text>}
        </Box>
        <Box flex>
          <Text style={{ lineHeight: 'normal' }} truncate>
            {areOptionsString
              ? selectedOptions
                  .slice(0, maxSelectedOptionsToRender)
                  .map(opt => opt)
                  .join(', ')
              : selectedOptions
                  .slice(0, maxSelectedOptionsToRender)
                  .map(opt => opt[labelKey])
                  .join(', ')}
          </Text>
        </Box>
        <Button
          href="#"
          onFocus={event => event.stopPropagation()}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            clearSelectedOptions();
          }}
        >
          <Box background="gray" round="full">
            <FormClose style={{ width: '12px', height: '12px' }} />
          </Box>
        </Button>
      </Box>
    );
  };

  const onOptionChange = ({option}) =>{    
    let newSelectedOptions = [...selectedOptions];
    let filteredOptionList = optionsList.filter(emp => shiftData.employee_ids.indexOf(emp.id)==-1,)

    const seasonIndex = newSelectedOptions
      .map(opt => ( opt['id']))
      .indexOf(option['id']);

    const selectedOptionValues = areOptionsString
      ? newSelectedOptions.map(opt => opt)
      : newSelectedOptions.map(opt => opt[labelKey]);

    if(option[labelKey]=="Select All" && seasonIndex < 0){
      newSelectedOptions = filteredOptionList;
    } else if(option[labelKey] == 'Select All' && seasonIndex >=0){
      newSelectedOptions.splice(0, newSelectedOptions.length);
    }else if(newSelectedOptions.find(i=>i.id==option.id)){
      newSelectedOptions.splice(seasonIndex, 1);
    }
    else if(!newSelectedOptions.find(i=>i.id==option.id)){
      newSelectedOptions.push(option);
    }

    if(filteredOptionList.length == newSelectedOptions.length+1 && seasonIndex <0){
      newSelectedOptions = filteredOptionList;
    } else if(newSelectedOptions.length-1 == 0 && seasonIndex >=0){
      newSelectedOptions.splice(0, filteredOptionList.length);
    }else if(newSelectedOptions.length < filteredOptionList.length && filteredOptionList.find(i=>i.full_name == 'Select All')){
      newSelectedOptions = newSelectedOptions.filter(i=>i.id !== null)
    }

    setSelectedOptions(newSelectedOptions);

    setOptions(options);

    userOnChange(newSelectedOptions);
  }

  return (
     <ThemeContext.Extend value={dropText}>
      <Select
        ref={selectedOptionRef}
        closeOnChange={false}
        emptySearchMessage="No record found"
        multiple
        onChange={onOptionChange}
        onSearch={query => {
          setTimeout(() => {
            setOptions(
              options.filter(opt =>
                areOptionsString
                  ? opt.toLowerCase().indexOf(query.toLowerCase()) >= 0
                  : opt[labelKey].toLowerCase().indexOf(query.toLowerCase()) >= 0,
              ),
            );
          }, 500);
        }}
        options={optionsList.filter(emp => shiftData.employee_ids.indexOf(emp.id)==-1,)}
        placeholder={placeholder}
        searchPlaceholder="Search"
        selected={[]}
        value={selectedOptions.length ? renderSelectedOptions() : []}
        {...rest}
      >
        {renderOption}
      </Select>
    </ThemeContext.Extend>
  );
};

MultiSelectDropDown.defaultProps = {
  labelKey: '',
  options: [],
  defaultSelectedOptions: [],
  onChange: () => {},
  placeholder: 'Multi Select',
  onBlur: () => {},
};

MultiSelectDropDown.propTypes = {
  labelKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  defaultSelectedOptions: PropTypes.array,
  placeholder: PropTypes.string,
};

export default MultiSelectDropDown;
