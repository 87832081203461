// Lib Imports
import React from 'react'
import {Box, Heading} from 'grommet'

// Core Imports
import Breadcrumb from 'granite/components/Breadcrumb'
import generateBreadcrumbPath from 'granite/components/Breadcrumb/utils/path'

// Application Imports
import EMPLOYEE_EDIT_MESSAGES from 'messages/employeeEdit'

const EmployeeEditPage = ({location}) => {
  const path = generateBreadcrumbPath(location.pathname)
  return (
    <Box pad={{horizontal: 'large', vertical: 'small'}}>
      <Breadcrumb margin={{bottom: 'large'}} path={path} />
      <Heading level={2}>{EMPLOYEE_EDIT_MESSAGES.HEADING}</Heading>
    </Box>
  )
}

export default EmployeeEditPage
