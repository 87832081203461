import React from 'react';
import { Box, Tip, Text } from 'grommet';
import { CircleInformation } from 'grommet-icons';
import styled, { withTheme } from 'styled-components';
import EditIconAtom from 'atoms/EditIcon';
import ViewIconAtom from 'atoms/ViewIcon';
import DeleteIconAtom from 'atoms/DeleteIcon';

const StyledCircleInformation = styled(CircleInformation)`
  height: 18px;
  width: 18px;
`;

const ToolTip = ({ tipMessage, type, height, width, background = 'black', align = 'right' }) => {
  return (
    <Box alignSelf='center'>
      <Tip
        plain
        content={
          <Box
            pad="small"
            gap="small"
            width={{ max: 'medium' }}
            round="small"
            background={background}
            responsive={false}
          >
            <Text color="white" size="small">
              {tipMessage}
            </Text>
          </Box>
        }
        dropProps={{ align: { left: 'right' } }}
      >
        {type === 'Edit' ? (
          <EditIconAtom height={height} width={width} />
        ) : type === 'View' ? (
          <ViewIconAtom height={height} width={width} />
        ) : type === 'Delete' ? (
          <DeleteIconAtom height={height} width={width} />
        ) : type === 'Info' ? (
          <StyledCircleInformation color="#09819a" />
        ) : null}
      </Tip>
    </Box>
  );
};

export default withTheme(ToolTip);
