import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {
  Box,
  Button,
  CheckBox,
  DropButton,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet'
import {Configure, FormDown, FormUp} from 'grommet-icons'
import {useDataGridContext} from 'granite/components/DataGrid/DataGridTable'

const HoverableConfigure = styled(Configure)`
  cursor: pointer;
`

const StyledTableRow = styled(TableRow)`
  background-color: ${props => {
    return props.theme.global.colors[props.background]
  }};
`

function DataGridHeader({
  hideCheckbox,
  onColumnChange,
  onSortChange,
  sortBy,
  ...rest
}) {
  const {
    columns,
    data,
    hasFirstColumn,
    selectedIds,
    toggleSelectedAll,
  } = useDataGridContext()

  const [isSelected, setIsSelected] = useState(false)
  const [checked, setChecked] = useState(columns.map(column => column.label))

  useEffect(() => {
    if (data.length === selectedIds.length) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }, [selectedIds])

  useEffect(() => {
    if (onColumnChange) onColumnChange(checked)
  }, [checked])

  const onHeaderSelect = ev => {
    setIsSelected(ev.target.checked)
    toggleSelectedAll(ev.target.checked)
  }

  const onCheck = (event, colLabel) => {
    if (event.target.checked) {
      setChecked([...checked, colLabel])
    } else {
      setChecked(checked.filter(checkLabel => checkLabel !== colLabel))
    }
  }

  const onCheckAll = event => {
    if (event.target.checked) {
      setChecked(columns.map(column => column.label))
    } else {
      setChecked([])
    }
  }

  return (
    <TableHeader>
      <StyledTableRow border="bottom" {...rest}>
        {hasFirstColumn ? (
          <TableCell scope="col">
            {!hideCheckbox && (
              <CheckBox checked={isSelected} onChange={onHeaderSelect} />
            )}
          </TableCell>
        ) : null}
        {columns.map(col => {
          if (!col.visible) return
          return (
            <TableCell scope="col" key={`header-${col.property}`}>
              <Box align="center" direction="row">
                <Text>{col.label}</Text>
                {col.isSortable ? (
                  <Box>
                    <Button
                      as="span"
                      onClick={() =>
                        onSortChange && onSortChange(col.property, 'asc')
                      }
                    >
                      <FormDown color={col.sort === 'asc' ? 'brand' : null} />
                    </Button>
                    <Button
                      as="span"
                      onClick={() =>
                        onSortChange && onSortChange(col.property, 'des')
                      }
                    >
                      <FormUp color={col.sort === 'des' ? 'brand' : null} />
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </TableCell>
          )
        })}
        {onColumnChange && (
          <TableCell scope="col">
            <DropButton
              label={<HoverableConfigure />}
              plain
              dropAlign={{top: 'bottom', right: 'right'}}
              dropContent={
                <Box gap="small" pad="small">
                  <CheckBox
                    checked={checked.length === 3}
                    indeterminate={checked.length > 0 && checked.length < 3}
                    label="All"
                    onChange={onCheckAll}
                  />
                  {columns.map(col => (
                    <CheckBox
                      key={col.label}
                      checked={checked.indexOf(col.label) !== -1}
                      label={col.label}
                      onChange={e => onCheck(e, col.label)}
                    />
                  ))}
                </Box>
              }
            />
          </TableCell>
        )}
      </StyledTableRow>
    </TableHeader>
  )
}
export default DataGridHeader
