import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import SidemenuEntity from 'accounts/entities/sidemenu'

/**
 *  GetSidemenuItems api call implementation.
 *
 * @returns {object} containing a array: result, count
 * @throws {GraniteError} if xhr request fails
 */
async function getPermissionsAndSideMenuItems() {
  const payload = {
    sort_by: 'sequence',
    sorting: 'ASC',
  }
  try {
    const response = await GraniteAPIClient().get('/t2b/access/', payload)
    // const dashboardMock = {
    //   id: 49,
    //   isActive: true,
    //   children: [],
    //   label: 'Dashboard',
    //   name: 'dashboard',
    //   isExternalLink: true,
    //   link: 'https://admin-test.myetimecard.com/dashboard',
    // }
    const schedulingMock = {
      id: 100,
      isActive: true,
      children: [{
        children: [],
        id: 50,
        label: "Scheduling",
        name: "scheduling",
        sequence: 0
      }],
      label: 'Premium Features',
      name: 'premium',
    }
    // const sidemenuItems = [dashboardMock, schedulingMock].map(
    //   item => new SidemenuEntity(item),
    // )
    const addExternalLink = item => {
      return {
        ...item,
        isExternalLink: true,
        link: process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL,
        children: item.children.map(i => addExternalLink(i)),
      }
    }
    const sidemenuItems = [
      ...response.data.sidemenu.map(addExternalLink),
      schedulingMock,
    ].map(item => new SidemenuEntity(item))

    // TODO: create permissions entity
    const permissions = response.data.permissions
    return {sidemenuItems, permissions}
  } catch (error) {
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

export {getPermissionsAndSideMenuItems}
