import React from 'react'
import {Box, Text} from 'grommet'
import {Link} from 'react-router-dom'

//CORE imports
import MenuButton from 'granite/components/MenuButton'
import Avatar from 'granite/components/Avatar'
import DropMenu from 'granite/components/DropMenu'

//application imports
import {removeAuthToken} from 'granite/controllers/dashboardHeader'
import Cookies from 'universal-cookie';

const hoverIndicatorOptions = {
  color: '#9cdad7',
}

const cookie = new Cookies();

const ProfileSection = ({history, isCollapsed, user,organisations}) => {
  const renderItems = history => {
    const signOut = () => {
      cookie.remove('lastActivityTime', {path: '/'})
      removeAuthToken()
      history.push('/')
    }
    
    return (
      <Box fill="vertical" background='white'>
        <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label={`${user.firstName} \n ${user.lastName}`}
            textColor="dark-1"
            textTransform='uppercase'
            style={{background: '#9cdad7'}}
          />
        <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/accounts/profile`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="Edit My Profile"
            textColor="dark-1"
          />
        </a>
        <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/accounts/change-password`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="Change My Password"
            textColor="dark-1"
          />
        </a> 
         <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label={organisations.name}
            textColor="dark-1"
            textTransform='uppercase'
            style={{background: '#9cdad7'}}
          />
        <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/vendor/plan`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="Billing"
            textColor="dark-1"
          />
        </a> 
        <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/vendor/profile`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="Edit Company Profile"
            textColor="dark-1"
          />
        </a> 
         <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/payroll/setting/list`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="Payroll Settings"
            textColor="dark-1"
          />
        </a>
         <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/vendor/basic-setting`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="System Settings"
            textColor="dark-1"
          />
        </a>
         <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/vendor/role-setting`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="Organizational Settings"
            textColor="dark-1"
          />
        </a>
        <a href={`${process.env.REACT_APP_METC_EXISTING_DASHBOARD_BASE_URL}/vendor/plugin`}>
          <MenuButton
            hoverIndicatorOptions={hoverIndicatorOptions}
            label="Plugin Settings"
            textColor="dark-1"
          />
        </a>
        <MenuButton
          hoverIndicatorOptions={hoverIndicatorOptions}
          label="Log out"
          onClick={() => signOut()}
          textColor="dark-1"
        />
      </Box>
    )
  }
  return (
  <Box background="#001e2e" justify="start" height={{'min':'66px'}} style={{'whiteSpace' : 'pre-line'}}>
    <DropMenu
      label={<Box>
              <Text truncate>{user.email}</Text>
              <Text truncate>{organisations.name}</Text>
            </Box>}
      icon={
        <Avatar width="56px" height="56px" round="full">
          <Text color="white">{user.email.charAt(0).toUpperCase()}</Text>
        </Avatar>
      }
      renderItems={() => renderItems(history)}
      hoverIndicatorOptions={{color: '#4cbcb7'}}
      isCollapsed={isCollapsed}
    />
  </Box>
  )
}

export default ProfileSection
