import Cookies from 'universal-cookie';
const cookies = new Cookies();
let auth = {};

function loadAuthToken() {
  const authToken = cookies.get('adminAuthToken');
  return authToken
}

function setAuthToken(authToken) {
  let domain = window.location.hostname;
  cookies.set('adminAuthToken', authToken, { path: '/', domain });
}

function getAuthToken() {
  const token = cookies.get('adminAuthToken');
  if (auth.token) {
    return auth.token;
  } else if (token) {
    return token;
  } else {
    return null;
  }
}

function getCompany() {
  const company_id = cookies.get('company_id');
  return company_id;
}

function resetAuthToken() {
  localStorage.clear();
  let domain = window.location.hostname;
  const host = window.location.hostname.split('.');
  if (host[0].indexOf('apps') >= 0) domain = `.${host[1]}.${host[2]}`;
  cookies.remove('Token', { path: '/', domain });
  cookies.remove('oAuthToken', { path: '/', domain });
  cookies.remove('company_id', { path: '/', domain });
  cookies.remove('shiftAuthToken', { path: '/', domain });
  cookies.remove('authToken', { path: '/' });
  cookies.remove('viewType', { path: '/' });
  auth.token = null;
  auth.company = null;
}

function getPageSizeAndNo(type) {
  if (type.indexOf('pageSize') > -1) {
    return cookies.get(type) ? parseInt(cookies.get(type)) : 20;
  } else if (type.indexOf('pageNo') > -1) {
    return cookies.get(type) ? parseInt(cookies.get(type))+1 : 1;
  }
}

function setCookies(type,value) {
    cookies.set(type,value,{ path: '/' })

}
export { loadAuthToken, setAuthToken, getAuthToken, resetAuthToken, getCompany, getPageSizeAndNo, setCookies};
