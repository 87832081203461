import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Text, TextInput } from 'grommet';
import { FormNext, FormPrevious } from 'grommet-icons';
import moment from 'moment';
import './index.css'

const DateHoursTable = ({ startDate, endDate, setRequestedHours, mode, data,setFieldValue }) => {
  const [curPage, setCurPage] = useState(0);
  const [dateArray, setDateArray] = useState([]);
  const [previous, setPrevious] = useState(0);
  const [next, setNext] = useState(7);
  const [lastIndex, setLastIndex] = useState(true);
  const [paginationHeader, setPaginationHeader] = useState(['No date range selected']);
  const handleInputChange = (value, key) => {
    let newArray = [...dateArray];
    const index = dateArray.findIndex(element => element.datetime === key.datetime);
    newArray[index] = { datetime: key.datetime, hours: value };
    setDateArray(newArray);
    setFieldValue('details',newArray)
    calculateRequestedHours(index, value);
  };

  const calculateRequestedHours = (index, value) => {
    let count = 0;
    dateArray.forEach((item, index1) => {
      index1 !== index
        ? (count += parseInt(item.hours))
        : value === ''
        ? (count += parseInt(0))
        : (count += parseInt(value));
    });
    setRequestedHours(count);
  };

  const setInitialData = useCallback(() => {
    setCurPage(0);
    setNext(7);
    setPrevious(0);
    setPaginationHeader(['No date range selected']);
    setDateArray([]);
    setLastIndex(true);
  }, [setDateArray]);

  const getDates = useCallback(() => {
    setInitialData();
    if (!(startDate && endDate)) {
      return;
    }
    let dateArr = [];
    let currentDate = moment(startDate);
    let endDateObject = moment(endDate);
    let count = 0;
    let header = '';
    let arr = [];

    while (currentDate <= endDateObject) {
      if (count === 0) {
        header = moment(currentDate).format('ddd, MMM Do') + ' - ';
      }
      if (count === 6) {
        header += moment(currentDate).format('ddd, MMM Do');
        arr.push(header);
        header = '';
        count = -1;
      }
      dateArr.push({
        datetime: moment(currentDate).format('YYYY-MM-DD'),
        hours: 0,
      });
      currentDate = moment(currentDate).add('days', 1);
      count++;
    }

    if (dateArr.length % 7 !== 0) {
      currentDate = moment(currentDate).subtract('days', 1);
      header += moment(currentDate).format('ddd, MMM Do');
      arr.push(header);
    }
    setPaginationHeader(arr);
    if (mode === 'edit') {
      setDateArray(data?.request_details ?? []);
      setFieldValue('details',data?.request_details ?? [])
    } else {
      setDateArray(dateArr);
      setFieldValue('details',dateArr)
    }
  }, [startDate, endDate,data,mode,setInitialData,setFieldValue]);

  const previousButton = () => {
    setLastIndex(true);
    if (curPage > 0) {
      setCurPage(curPage - 1);
    }
    if (previous - 7 >= 0) {
      setNext(next - 7);
      setPrevious(previous - 7);
    } else {
      setNext(7);
      setPrevious(0);
    }
  };

  useEffect(() => {
    getDates();
  }, [startDate, endDate,getDates]);

  const nextButton = () => {
    if (curPage < paginationHeader.length - 1) {
      setCurPage(curPage + 1);
    }

    if (next + 7 < dateArray.length) {
      setNext(next + 7);
      setPrevious(previous + 7);
    } else if (lastIndex && paginationHeader.length > 1) {
      setLastIndex(false);
      setNext(next + 7);
      setPrevious(previous + 7);
    }
  };
  return (
    <Box>
      <Box justify="between" width="full" direction="row" gap="medium" align="center">
        <Button
          onClick={previousButton}
          primary
          color="accent-1"
          align="center"
          disabled={previous === 0 ? true : false}
          className='circle-button'
        >
          <FormPrevious color="white"  size='medium'/>
        </Button>
        <Text>{paginationHeader[curPage]}</Text>
        <Button
          onClick={nextButton}
          primary
          color="accent-1"
          align="center"
          disabled={(next === 7 && paginationHeader.length < 2) || !lastIndex ? true : false}
          className='circle-button'
        >
          <FormNext color="white"/>
        </Button>
      </Box>
      {dateArray.slice(previous, next).map((item, index) => {
        return (
          <Box key={index} direction="row" width="full" display="flex" justify="center" gap="small">
            <Box width="40%">
              <Text>{moment(item.datetime).format('ddd, MMM Do')}</Text>
            </Box>
            <Box pad={{ bottom: 'small' }}>
              <TextInput
                style={{
                  width: '60px',
                  height: '30px',
                }}
                type="number"
                min={0}
                value={item.hours}
                onChange={event => handleInputChange(event.target.value, item)}
                max={24}
              />
            </Box>
            <Text> hours </Text>
          </Box>
        );
      })}
    </Box>
  );
};
export default DateHoursTable;
