// Lib Imports
import React from 'react'
import {Anchor, Box, TextInput, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'

// CORE Imports
import FormField from 'granite/components/FormField'

const RequestTokenForm = ({onSubmit}) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .email('Invalid email')
      .required('Email is required'),
  })

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="email"
            label="Email address or Phone number"
            required
            error={touched.email && errors.email}
          >
            <TextInput
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
          </FormField>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            justify="between"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{vertical: 'small'}}
              label={isSubmitting ? 'Sending OTP...' : 'Next'}
              disabled={isSubmitting}
              primary
            />
            <Link to="/login">
              <Anchor
                as="span"
                label="Log in to existing account"
                size="small"
                margin={{vertical: 'small'}}
              />
            </Link>
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default RequestTokenForm
