import React from 'react'
import styled, {keyframes, withTheme} from 'styled-components'
import spin from 'granite/components/Animations/spin'

const spinAnimation = keyframes`${spin}`

const InnerDiv = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: ${spinAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${props =>
    props.theme.global.colors.brand +
    ` transparent transparent transparent`};
`
const OuterDiv = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`

const FirstDiv = styled(InnerDiv)`
  animation-delay: -0.45s;
`
const SecondDiv = styled(InnerDiv)`
  animation-delay: -0.3s;
`
const ThirdDiv = styled(InnerDiv)`
  animation-delay: -0.15s;
`

const Spinner = ({...rest}) => {
  return (
    <OuterDiv>
      <FirstDiv {...rest} />
      <SecondDiv {...rest} />
      <ThirdDiv {...rest} />
      <InnerDiv {...rest} />
    </OuterDiv>
  )
}

export default withTheme(Spinner)
