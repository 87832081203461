function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function generateBreadcrumbPath(pathname) {
  // discard first two elements. One is empty string and other is "dashboard"
  let splitPath = pathname.split('/').slice(1)
  // console.log(splitPath)
  if ((splitPath[0] === 'dashboard')) splitPath = splitPath.slice(1)

  return splitPath.map(path => {
    return {
      label: capitalizeFirstLetter(path),
      path: '/' + path,
    }
  })
}

export default generateBreadcrumbPath
