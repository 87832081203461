import {Subject} from 'rxjs'

export default class EventEmitter {
  constructor() {
    this.observable = new Subject()
  }

  getObservable() {
    return this.observable
  }

  emit(eventType, data) {
    this.observable.next({
      type: eventType,
      data,
    })
  }
}
