// Core imports
import GraniteError from 'utils/granite-error';
import GraniteAPIClient from 'utils/granite-client';

// Application imports
import Employees from 'employee/entities/employee';

/**
 *  department api call implementation.
 *
 *
 * @returns {object} department.
 * @throws {GraniteError} api failed.
 */

async function fetchEmployees(params) {
  try {
    const { data } = await GraniteAPIClient().get('/employee/', {
      params,
    });
    const employees = data && data.length ? data : data.results;
    // TODO: update it while switching to granite api
    return { employees };
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

async function fetchEmployeesData(params) {
  try {
    const response = await GraniteAPIClient().get('/employee/', { params });
    const employees = response.data.map(items => new Employees(items));
    return { employees };
    // TODO: update it while switching to granite api
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    throw new GraniteError(errorMessage);
  }
}

export default {
  fetchEmployees,
  fetchEmployeesData,
};
