import React, {useEffect, useState} from 'react'
import {Box, Button, CheckBox, Heading, Layer, TextInput, Text, Image, Select} from 'grommet'
import {Close} from 'grommet-icons'
import PropTypes from 'prop-types'
import { Clock } from 'grommet-icons';

// CORE imports
import Divider from 'granite/components/Divider'
import NumberInput from 'granite/components/NumberInput'
import moment from 'moment'
import Toast from 'granite/components/Toast'

// application imports
import ReoccuranceInputs from './ReoccuranceInputs'
import DeviationThresholdInputs from './DeviationThresholdInputs'
import {defaultDays} from 'pages/components/FullCalendar/utils'
import DateTimePicker from 'granite/components/DateTimePicker'
import {TIME_FORMAT, TIME_FORMAT_24_HOURS} from 'pages/constants/timeFormat'
import { DateTimePickerComponent, TimePickerComponent, DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import InfoGreyIcon from './grey-info-icon.png';

import Tooltip from 'granite/components/Tooltip'

const defaultReoccuranceData = {
  isReoccuring: false,
  days: defaultDays,
  endsOn: null,
  neverExpire: false,
}
const defaultDeviationData = {
  isDeviationThreshold: false,
  //punch_in_before: 15,
  //punch_in_after: 15,
  punch_in_before:15,
  punch_in_after:15,
}
const defaultToastData = {visible: false, text: '', background: 'accent-1'}

const ShiftAddEditModal = ({
  messages,
  data,
  setData,
  createShift,
  updateShift,
  editMode = false,
  setShiftId,
  editRecurring,
  editRecurringShiftDetails,
  time_format_24_hr,
  date_format,
  isSubmitting,
  setSubmitShift,
  shiftStartDate, 
  shiftEndDate,
  setShiftStartEndDateNull,
  shiftData,
}) => { 
  let difference
  const {from, to} = data 
  const [startTime, setStartTime] = useState(shiftStartDate ? shiftStartDate : from)
  const [endTime, setEndTime] = useState(shiftEndDate ? shiftEndDate : to )
  const [startDate, setStartDate] = useState(shiftStartDate ? shiftStartDate : from)
  const [endDate, setEndDate] = useState(shiftEndDate ? shiftEndDate : to)
  const [shiftName, setShiftName] = useState('')
  const [reoccuranceData, setReoccuranceData] = useState(defaultReoccuranceData)
  const [deviationData, setDeviationData] = useState(defaultDeviationData)
  const [shiftEstimatedEmp, setShiftEstimatedEmp] = useState('')
  const [toastData, setToastData] = useState(defaultToastData)
  
  let shift_start_date_time= (startDate && moment(startDate).format('YYYY-MM-DD')) + ' ' + (startTime && moment(startTime, ["h:mm A"]).format("HH:mm"))
  
  let new_timefence_start_time=shiftData && shiftData.timefence_start_time && (moment(shiftStartDate).format('YYYY-MM-DD')).concat(" ",(shiftData.timefence_start_time.split(" "))[1])

  let reoccuring_start_time = shiftData && shiftData.timefence_start_time &&
   (moment(new_timefence_start_time).format("hh:mm a"))

  let reoccurring_start_date_time= 
   (shiftStartDate && moment(shiftStartDate).format('YYYY-MM-DD')) + ' ' + reoccuring_start_time;

  let new_timefence_end_time=shiftData && shiftData.timefence_start_time && moment(shiftEndDate).format('YYYY-MM-DD').concat(" ",(shiftData.timefence_end_time.split(" "))[1])

  let reoccuring_end_time = shiftData && shiftData.timefence_end_time &&
   (moment(new_timefence_end_time).format("hh:mm a"))

  let reoccurring_end_date_time= 
   (shiftEndDate && moment(shiftEndDate).format('YYYY-MM-DD')) + ' ' + reoccuring_end_time;

  let initial_timefence_start_date_time = (new Date(shift_start_date_time)).setMinutes((new Date(shift_start_date_time)).getMinutes() - 30);

  const [timeFenceStartDateTime, setTimeFenceStartDateTime] = useState(
    shiftData && shiftData.timefence_start_time && editMode 
    ? 
    shiftData && shiftData.on_going ? new Date(reoccurring_start_date_time) 
    :
    new Date(shiftData.timefence_start_time) 
    :     
    new Date(initial_timefence_start_date_time) )
  
  const [timeFenceEndDateTime, setTimeFenceEndDateTime] = useState(
    shiftData && shiftData.timefence_end_time && editMode 
    ?
    shiftData && shiftData.on_going ? new Date(reoccurring_end_date_time) 
    :
    new Date(shiftData.timefence_end_time)
    :
    new Date(shift_start_date_time))
  
  const [gracePeriod, setGracePeriod] = useState(
    shift_start_date_time && shiftData && shiftData.timefence_end_time && editMode 
    ? 
    shiftData && shiftData.on_going ? 
    (Math.round(( new Date(shift_start_date_time).getTime() - new Date(reoccurring_end_date_time).getTime()) / 60000)) 
    :
    (Math.round(( new Date(shift_start_date_time).getTime() - new Date(shiftData.timefence_end_time).getTime()) / 60000)) :
    (Math.round(( new Date(shift_start_date_time).getTime() - new Date(timeFenceEndDateTime).getTime()) / 60000))
      )
    
  const [isTimefenceDiff, setTimefenceDiff] = useState(gracePeriod > 0 ? true: false)

    let sync_date_format;
    if( date_format == "ddd, DD MMM YYYY"){
      sync_date_format = "E, dd MMM y"
    } else if( date_format == "MM/DD/YYYY"){
      sync_date_format = "MM/dd/y"
    } else if( date_format == "DD/MM/YYYY"){
      sync_date_format = "dd/MM/y"
    } else if( date_format == "YYYY/MM/DD"){
      sync_date_format = "y/MM/dd"
    } else if( date_format == "YYYY/DD/MM"){
      sync_date_format = "y/dd/MM"
    } else if( date_format == "MMMM DD, YYYY"){
      sync_date_format = "MMMM dd, y"
    } else if( date_format == "MMM DD, YYYY"){
      sync_date_format = "MMM dd, y"
    } else {  
      sync_date_format = date_format
    }
    let startTimeObj, endTimeObj;

    const onClickStartTime = () => {
      startTimeObj.show("time")
   }

   const onClickEndTime = () => {
    endTimeObj.show("time")
 }

  useEffect(() => {
    if (Boolean(data) === false || !editMode) {setInitialVal(); return}
    const {
      name,
      reoccuring,
      punchInRestrictions,
      expectedEmployees,
      deviationThreshold,
    } = data
    setShiftName(name)
    setReoccuranceData(parseReoccuranceData(reoccuring))
    setDeviationData(parseDeviationData(deviationThreshold))
    setShiftEstimatedEmp(expectedEmployees)       
  }, [data])

  const closeModal = (type) => {
    setSubmitShift({isSubmitting: false, isEditing: false})
    setData({
      from: null,
      to: null,
    })
    setInitialVal()
    if(type == 'back') {
      setShiftId({shiftId: data.id})
    }
    else{
      setShiftStartEndDateNull()
    }
  }

  const setInitialVal = () => {
    setShiftName('')
    const parsedDays = defaultDays.map(day => {
     day.selected = false
      return day
    })
    setReoccuranceData({endsOn: null, days: parsedDays, isReoccuring: false, neverExpire: false})
    setDeviationData(defaultDeviationData)
    setShiftEstimatedEmp('')
  }
  
  const timefenceEndTime = (e) => {
    setTimeFenceEndDateTime(e.value)
    if(e.value && startDate && startTime){
     difference = new Date(shift_start_date_time).getTime() - e.value.getTime();
     timeDifference() 
    } else {
      setTimefenceDiff(false)      
    }
  }

  const changeStartTime = (e) => {
    setStartTime(e.value)
    if(e.value && timeFenceEndDateTime && startDate){
      const datetime= (startDate && moment(startDate).format('YYYY-MM-DD')) + ' ' + (e.value && moment(e.value, ["h:mm A"]).format("HH:mm"))
      difference = new Date(datetime).getTime() - new Date(timeFenceEndDateTime).getTime();
      timeDifference() 
    } else {
      setTimefenceDiff(false)
    }
  }

  const changeStartDate = (e) => {
    setStartDate(e.value)
    if(e.value && timeFenceEndDateTime && startTime){
      const datetime= e.value && moment(e.value).format('YYYY-MM-DD') + ' ' + (startTime && moment(startTime, ["h:mm A"]).format("HH:mm"))
      difference = new Date(datetime).getTime() - new Date(timeFenceEndDateTime).getTime();
      timeDifference() 
    } else {
      setTimefenceDiff(false)
    }
  }
  
  const timeDifference = () => {
    const resultInMinutes = Math.round(difference / 60000);
    setGracePeriod(resultInMinutes)
    resultInMinutes > 0 ? setTimefenceDiff(true) : setTimefenceDiff(false)
  }
  
  const handleSubmit = () => {
    setSubmitShift({isSubmitting: true})    
    let error = ''
    let today = from.getDay();
    let day_label;
    let startDateandTime = reoccuranceData.isReoccuring ? (from && moment(from).format('YYYY-MM-DD')) + ' ' + (startTime && moment(startTime, ["h:mm A"]).format("HH:mm")) : (startDate && moment(startDate).format('YYYY-MM-DD')) + ' ' + (startTime && moment(startTime, ["h:mm A"]).format("HH:mm"))
    let endDateandTime = reoccuranceData.isReoccuring ? (to && moment(to).format('YYYY-MM-DD'))  + ' ' + (endTime && moment(endTime, ["h:mm A"]).format("HH:mm")) : (endDate && moment(endDate).format('YYYY-MM-DD'))  + ' ' + (endTime && moment(endTime, ["h:mm A"]).format("HH:mm"))
    let currentD = new Date();
    let startD = new Date(startDateandTime);
    let endD = new Date(endDateandTime);
    let reoccuringStartD =  (startDate && moment(startDate).format('YYYY-MM-DD')) + ' ' + (startTime && moment(startTime, ["h:mm A"]).format("HH:mm"))
    
    if((startD < currentD ||  endD < currentD ) && !reoccuranceData.isReoccuring){
      error = "Cannot enter a past shift";
    }  
    if(endD <= startD){
      error = "End date/time should not be less than or equal to start date/time";
    }
    if(!endTime){
      error= "Shift end time is required"
    }
    if(!startTime){
     error= "Shift start time is required"
    }
    if( !timeFenceEndDateTime )
      error= "Timefence end date/time is required"
    if( !timeFenceStartDateTime)
      error= "Timefence start date/time is required"    
    if(timeFenceEndDateTime && timeFenceStartDateTime && new Date(timeFenceEndDateTime) <= new Date(timeFenceStartDateTime)){
      error = "Timefence end date/time should not be less than or equal to timefence start date/time";
    }
    if( (timeFenceStartDateTime && !reoccuranceData.isReoccuring && new Date(timeFenceStartDateTime) > startD)
         || 
         (timeFenceStartDateTime && reoccuranceData.isReoccuring && new Date(timeFenceStartDateTime) > new Date(reoccuringStartD))){
           error = "Timefence start date/time should not be greater than shift start date/time";
          }
          if((timeFenceEndDateTime && !reoccuranceData.isReoccuring && new Date(timeFenceEndDateTime) > startD)
          ||
          timeFenceEndDateTime && reoccuranceData.isReoccuring && new Date(timeFenceEndDateTime) > new Date(reoccuringStartD) ){
            error = "Timefence end date/time should not be greater than shift start date/time";
          }
          if(today==0){
            today=7;
            day_label = defaultDays[6].label;
          }else{
      day_label=defaultDays[today-1].label;
    } 
    if(!shiftName.trim()) {
      error = 'Name of the shift is required'
    }
    else if (reoccuranceData.isReoccuring) {
      if( Boolean(reoccuranceData.endsOn) === false && reoccuranceData?.neverExpire == false) {
        error = 'The end date or never expire of the recurring shift is required'
      } else if(moment(mapReoccuranceData(reoccuranceData).endsOn).format("YYYY-MM-DD") < moment(from).format("YYYY-MM-DD")) {
        error = 'End date can\'t be less than the start date of original shift.'
      } else if(!mapReoccuranceData(reoccuranceData).weekdays.length) {
        error = 'Please select at least one day for recurring shift'
      } else if(!mapReoccuranceData(reoccuranceData).weekdays.includes(today-1)){
        error = `Please select ${day_label} also in shift reoccurence days`
      }
    } 
    
    if(error) {
      setSubmitShift({isSubmitting: false})
      setToastData({
        visible: true,
        text:
        `${error}`,
        background: 'accent-2',
      })
      setTimeout(() => {
        setToastData({visible: false, text: '', background: 'accent-2'})
      }, 3000)
      return
    }
    const deviation_data = deviationData && deviationData.isDeviationThreshold ? {punch_in_before: deviationData.punch_in_before || 0, punch_in_after: deviationData.punch_in_after || 0} : null; 
    const repeatData = reoccuranceData && reoccuranceData.isReoccuring ? mapReoccuranceData(reoccuranceData) : null;
    if (editMode && !repeatData) {
      // NOTE: we need to send id in order to compare to prev shift
        updateShift({
          id: data.id,
          name: shiftName,
          no_of_employees: shiftEstimatedEmp,
          restriction_data: {punch_in: deviationData.isDeviationThreshold ? true : false},
          deviation_data: deviation_data,
          on_going: reoccuranceData.isReoccuring,
          repeat: {weekdays: repeatData && repeatData.weekdays},
          end_date: repeatData && moment(repeatData.endsOn).format('YYYY-MM-DD'),
          startDateTime : startD,
          endDateTime : endD,
          timefence_start_time: new Date(timeFenceStartDateTime),
          timefence_end_time: new Date(timeFenceEndDateTime),
          never_expire: reoccuranceData?.neverExpire 
        })
    } else if(editMode && repeatData){
        editRecurringShiftDetails('edit',{
          id: data.id,
          name: shiftName,
          no_of_employees: shiftEstimatedEmp,
          restriction_data: {punch_in: deviationData.isDeviationThreshold ? true : false},
          deviation_data: deviation_data,
          on_going: reoccuranceData.isReoccuring,
          repeat: {weekdays: repeatData && repeatData.weekdays},
          end_date: repeatData && (Boolean(repeatData.endsOn) ? moment(repeatData.endsOn).format('YYYY-MM-DD') : null),
          startDateTime : startD,
          endDateTime : endD,
          timefence_start_time: new Date(timeFenceStartDateTime),
          timefence_end_time: new Date(timeFenceEndDateTime),
          never_expire: reoccuranceData?.neverExpire
        })
      }
      else{
      createShift({
        name: shiftName,
        end_date: repeatData && (Boolean(repeatData.endsOn) ? moment(repeatData.endsOn).format('YYYY-MM-DD') : null),
        restriction_data: {punch_in: deviationData.isDeviationThreshold ? true : false},
        deviation_data: deviation_data,
        no_of_employees: shiftEstimatedEmp,
        on_going: reoccuranceData.isReoccuring,
        repeat: {weekdays: repeatData && repeatData.weekdays},
        startDateTime : startD,
        endDateTime : endD,
        timefence_start_time: new Date(timeFenceStartDateTime),
        timefence_end_time: new Date(timeFenceEndDateTime),
        never_expire: reoccuranceData.neverExpire,
      })
    }
  }

  const mapReoccuranceData = data => {
    const {days, endsOn} = data
    const mappedDays = []
    days.forEach(day => {
      if (day.selected) mappedDays.push(day.val)
    })
    return {weekdays: mappedDays, endsOn}
  }

  const parseReoccuranceData = data => {
    const {days, endsOn} = data
    const parsedDays = defaultDays.map(day => {
      if (days.includes(day.val)) day.selected = true
      return day
    })
    return {endsOn, days: parsedDays, isReoccuring: Boolean(shiftData.repeat.weekdays), neverExpire: shiftData.extra_data.never_expire}
  }

  const parseDeviationData = data => {
    const {punch_in_before, punch_in_after} = data
    return {
      punch_in_before,
      punch_in_after,
      isDeviationThreshold: Boolean(punch_in_before !== null || punch_in_after !== null),
    }
  }
  return (
      <Layer>
        <Box height={{max: '90vh'}} overflow={{vertical: 'auto'}}>
          <Box
            background="light-1"
            width="40rem"
            round="small"
            height={{min:'auto'}}
          >
            <Box
              direction="row"
              justify="between"
              align="center"
              pad={{top: 'small', bottom: 'xsmall', horizontal: 'medium'}}
              height={{min: 'auto'}}
            >             
              <Box direction="row">              
                  <Button
                    pad="xxsmall"
                    icon={
                      <Image
                        src={process.env.PUBLIC_URL + '/img/left-arrow.png'}
                        alt="icon"
                        height="20"
                        margin={{top:'5px'}}
                      />
                      }
                    onClick={ () => closeModal('back')}
                    />
                  <Heading level={3} color="dark-1" margin={{'bottom':'5px', 'top':'4px !important'}}>
                    {editMode
                      ? <Text size="21px">{messages.EDIT_SHIFT_MODAL_HEADING}</Text>
                      : <Text size="21px">{messages.ADD_SHIFT_MODAL_HEADING}</Text>}
                  </Heading>
              </Box>
              <Button icon={<Close size="small" />} onClick={closeModal} />
            </Box>
            <Divider
              background="accent-1"
              margin={{bottom: 'small'}}
              height="vsmall"
            />
            <Box
              direction="column"
              gap="medium"
              pad={{top: 'small', horizontal: 'large'}}
            >
              <Box direction="row" gap="medium">
                <Box gap="xsmall" height={{min: 'auto'}} basis="50%">
                  <Text color="dark-1" size="small">
                    Shift Name
                  </Text>
                  <TextInput
                    value={shiftName}
                    placeholder={
                      messages.ADD_SHIFT_MODAL_SHIFT_NAME_PLACEHOLDER
                    }
                    onChange={event => setShiftName(event.target.value)}
                  />
                </Box>
                <Box gap="xsmall" height={{min: 'auto'}} basis="50%">
                  <Text color="dark-1" size="small">
                    Minimum No. Of Employees <Tooltip width="63%" content="Shift will be flagged in red if the number of employees Punched In to the shift is less than this number" align="bottom"><img src={InfoGreyIcon} height="14" width="14" alt="info" className="info-icon-align"/></Tooltip>
                  </Text>
                  <NumberInput
                    val={shiftEstimatedEmp}
                    min={0}
                    max={99999999}
                    showButtons
                    //numlength="noofemployees"
                    placeholder={
                      messages.ADD_SHIFT_MODAL_ESTIMATED_EMP_PLACEHOLDER
                    }
                    onChange={(event, val) => {
                      const num = parseInt(val)
                      if (isNaN(num)) setShiftEstimatedEmp('')
                      else setShiftEstimatedEmp(parseInt(num))
                    }}
                  />
                </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box gap="xsmall" height={{min: 'auto'}} basis="50%">                
                   <Text color="dark-1" size="small">
                     Shift Start Date
                   </Text>
                  <DatePickerComponent
                    value={startDate}
                    placeholder="Select start date"
                    format={sync_date_format}
                    allowEdit={false}
                    showTodayButton={false}
                    showClearButton={false}
                    dayHeaderFormat="Narrow"
                    onChange={changeStartDate}
                    openOnFocus={true}
                  />
                </Box>
                <Box gap="xsmall" height={{min: 'auto'}} basis="50%">                   
                   <Text color="dark-1" size="small">
                     Shift Start Time
                   </Text>
                   <TimePickerComponent
                    value={startTime}
                    placeholder="Select start time"
                    format={time_format_24_hr ? `HH:mm`: `hh:mm a`}
                    timeFormat={time_format_24_hr ? "HH:mm" : "hh:mm a"}
                    allowEdit={true}
                    showTodayButton={false}
                    showClearButton={false}
                    step={30}
                    onChange={changeStartTime}
                    openOnFocus={true}
                    strictMode={true}
                  /> 
               </Box>
              </Box>
              <Box direction="row" gap="medium">
                <Box gap="xsmall" height={{min: 'auto'}} basis="50%">
                   <Text color="dark-1" size="small">
                     Shift End Date
                   </Text>
                  <DatePickerComponent
                    value={endDate}
                    placeholder="Select end date"
                    format={sync_date_format}
                    allowEdit={false}
                    showTodayButton={false}
                    showClearButton={false}
                    dayHeaderFormat="Narrow"
                    onChange={e => {setEndDate(e.value)}}
                    openOnFocus={true}
                  />
                </Box>
                <Box gap="xsmall" height={{min: 'auto'}} basis="50%">
                   <Text color="dark-1" size="small">
                     Shift End Time
                   </Text>
                  <TimePickerComponent
                    value={endTime}
                    placeholder="Select end time"
                    format={time_format_24_hr ? `HH:mm`: `hh:mm a`}
                    timeFormat={time_format_24_hr ? "HH:mm" : "hh:mm a"}
                    allowEdit={true}
                    showTodayButton={false}
                    showClearButton={false}
                    step={30}
                    onChange={e => {setEndTime(e.value)}}
                    openOnFocus={true}
                    strictMode={true}
                  />
               </Box>
              </Box>
              <Box direction="row" gap="xsmall" margin={{top: 'small'}}>
                <Text color="dark-1" size="medium">
                   Timefence
                </Text>
              </Box>
              <div className="timefence-border">
              <Box direction="row" gap="medium">
                <Box gap="xsmall" height={{ min: 'auto' }} basis="50%">
                   <Text color="dark-1" size="small">
                      Start Date/Time
                   </Text>
                   <DateTimePickerComponent
                      value={timeFenceStartDateTime}
                      placeholder="Select date and time"
                      format={time_format_24_hr ? `${sync_date_format} HH:mm`: `${sync_date_format} hh:mm a`}
                      timeFormat={time_format_24_hr ? "HH:mm" : "hh:mm a"}
                      allowEdit={true}
                      showTodayButton={false}
                      showClearButton={false}
                      dayHeaderFormat="Narrow"
                      step={10}
                      onChange={e => setTimeFenceStartDateTime(e.value)}
                      onClick={onClickStartTime}
                      ref={time => startTimeObj =time}
                      strictMode={true}
                    />
                </Box>
                <Box gap="xsmall" height={{ min: 'auto' }} basis="50%">
                  <Text color="dark-1" size="small">
                     End Date/Time
                  </Text>
                  <DateTimePickerComponent
                     value={timeFenceEndDateTime}
                     placeholder="Select date and time"
                     format={time_format_24_hr ? `${sync_date_format} HH:mm`: `${sync_date_format} hh:mm a`}
                     timeFormat={time_format_24_hr ? "HH:mm" : "hh:mm a"}
                     allowEdit={true}
                     showTodayButton={false}
                     showClearButton={false}
                     dayHeaderFormat="Narrow"
                     onChange={timefenceEndTime}
                     step={10}
                     onClick={onClickEndTime}
                     ref={time => endTimeObj =time}
                     strictMode={true}
                  />
                </Box>
              </Box>
              { 
              isTimefenceDiff && 
              <Box direction="row" gap="medium" margin={{bottom: 'small', top: 'small'}}>
               <Box gap="xsmall" height={{min: 'auto'}} basis="50%">
                  <Text color="dark-1" size="small" className="graceperiod-text">
                    Grace Period For Early Punch In (Mins)
                  </Text>
                  <NumberInput
                    val={gracePeriod}
                    disabled={true}
                    placeholder="In minutes"
                  />
                </Box>
              </Box>
              }
              </div>
              <ReoccuranceInputs
                data={reoccuranceData}
                setData={setReoccuranceData}
              />
              <DeviationThresholdInputs
                data={deviationData}
                setData={setDeviationData}
                flex={{shrink: 0}}
              />
              {/* TODO: disable button till necessory fields are not present */}
              <Button
                primary
                alignSelf="end"
                color="accent-1"
                size="small"
                style={{width:'30%'}}
                label={isSubmitting ? 'Submitting...' : messages.ADD_SHIFT_MODAL_SHIFT_BTN_LABEL}
                // {messages.ADD_SHIFT_MODAL_SHIFT_BTN_LABEL}
                onClick={handleSubmit}
                disabled={isSubmitting}
                margin={{bottom: 'large'}}
              />
            </Box>
            {toastData.visible && (
            <Toast
              onClose={() =>
                setToastData({visible: false, text: '', background: 'accent-1'})
              }
              text={toastData.text}
              background={toastData.background}
            />
          )}
          </Box>
        </Box>  
      </Layer>
  )
}

ShiftAddEditModal.defaultProps = {
  messages: {},
}

ShiftAddEditModal.propTypes = {
  data: PropTypes.object,
  messages: PropTypes.object,
}

export default ShiftAddEditModal
