import React from 'react'
import {Box, CheckBox, Text} from 'grommet'

// CORE imports
import NumberInput from 'granite/components/NumberInput'
import Divider from 'granite/components/Divider'

const DeviationThresholdInputs = ({data, setData, ...rest}) => {
  const {isDeviationThreshold, punch_in_before, punch_in_after} = data

  return (
    <Box {...rest} height={{min: 'auto'}}>
      <Box direction="row" gap="small" margin={{bottom: 'medium'}}>
        <CheckBox
          checked={isDeviationThreshold}
          onChange={() =>
            setData({
              punch_in_before,
              punch_in_after,
              isDeviationThreshold: !isDeviationThreshold,
            })
          }
        />
        <Text color="dark-1" size="small" alignSelf="center">
          Enable Early/Late Arrival Tracking
        </Text>
      </Box>
      {isDeviationThreshold && (
        <>
          <Box direction="row" gap="small">
            <Box gap="xsmall">
              <Text color="dark-1" size="small">
                Report No Earlier Than (Mins):
              </Text>
              <NumberInput
                onChange={(e, val) => {
                  setData({
                    isDeviationThreshold,
                    punch_in_after,
                    punch_in_before: val,
                  })
                }}
                val={punch_in_before !== null ? punch_in_before : 0}
                min={0}
                max={720}
                showButtons
                placeholder={'Report No Earlier Than (Mins)'}
              />
            </Box>
            <Box gap="xsmall">
              <Text color="dark-1" size="small">
                Report No Later Than (Mins):
              </Text>
              <NumberInput
                showButtons
                onChange={(e, val) =>
                  setData({
                    isDeviationThreshold,
                    punch_in_before,
                    punch_in_after: val,
                  })
                }
                min={0}
                max={720}
                val={punch_in_after !== null ? punch_in_after : 0}
                placeholder={'Report No Later Than (Mins)'}
              />
            </Box>
          </Box>
          <Divider
            background="light-5"
            height="vsmall"
            margin={{vertical: 'medium'}}
          />
        </>
      )}
    </Box>
  )
}

export default DeviationThresholdInputs
