// Lib Imports
import React from 'react'
import {connect} from 'react-redux'
import {Box, Heading} from 'grommet'
import {History, FormEdit, InProgress, User} from 'grommet-icons'

// CORE Imports
import * as OrganisationDucks from 'organisations/ducks/organisations'
import Breadcrumb from 'granite/components/Breadcrumb'
import generateBreadcrumbPath from 'granite/components/Breadcrumb/utils/path'

// Application Imports
import StatCard from './components/StatCard'

function Dashboard({history, location, userOrganisations}) {
  // check if there are any userOrganisations if there is none, redirect to get-started page
  if (userOrganisations.length === 0) {
    history.replace('/get-started')
  }
  const path = generateBreadcrumbPath(location.pathname)

  return (
    <Box pad="large" flex={{grow: 1}}>
      <Breadcrumb margin={{bottom: 'large'}} path={path} />
      <Heading level={2}>Overview</Heading>
      <Box direction="row" wrap>
        <StatCard
          icon={<User />}
          stat="0/75"
          caption="Employees currently working"
        />
        <StatCard
          icon={<History />}
          stat="0"
          caption="Overtime Hours Worked in last 30 days"
        />
        <StatCard
          icon={<FormEdit />}
          stat="0"
          caption="Manual Corrections in the Last 24 Hours"
        />
        <StatCard
          icon={<InProgress />}
          stat="0"
          caption="Hours Worked in last 30 days"
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userOrganisations: OrganisationDucks.organisations(state),
})
export default connect(mapStateToProps)(Dashboard)
