import ChangePassword from 'pages/changePassword'
import Dashboard from 'pages/dashboard'
import ForgetPassword from 'pages/forgotpassword'
import Home from 'pages/home'
import EmployeeList from 'pages/employeeList'
import EmployeeEdit from 'pages/employeeEdit'
import Login from 'pages/login'
import OnBoarding from 'pages/onBoarding'
import ProfilePage from 'pages/profile'
import Register from 'pages/register'
import VerifyAccount from 'pages/verifyAccount'
import ShiftManagement from 'pages/shiftManagement'
import SchedulingDashboard from 'pages/schedulingDashboard'

import TimeOffSettingPage from 'pages/paidTimeOffSettings'
import AddEditPolicyPage from 'pages/addOrEditPtoPolicy'
import TimeOffRequestPage from 'pages/paidTimeOffRequets'
import PtoEditEmployeePage from 'pages/employeePaidTimeOff'
import EmployeePaidTimeOff from 'pages/employeeTimeOffPolicies'
import PaidTimeOffActions from 'pages/ptoRequestHandler'
import TimecardPaidTimeOffRequest from 'pages/ptoTimecard';
import FormPreveiw from './pages/webView'

export default [
  {
    path: '/',
    exact: true,
    component: Home,
    layout: 'PlainLayout',
  },
  {
    path: '/login',
    exact: true,
    publicOnly: true,
    component: Login,
    layout: 'PlainLayout',
  },
  {
    path: '/register',
    exact: true,
    publicOnly: true,
    component: Register,
    layout: 'HeaderLayout',
  },
  {
    path: '/verify-account',
    exact: true,
    // TODO: change it to private
    public: true,
    component: VerifyAccount,
    layout: 'HeaderLayout',
  },
  {
    path: '/dashboard',
    exact: true,
    private: true,
    component: Dashboard,
    layout: 'DashboardLayout',
  },
  {
    path: '/get-started',
    exact: true,
    private: true,
    component: OnBoarding,
    layout: 'HeaderLayout',
  },
  {
    path: '/employee/list',
    exact: true,
    private: true,
    component: EmployeeList,
    layout: 'DashboardLayout',
  },
  {
    path: '/employee/:id/edit',
    private: true,
    component: EmployeeEdit,
    layout: 'DashboardLayout',
  },
  {
    path: '/profile',
    exact: true,
    private: true,
    component: ProfilePage,
    layout: 'DashboardLayout',
  },
  {
    path: '/change-password',
    exact: true,
    private: true,
    component: ChangePassword,
    layout: 'DashboardLayout',
  },
  {
    path: '/forgotpassword',
    exact: true,
    publicOnly: true,
    component: ForgetPassword,
    layout: 'HeaderLayout',
  },
  {
    path: '/shift-management',
    exact: true,
    //private: true,
    component: ShiftManagement,
    layout: 'PlainLayout',
  },
  {
    path: '/scheduling-dashboard',
    exact: true,
    //private: true,
    component: SchedulingDashboard,
    layout: 'PlainLayout',
  },
  {
    path: '/timeoff-setting',
    exact: true,
    component:  TimeOffSettingPage,
   // public:true,
    layout: 'PlainLayout',
  },
  {
    path: '/pto-policy/add',
    exact: true,
    component: AddEditPolicyPage,
    layout: 'PlainLayout',
  },
  {
    path: '/pto-policy/employee-edit',
    exact: true,
    component: PtoEditEmployeePage,
    layout: 'PlainLayout',
  },
  {
    path: '/pto-policy/:id/',
    exact: true,
    component: AddEditPolicyPage,
    layout: 'PlainLayout',
  },
  {
    path: '/timeoff-request',
    exact: true,
    component: TimeOffRequestPage,
    layout: 'PlainLayout',
  },
  {
    path: '/pto-policy/employee-edit/timeOff-policies',
    exact: true,
    component: EmployeePaidTimeOff,
    layout: 'PlainLayout',
  },
  {
    path: '/pto-request/:id',
    exact: true,
    component: PaidTimeOffActions,
    layout: 'PlainLayout',
  },
  {
    path: '/timecard/pto-request',
    exact: true,
    component: TimecardPaidTimeOffRequest,
    layout: 'PlainLayout',
  },
  {
    path: '/form/preview',
    exact: true,
    component: FormPreveiw,
    layout: 'PlainLayout',
  },
]
