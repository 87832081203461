import React from 'react';
import EditIconAtom from 'atoms/EditIcon'
import styles from './styles.module.scss'

import {Box, Button, Text} from 'grommet'
import GenericWindowPostMessage from 'pages/components/GenericWindowPostMessage'

const COLUMNS=[
    {
      Header: 'ACTION',
      width: 80,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box pad={{left: 'small'}}>
            <Button
              onClick={() => {
                GenericWindowPostMessage('OPEN_TIME_OFF_REQUEST_SLIDER', {
                  empName: row.original.employee_name,
                  policyId: row.original.id,
                })
              }}
              title="Edit Time Off Request"
              //disabled={row.original.status !== 'PENDING' ? true : false}
            >
              <EditIconAtom height="15" width="15" />
            </Button>
          </Box>
        )
      },
    },
    {
      Header: 'EMPLOYEE ID',
      accessor: 'employee_id',
      id: 'employee_id',
      sortable: false,
      width: 120,
      resizable: false,
      Cell: row => {
        return (
          <Box justify="center">
            <Text>{row.original.employee_id}</Text>
          </Box>
        )
      },
    },
    {
      Header: 'EMPLOYEE NAME',
      accessor: 'employee_name',
      id: 'employee_name',
      width: 180,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box justify="center">
            <Text size="small" truncate="tip">
              {row.original.employee_name}
            </Text>
          </Box>
        )
      },
    },
    {
      Header: 'EMPLOYEE TITLE',
      accessor: 'employee_title',
      id: 'employee_title',
      width: 140,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text truncate="tip">{row.original.employee_title}</Text>
          </Box>
        )
      },
    },
    {
      Header: 'POLICY',
      accessor: 'policy_name',
      id: 'policy_name',
      width: 140,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box>
            <Text
              truncate="tip"
              tip={{
                plain: true,
                dropProps: {align: {bottom: 'top'}},
                content: (
                  <Box
                    pad="xxsmall"
                    elevation="small"
                    background="#EDEDED" // no opacity
                    round="xsmall"
                    margin="xsmall"
                    overflow="hidden"
                    align="center"
                  >
                    <Text color="#242424" size="small">
                      {row.original.policy_name}
                    </Text>
                  </Box>
                ),
              }}
              className={`${styles['tableColumnText']}`}
            >
              {row.original.policy_name}
            </Text>
          </Box>
        )
      },
    },
    {
      Header: 'TOTAL HOURS',
      accessor: 'total_requested_hours',
      id: 'total_requested_hours',
      width: 100,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box justify="center">
            <Text>{row.original.total_requested_hours}</Text>
          </Box>
        )
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      id: 'status',
      width: 100,
      sortable: false,
      resizable: false,
      Cell: row => {
        return (
          <Box justify="center">
            <Text>{row.original.status}</Text>
          </Box>
        )
      },
    }
  ]


export default COLUMNS