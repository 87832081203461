import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import {Box} from 'grommet'

import Header from './Header'
import Footer from './Footer'
const StyledBox = styled(Box)`
  min-height: 100%;
`

const LinearLayout = ({children}) => {
  const defaultScreenInfo = {
    width: 0,
    height: 0,
    mode: 'desktop',
  }
  const [info, setInfo] = useState(defaultScreenInfo)

  useEffect(function init() {
    updateDimensions()
    window.addEventListener('resize', _.throttle(updateDimensions, 500))

    function updateDimensions() {
      setInfo({
        width: window.innerWidth,
        height: window.innerHeight,
        mode: window.innerWidth > 1087 ? 'desktop' : 'mobile',
      })
    }

    return function cleanUp() {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  return (
    // Box is not acquiring all space of parent
    <StyledBox>
      <Header />
      {children}
      <Footer />
    </StyledBox>
  )
}

export default LinearLayout
