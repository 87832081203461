import React,{useEffect, useState} from 'react'
import {TextInput, Box, Image} from 'grommet'
import CheckMarkIcon from 'pages/components/assets/checkmark.png'

const EditTableCell = ({
  type="number",
  value,
 //data,
  tabIndex,
  onSubmit,
  checkbox,
}) =>{
   const [state,setState] = useState(value)
   const handleSubmit = () =>{
        onSubmit(state);
    }
    useEffect(()=>{
      setState(value)
    },[value])
    
    const keypressHandler = event => {
        if (event.key === 'Enter' || event.key === 'Tab') {
          handleSubmit()
        }
    };

    const handleBlur = () => {
      setState(value);
    }

    return(
      <Box 
        flex
        direction='row' 
        alignSelf='center'
        gap='medium'
      >
        <TextInput
        style={{
          width: '38px',
          height: '30px',
          border: '0px solid white',
        }}
        type={type}
        value={state}
        onChange={(e)=>setState(e.target.value)}
      //  onChange={handleChange}//event => handleBalanceChange(event.target.value, row.original, row.index)}
        tabIndex={tabIndex+1}
        onKeyDown={event => keypressHandler(event)}
        onBlur={()=> handleBlur()}
      />
        {checkbox && (value !== +state) && <Image 
          alignSelf='center'
          height="16px"
          width="16px"
          src={CheckMarkIcon}
          onClick={() => handleSubmit()}
          style={{cursor: 'pointer'}}
          onMouseDown={e => e.preventDefault()}
          title='Submit Balance'
        />}
      </Box>
    )
}

export default EditTableCell