import React from 'react'
import {Route, Redirect} from 'react-router-dom'

import {getAuthToken} from 'utils/auth-singleton'
import LinearLayout from 'granite/components/LinearLayout'

const PublicOnlyRoute = ({component: Component, layout, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authToken = getAuthToken()
        if (authToken) {
          // ONLY non-logged in users are allowed to access this route.
          return <Redirect to={{pathname: '/scheduling-dashboard'}} />
        }
        // authorised so return component
        if (layout === 'PlainLayout') {
          return <Component {...props} />
        }
        return (
          <LinearLayout>
            <Component {...props} />
          </LinearLayout>
        )
      }}
    />
  )
}

export default PublicOnlyRoute
