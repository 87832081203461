// lib imports
import React from 'react'
import {Box} from 'grommet'

// CORE imports
import Sidebar from 'granite/components/Sidebar'

// application imports
import Footer from 'granite/components/Footer'
import Alerts from './components/Alerts'

const DashboardLayout = ({children, pathname, history}) => {
  return (
    <Box direction="row" fill>
      <Sidebar collapsible pathname={pathname} history={history} />
      <Box background="light-2" fill="horizontal">
        <Alerts />
        <Box overflow={{vertical: 'auto'}} fill>
          {children}
          <Footer />
        </Box>
      </Box>
    </Box>
  )
}

export default DashboardLayout
