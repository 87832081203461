const messages = {
  PAGE_HEADING: 'Shift Dashboard',
  PAGE_SUB_HEADING: 'View and manage daily shifts',
  MANAGE_SHIFTS_LINK_LABEL: 'Manage Shifts',
  SHIFT_MODAL_HEADING: 'Shift Details',
  SHIFT_MODAL_TITLE_LABEL: 'Shift Name :',
  SHIFT_MODAL_START_TIME_LABEL: 'Start Date and Time :',
  SHIFT_MODAL_END_TIME_LABEL: 'End Date and Time :',
  SHIFT_MODAL_P_IN_LABEL: 'Punch-in Restrictions :',
  SHIFT_MODAL_NUM_EMP_LABEL: 'Allocated Employees :',
  SHIFT_MODAL_REOCCURING_LABEL: 'Reoccurs on :',
  SHIFT_MODAL_DEVIATIONS_LABEL: 'Early/Late Arrival Tracking :',
  SHIFT_MODAL_EMPLOYEES_LABEL: 'Employees :',
  SHIFT_MODAL_EMP_NAME_LABEL: 'Name',
  SHIFT_MODAL_EMP_MOBILE_LABEL: 'Mobile',
  SHIFT_MODAL_EMP_DEPT_LABEL: 'Dept-Team',
  SHIFT_MODAL_EMP_PUNCH_IN_RESTRICTIONS: 'Punch-in Restrictions',
  SHIFT_MODAL_EMP_ACTIONS_LABEL: 'Actions',
  SHIFT_MODAL_SEARCH_PLACEHOLDER: 'Search Employee and add',
  SHIFT_MODAL_EDIT_SHIFT_LABEL: 'Edit',
  SHIFT_MODAL_DELETE_SHIFT_LABEL: 'Delete',
  SHIFT_MODAL_NA: 'NA',
  SHIFT_MODAL_NO_EARLIER_THAN: 'No earlier than',
  SHIFT_MODAL_NO_LATER_THAN: 'No later than',
  EDIT_SHIFT_MODAL_HEADING:'Edit Shift',
  ADD_SHIFT_MODAL_SHIFT_BTN_LABEL:'Submit',
  SHIFT_MODAL_TIMEFENCE_START_TIME_LABEL: 'Timefence Start Date and Time :',
  SHIFT_MODAL_TIMEFENCE_END_TIME_LABEL: 'Timefence End Date and Time :',
  SHIFT_MODAL_GRACE_PERIOD_LABEL: 'Grace Period :',
}

export default messages
