// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'
import moment from 'moment'

// Application imports
import shiftApi from 'shifts/gateways/shift-api'
import {SHIFT_EVENTS, EMPLOYEE_EVENTS} from 'pages/shiftManagement/constants'
import employeeApi from 'employee/gateways/employee-api'
// TODO: update comment when migrating to Granite API
/**
 * OnBoarding Page - Implimentation of user createOrganisation action.
 *
 * @param {Object} organisation data
 * @param {string} organisation.name
 * @param {string} organisation.description
 * @param {string} organisation.address
 *
 * @returns {json} - a object containing success/failure message
 * @throws {GraniteError} if name is invalid.
 */
async function createShifts(eventEmitter, params, dragAddEmployee) {
	try {
		// const {startDate,endDate, ...rest} = params
		const {startDate,endDate, ...rest} = params
		const data = await shiftApi.createShift(rest)
		eventEmitter.emit(SHIFT_EVENTS.CREATE_SHIFT_SUCCESS, data)
		if(params.employeeIds && params.employeeIds.length) {
			await mapEmployeeToShift(eventEmitter, {
	        shift_id: data.id,
	        employee_ids: params.employeeIds,
	      	},false,dragAddEmployee)
	      	fetchAllShifts(eventEmitter, {paginate: false, start_date: startDate, end_date: endDate}, true)
		}
		else{
			fetchAllShifts(eventEmitter, {paginate: false, start_date: startDate, end_date: endDate}, true)
		}		
	} catch (err) {
		const errorMessage = err && err.errors.non_field;
		eventEmitter.emit(SHIFT_EVENTS.CREATE_SHIFT_FAILURE, errorMessage)
	}
}

async function fetchAllShifts(eventEmitter, params,dontShowLoader ) {
	try {
		!dontShowLoader && eventEmitter.emit(SHIFT_EVENTS.SHOW_LOADER)
		const data = await shiftApi.fetchAllShifts(params)
		eventEmitter.emit(SHIFT_EVENTS.GET_ALL_SHIFTS_SUCCESS, data)
		eventEmitter.emit(SHIFT_EVENTS.HIDE_LOADER, true)
	} catch (err) {
		const errorMessage = err && err.errors.non_field;
		eventEmitter.emit(SHIFT_EVENTS.GET_ALL_SHIFTS_FAILURE, errorMessage)
		eventEmitter.emit(SHIFT_EVENTS.HIDE_LOADER, true)
	}
}

async function fetchShiftDetails(eventEmitter, params) {
	try {
		const data = await shiftApi.fetchShiftDetails(params)
		eventEmitter.emit(SHIFT_EVENTS.GET_SHIFT_DETAILS_SUCCESS, data)
		const employeeIds = data.employee_ids
		eventEmitter.emit(EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_SUCCESS, employeeIds)
	} catch (err) {
		const errorMessage = err && err.errors.non_field;
		eventEmitter.emit(SHIFT_EVENTS.GET_EMPLOYEE_DATA_FAILURE, errorMessage)
	}
}

async function updateShifts(eventEmitter, params, type) {
	try {
		const {startDate,endDate, ...rest} = params		
		const data = await shiftApi.updateShift(rest)
		eventEmitter.emit(SHIFT_EVENTS.UPDATE_SHIFT_SUCCESS, data)
		fetchAllShifts(eventEmitter, {paginate: false, start_date: startDate, end_date: endDate}, true)
	} catch (err) {
		const errorMessage = err && err.errors.non_field;
		const {startDate,endDate, ...rest} = params
		eventEmitter.emit(SHIFT_EVENTS.UPDATE_SHIFT_FAILURE, errorMessage)
		fetchAllShifts(eventEmitter, {paginate: false, start_date: startDate, end_date: endDate}, true)
	}
}

async function deleteShifts(eventEmitter, params) {
	try {
		const {startDate,endDate, ...rest} = params
		const data = await shiftApi.deleteShift(rest)
		eventEmitter.emit(SHIFT_EVENTS.DELETE_SHIFT_SUCCESS, data)
		fetchAllShifts(eventEmitter, {paginate: false, start_date: startDate, end_date: endDate}, true)
	} catch (err) {
		const errorMessage = err && err.errors.non_field;
		eventEmitter.emit(SHIFT_EVENTS.DELETE_SHIFT_FAILURE, errorMessage)
	}
}

async function mapEmployeeToShift(eventEmitter, params, fetchAllShiftsParams, dragAddEmployee) {
	try {
		const data = await shiftApi.mapEmployeeToShift(params)
		eventEmitter.emit(SHIFT_EVENTS.ADD_EMPLOYEE_TO_SHIFT_SUCCESS, data)
		const shiftParams= {shift_id:  data[0].updated_shift_id || params.shift_id}
		const shiftData = await shiftApi.fetchShiftDetails(shiftParams)
		if(!dragAddEmployee){
			eventEmitter.emit(SHIFT_EVENTS.GET_SHIFT_DETAILS_SUCCESS, shiftData)
		}
		const employeeIds = shiftData.employee_ids
		eventEmitter.emit(EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_SUCCESS, employeeIds)
		fetchAllShiftsParams && fetchAllShifts(eventEmitter, {paginate: false, start_date: fetchAllShiftsParams.start_date, end_date: fetchAllShiftsParams.end_date}, true)
	} catch (err) {
		const errorMessage = err && err.errors.non_field;
		eventEmitter.emit(SHIFT_EVENTS.ADD_EMPLOYEE_TO_SHIFT_FAILURE, errorMessage)
	}
}

async function updateEmployeeShiftMap(eventEmitter, params, fetchAllShiftsParams) {
	try {
		const data = await shiftApi.updateEmployeeShiftMap(params)
		eventEmitter.emit(SHIFT_EVENTS.UPDATE_EMPLOYEE_SHIFT_MAP_SUCCESS, data)
		const shiftParams= {shift_id: data.updated_shift_id || params.shift_id}
		const shiftData = await shiftApi.fetchShiftDetails(shiftParams)
		eventEmitter.emit(SHIFT_EVENTS.GET_SHIFT_DETAILS_SUCCESS, shiftData)
		const employeeIds = shiftData.employee_ids
		eventEmitter.emit(EMPLOYEE_EVENTS.GET_EMPLOYEE_DATA_SUCCESS, employeeIds)
		fetchAllShifts(eventEmitter, {paginate: false, start_date: fetchAllShiftsParams.start_date, end_date: fetchAllShiftsParams.end_date}, true)

	} catch (err) {
		const errorMessage = err && err.errors.non_field;
		eventEmitter.emit(SHIFT_EVENTS.UPDATE_EMPLOYEE_SHIFT_MAP_FAILURE, errorMessage)
	}
}

export {createShifts, fetchAllShifts, fetchShiftDetails, updateShifts, deleteShifts, mapEmployeeToShift, updateEmployeeShiftMap}