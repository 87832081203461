// lib imports
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Box, Image, Button, Heading, ResponsiveContext} from 'grommet'
import {Menu} from 'grommet-icons/icons/'

// CORE imports
import * as SidebarDucks from 'accounts/ducks/sidebar'
import * as UserDucks from 'accounts/ducks/user'
import * as OrganisationDucks from 'organisations/ducks/organisations'
import {getPermissionsAndSideMenuItems} from 'accounts/gateways/sidebar-api'
import EventEmitter from 'utils/event-emitter'
import SectionLoader from 'granite/components/SectionLoader'
import Divider from 'granite/components/Divider'
import VerticalMenu from 'granite/components/VerticalMenu'

// application imports
import ProfileSection from './components/ProfileSection'
import DateTime from './components/DateTime'

const hoverIndicatorOptions = {
  color: '#4cbcb7',
}

function fetchSidemenuItems(dispatch) {
  getPermissionsAndSideMenuItems().then(response => {
    dispatch({
      type: 'accounts/sidebar/UPDATE_SIDEBAR',
      data: response.sidemenuItems,
    })
    dispatch({
      type: 'accounts/user/UPDATE_PERMISSIONS',
      data: response.permissions,
    })
  })
}

function listenEvents(eventEmitter) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      default:
        break
    }
  })
  return subscription
}

const Sidebar = ({
  dispatch,
  history,
  pathname,
  title,
  width,
  collapsible,
  headingProps,
  sidebarItems,
  userProfile,
  userPermissions,
  userOrganisations,
  ...rest
}) => {
  let eventEmitter = new EventEmitter()
  const [collapsed, setCollapsed] = useState(true)

  useEffect(function init() {
    const subscription = listenEvents(eventEmitter)

    return function cleanUp() {
      subscription.unsubscribe()
    }
  }, [])

  if (sidebarItems.length === 0) {
    fetchSidemenuItems(dispatch)
  }
  return (
    <ResponsiveContext.Consumer>
      {size => {
        const isCollapsed =
          (collapsible && size === 'small' && collapsed === undefined) ||
          collapsed === true

        return (
          <Box
            width={isCollapsed ? '63px' : width}
            style={{
              minWidth: isCollapsed ? '63px' : width,
              position:'relative'
            }}
            {...rest}
            background="#001e2e"
            elevation="small"
          >
            <Box flex>
              <Box
                pad={{horizontal: 'small', vertical: 'xsmall'}}
                gap="small"
                direction="row"
                align="center"
                justify={isCollapsed ? 'center' : 'end'}
              >
                <Button
                  pad="xxsmall"
                  as="div"
                  icon={
                    <Image
                      height="15"
                      width="16"
                      src={process.env.PUBLIC_URL + '/img/ham-menu-icon.png'}
                      alt="menu"
                    />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                />
              </Box>
              <Box
                pad={{horizontal: isCollapsed ? 'none' : 'medium'}}
                margin={{horizontal: isCollapsed ? 'none' : 'small'}}
              >
                {!isCollapsed && title && (
                  <Box flex={false} tag="header" {...headingProps}>
                    {typeof title === 'string' ? (
                      <Heading margin="none" level={3}>
                        {title}
                      </Heading>
                    ) : (
                      title
                    )}
                  </Box>
                )}
              </Box>
              {/*sidebarItems.length === 0 || Boolean(userProfile) === false ? (
                <Box align="center" justify="center" fill>
                  <SectionLoader />
                </Box>
              ) : (*/}
                <>
                  <Box
                    overflow={{vertical: 'auto'}}
                    style={{overflowX: 'hidden'}}
                    flex
                    margin={{top: 'medium'}}
                    pad={{top: 'medium'}}
                  >
                    <VerticalMenu
                      data={sidebarItems}
                      pathname={pathname}
                      isCollapsed={isCollapsed}
                      hoverIndicatorOptions={hoverIndicatorOptions}
                    />
                  </Box>
                  <ProfileSection
                    isCollapsed={isCollapsed}
                    user={userProfile}
                    history={history}
                    organisations={userOrganisations[0]}
                  />
                </>
              {/*)*/}
            </Box>
            {!isCollapsed && <DateTime userProfile={userProfile}/>}
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

Sidebar.defaultProps = {
  width: '230px',
  title: (
     <Box align="center" gap="medium">
        <Image
          height="auto"
          width="70%"
          src={process.env.PUBLIC_URL + '/img/logo_blue.png'}
          alt="Metc Logo"
        />
        <Image
          height="auto"
          width="100%"
          src={process.env.PUBLIC_URL + '/img/BrandName.png'}
          alt="Metc Logo"
        />
    </Box>
  ),
}

const mapStateToProps = state => ({
  sidebarItems: SidebarDucks.sidebarItems(state),
  userProfile: UserDucks.profile(state),
  userPermissions: UserDucks.permissions(state),
  userOrganisations: OrganisationDucks.organisations(state),
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Sidebar)
