import {Box, Text} from 'grommet'
import React, {useEffect, useState} from 'react'
import moment from 'moment'

const options = {timeZoneName: 'short'}

const DateTime = ({...rest}) => {
  const clock = useClock(rest)
  return <Box>{clock}</Box>
}

const useClock = (data) => {
  const [dateTime, setDateTime] = useState(moment().format('MMM Do YYYY, h:mm:ss a'))
  const { userProfile } = data;

  useEffect(() => {
    let id = setInterval(() => {
      setDateTime(moment().format('MMM Do YYYY, h:mm:ss a'))
    }, 1000)

    return () => clearInterval(id)
  }, [])

  return (
    <Box justify="start" pad="small" background="#001e2e">
      <Text size="xsmall" color="light-1">{dateTime}</Text>
      <Text size = "xsmall" color = "light-1">{userProfile.timezone}</Text>
    </Box>
  )
}

export default DateTime
