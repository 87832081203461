import React from 'react';
import { Card, Box, Text, RadioButton } from 'grommet';
import PropTypes from 'prop-types';
const MultiSelectFormComponent = ({ data, index }) => {
  return (
    <>
      <Card border >
        <Box direction="row" gap="small" pad={'small'}>
          <Box direction="row">
            {data.behaviour === 'MANDATORY' && <Text color={'red'}>*</Text>}
            <Text>{index + 1}.</Text>
          </Box>

          <Text>{data.question}</Text>
        </Box>
        <Box pad={{ left: 'medium', bottom: 'medium'}} gap='xsmall'>
          {data && data.answer && data.answer.length ? (
            data.answer.map((item, id) => (
              <Box key={id} gap='small'>
                {item.value ? (
                  <Box direction="row" gap="small">
                    <RadioButton checked={item.checked} disabled />
                    <Text>{item.value}</Text>
                  </Box>
                ) : null}
              </Box>
            ))
          ) : (
            <div>No Response Submitted</div>
          )}
        </Box>
      </Card>
    </>
  );
};

MultiSelectFormComponent.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};
export default MultiSelectFormComponent;
