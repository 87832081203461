import React from 'react'
import {Box, CheckBox, Text} from 'grommet'

// CORE imports
import DateTimePicker from 'granite/components/DateTimePicker'
import Divider from 'granite/components/Divider'

import moment from 'moment'

// NOTE: component expects data in this format
// const defaultDays = [
//   {label: 'Mon', val: 1, selected: false},
//   {label: 'Tue', val: 2, selected: false},
//   {label: 'Wed', val: 3, selected: false},
//   {label: 'Thu', val: 4, selected: false},
//   {label: 'Fri', val: 5, selected: false},
//   {label: 'Sat', val: 6, selected: false},
//   {label: 'Sun', val: 7, selected: false},
// ]
// const defaultReoccuranceData = {
//   isReoccuring: false,
//   days: defaultDays,
//   endsOn: null,
// }
const ReoccuranceInputs = ({data, shifts, setData, ...rest}) => {  
  const {days, endsOn, isReoccuring, neverExpire} = data
  const dateToday = moment(Date.now()).format('YYYY-MM-DD') 

  const handleDayClick = dayVal => {
    const updatedDays = days.map(d => {
      if (d.val === dayVal) {
        d.selected = !d.selected
        return d
      }
      return d
    })
    setData({isReoccuring, endsOn, days: updatedDays, neverExpire})    
  }

  return (
    <Box gap="medium" {...rest} height={{min: 'auto'}}>      
      <Box direction="row" gap="small">
        <CheckBox
          checked={isReoccuring}
          onChange={() => setData({days, endsOn, isReoccuring: !isReoccuring, neverExpire: false})}
        />
        <Text color="dark-1" size="small" alignSelf="center">
          On Going ?
        </Text>
      </Box>
      {isReoccuring && (
        <>
          <Box direction="row" gap="small">
            {days.map(day => {
              return (
                <Box
                  key={day.label}
                  background={day.selected ? 'accent-1' : 'light-4'}
                  align="center"
                  justify="center"
                  onClick={() => handleDayClick(day.val)}
                  pad={{vertical: 'xsmall', horizontal: 'medium'}}
                  round="xsmall"
                  style={{cursor: 'pointer'}}
                  margin={{vertical: 'small'}}
                  border={{color: 'dark-4', size: 'xsmall'}}
                  focusIndicator={false}
                >
                  <Text
                    color={day.selected ? 'light-1' : 'text'}
                    size="xsmall"
                    style={{cursor: 'pointer'}}
                  >
                    {day.label.toUpperCase()}
                  </Text>
                </Box>
              )
            })}
          </Box>
          <Box direction="row" gap="medium" margin={{top: 'small'}} justify="between">
            <Box basis='50%'>
              <Text color="dark-1" size="small">
                Ends On
              </Text>
              <Box
                border={{color: 'light-4', size: 'xsmall'}}
                round="xsmall"
                >
                <DateTimePicker
                  initialDate={endsOn}
                  onClose={val => setData({days, isReoccuring, endsOn: val.date, neverExpire: false})}
                  closeOnClick={true}
                  disabledDate={dateToday}
                  />
              </Box>            
            </Box>
            <Box direction='row' gap='small' margin={{top: 'medium'}}>
              <CheckBox
                checked={neverExpire}
                onChange={() => setData({days, endsOn: null, isReoccuring, neverExpire: !neverExpire})}
              />
              <Text color="dark-1" size="small" alignSelf="center">
                Never Expire
              </Text>
            </Box>
          </Box>
          <Divider
            background="light-5"
            margin={{top: 'medium'}}
            height="vsmall"
          />
        </>
      )}
    </Box>
  )
}

export default ReoccuranceInputs
