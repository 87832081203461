import React, { useState, useEffect, useCallback } from 'react';
import { Box, Select } from 'grommet';

import { STATUS_OPTIONS } from 'pto/constants';

const Filter = ({ status, setFilterStatus }) => {
  return (
    <Box fill align="start" justify="start">
      <Select
        options={STATUS_OPTIONS}
        value={status}
        valueKey={{ key: 'id', reduce: true }}
        labelKey="name"
        onChange={({ value: nextValue }) => setFilterStatus(nextValue)}
      />
    </Box>
  );
};

export default Filter;
