// Lib Imports
import React from 'react'
import {Anchor, Box, Button, Text, TextInput} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'

// CORE Imports
import FormField from 'granite/components/FormField'

const SubmitTokenForm = ({onResendToken, onSubmit}) => {
  const validationSchema = Yup.object().shape({
    token: Yup.string()
      .min(2, 'Too Short!')
      .max(10, 'Too Long!')
      .required('Token is required'),
  })

  return (
    <Formik
      initialValues={{
        token: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="token"
            label="Enter the OTP"
            required
            error={touched.token && errors.token}
          >
            <TextInput
              placeholder="Enter code"
              type="token"
              name="token"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.token}
            />
          </FormField>
          <Text size="small">
            Didn't receive code? <Text color="brand" onClick={onResendToken} size="small" style={{cursor: 'pointer'}}>resend code</Text>
          </Text>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            justify="between"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{vertical: 'small'}}
              label={isSubmitting ? 'Verifying...' : 'Verify'}
              disabled={isSubmitting}
              primary
            />
            <Link to="/login">
              <Anchor
                as="span"
                label="Log in to existing account"
                size="small"
                margin={{vertical: 'small'}}
              />
            </Link>
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default SubmitTokenForm
