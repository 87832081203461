import {combineReducers} from 'redux'

import accounts from 'accounts/ducks'
import organisations from 'organisations/ducks'

const appReducer = combineReducers({
  accounts,
  organisations,
})

const rootReducer = (state, action) => {
  let newState = state
  if (action.type === 'accounts/user/RESET_STATE') {
    newState = undefined
  }
  return appReducer(newState, action)
}

export default rootReducer
