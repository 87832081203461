import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class Department {
	constructor(data) {
		this.company = data.company || ''
		this.create_date = data.create_date || ''
		this.created_by_user = data.created_by_user || ''
		this.department_payroll = data.department_payroll
		this.department_payroll_name = data.department_payroll_name || {}
		this.description = data.description || ''
		this.id = data.id || ''
		this.is_active = data.is_active || ''
		this.is_deleted = data.is_deleted || ''
		this.modified_date = data.modified_date || ''
		this.name = data.name || ''
		this.payroll_setting = data.payroll_setting || ''
		this.payroll_setting_daily_split = data.payroll_setting_daily_split || ''
		this.payroll_setting_is_meal_override =
			data.payroll_setting_is_meal_override || ''
		this.payroll_setting_name = data.payroll_setting_name || ''
		this.payroll_setting_week_day = data.payroll_setting_week_day || ''
		this.payroll_setting_work_day_start_time =
			data.payroll_setting_work_day_start_time || ''
		this.teams = data.teams || []
	}
}

export default Department
