import React, { useEffect, useState } from 'react';
import { Box, Text, Button, TextArea, InfiniteScroll, RadioButtonGroup, CheckBox } from 'grommet';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import { Formik, FieldArray } from 'formik';

const StyledCheckBox = styled(CheckBox)`
  & ~ div {
    height: 15px;
    width: 15px;
    border: 1px solid #09819a;
    background: ${props => (props.checked ? '#09819a' : 'white')};
  }

  ,
  & + div {
    box-shadow: none;
  }
  ,
  & + div > svg {
    stroke: white;
  }
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  &{
    border: none !important;
  }
  & > label > div{
    height: 15px;
    width: 15px;
    font-size: 14 px;
    box-shadow: none;
  }
  ,
  & >label> div > div{
    border: 1px solid #09819a;
  },
  & >label:hover{
    & >label> div > div{
      border: 1px solid #09819a;
    },
  }
  ,
  & > label {
    font-size: 14px;
    color: #09819a;
    box-shadow: none;
  },
  .euRtrc {
    
    fill: #09819a;
}
`;

const StyledText = styled(Text)`
  &{
    color: black;
    min-width: fit-content;
  }
`
const PtoRequestActionSlider = ({ data, loading, onSubmit }) => {
  const [noteValue, setNoteValue] = useState('');
  const [details, setDetails] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    setDetails(data?.request_details ?? []);
    setNoteValue(data?.supervisor_note ?? '');
    setValue(data?.status ?? '');
  }, [data]);

  const handleSubmit = values => {
    let obj = {
      request_details: values.checked,
      type: value,
      supervisor_note: noteValue,
    };
    onSubmit(obj);
  };


  const handleActionOnAllDetails = (setFieldValue, status) => {
    details.forEach((item, index) => {
      setFieldValue(`checked.${index}.status`, status);
    });
  };
  return (
    <Formik initialValues={{ checked: details }} onSubmit={handleSubmit} enableReinitialize>
      {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, setTouched }) => (
        <form onSubmit={handleSubmit}>
          <Box background="white" height="fit-content" width="xlarge">
            <Box flex>
              <Box direction="row" align="center" gap="small">
                <StyledText >Policy :</StyledText>
                <Text truncate>{data && data?.policy_name}</Text>
              </Box>
              <Box direction="row" align="center" gap="small">
                <Text color="black">Start Date :</Text>
                <Text>{data && data?.start_date_display}</Text>
              </Box>
              <Box direction="row" align="center" gap="small">
                <Text color="black">Time Requested :</Text>
                <Text>{data && data?.total_requested_hours}</Text>
              </Box>

              <Box direction="column" align="start">
                <Box direction="row" gap="medium">
                  <Text color="black">Details :</Text>
                  {data.status === "DELETED" ? <Text color="gray">This request has been deleted.</Text> :<StyledRadioButtonGroup
                    gap="medium"
                    direction="row"
                    options={[
                      { label: 'Approve All', value: 'APPROVED' },
                      { label: 'Reject All', value: 'REJECTED' },
                    ]}
                    value={value}
                    onChange={event => {
                      setValue(event.target.value);
                      // handleActionOnAllDetails(setFieldValue, event.target.value);
                    }}
                  />}
                </Box>
                <Box pad={{ left: 'large' }} height={{ min: 'fit-content', max: 'large' }} overflow="auto" width="full">
                  <FieldArray
                    name="checked"
                    render={arrayHelpers => (
                      <InfiniteScroll items={values.checked}>
                        {(item, index) => (
                          <Box flex={false}>
                            <Box direction="row" gap="medium">
                              {/* <StyledCheckBox
                                name={`checked.${index}.id`}
                                checked={item.status === 'APPROVED' ? true : false}
                                onChange={e => {
                                  setValue('PARTIAL');
                                  setFieldValue(`checked.${index}.status`, e.target.checked ? 'APPROVED' : 'REJECTED');
                                }}
                              /> */}
                              <Text>
                                {item?.datetime} full day {item?.hours}
                              </Text>
                            </Box>
                          </Box>
                        )}
                      </InfiniteScroll>
                    )}
                  />
                </Box>
              </Box>
              <Box pad={{ top: 'small' }} direction="row" align="start" gap="small">
                <StyledText>Description :</StyledText>
                <Text
                  truncate="tip"
                  tip={{
                    plain: true,
                    dropProps: {align: {bottom: 'top'}},
                    content: (
                      <Box
                        pad="small"
                        background="#EDEDED"
                        round="small"
                        margin="medium"
                      >
                        <Text color="#242424" size="small" wordBreak='break-all'>
                          {data && data.employee_note}
                        </Text>
                      </Box>
                    ),
                  }}
                >
                  {data && data.employee_note}
                </Text>
              </Box>
              <Box direction="row" align="center" gap="small">
                <Text color="black">Balance :</Text>
                <Text>
                {data?.status ? data?.status === value ? `${data?.employee_name} is having ${data?.current_balance} hours`:
                    value === 'APPROVED' ? `${data?.employee_name} will have ${data?.current_balance - data?.total_requested_hours} hours`:
                    data?.status === 'PENDING' ? `${data?.employee_name} will have ${data?.current_balance} hours`:
                    `${data?.employee_name} will have ${data?.current_balance + data?.total_requested_hours} hours`: ''
                  }
                </Text>
              </Box>
              {data.status !== "DELETED" ?<Box pad={{ top: 'medium', bottom: 'large' }} direction="column" align="center" gap="small">
                <Text color="black" alignSelf="start">
                  Note to employee :
                </Text>
                <TextArea
                  size="small"
                  background="white"
                  placeholder="Leave a note"
                  value={noteValue}
                  onChange={event => setNoteValue(event.target.value)}
                />
              </Box>:null}
            </Box>
            {data.status !== "DELETED" ? <Box pad={{ left: 'small', top: 'medium' }} direction="row" align="end" gap="large">
              <Button
                alignSelf="start"
                type="submit"
                label={loading ? 'Submitting ...' : 'Submit'}
                color="accent-1"
                primary
                disabled={(value === "PENDING") || (value === data?.status)}
              />
            </Box>:null}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default withTheme(PtoRequestActionSlider);
