import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Keyboard,
  Text,
  Calendar,
  MaskedInput,
  DropButton,
  Image,
} from 'grommet'
import moment from 'moment'
import {Schedule} from 'grommet-icons'
import { initial } from 'lodash-es'

const DropContent = ({
  date: initialDate,
  time: initialTime,
  onClose,
  showTime,
  closeOnClick,
  disabledDate,
  ...rest
}) => {
  const [date, setDate] = useState()
  const [time, setTime] = useState()
  
  useEffect(() => {
    if (closeOnClick && date) close()
  }, [date])

  const close = () => {
    onClose(date || initialDate, time || initialTime)
  }
  return (
    <Box fill="horizontal" align="center" pad={{horizontal: 'small'}}>
      <Calendar
        animate={false}
        daysOfWeek={true}
        size="medium"
        date={date || initialDate}
        onSelect={setDate}
        showAdjacentDays={true}
        disabled={[["1970-01-01", disabledDate && disabledDate]]}
        {...rest}
      />
      <Box pad="medium" gap="medium">
        <Keyboard
          onEnter={event => {
            event.preventDefault() // so drop doesn't re-open
            close()
          }}
        >
          <>
            {showTime && (
              <>
                <MaskedInput
                  mask={[
                    {
                      length: [1, 2],
                      options: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                      ],
                      regexp: /^1[1-2]$|^[0-9]$/,
                      placeholder: 'hh',
                    },
                    {fixed: ':'},
                    {
                      length: 2,
                      options: ['00', '15', '30', '45'],
                      regexp: /^[0-5][0-9]$|^[0-9]$/,
                      placeholder: 'mm',
                    },
                    {fixed: ' '},
                    {
                      length: 2,
                      options: ['am', 'pm'],
                      regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                      placeholder: 'ap',
                    },
                  ]}
                  value={time || initialTime}
                  name="maskedInput"
                  onChange={event => setTime(event.target.value)}
                  {...rest}
                />
                <Button
                  primary
                  color="brand"
                  label="Done"
                  onClick={close}
                  {...rest}
                />
              </>
            )}
          </>
        </Keyboard>
      </Box>
    </Box>
  )
}

// TODO: allow user to jump certain number of years
const DateTimeDropButton = ({
  initialDate,
  onClose: userOnClose,
  showTime,
  closeOnClick,
  date_format,
  shiftManagement,
  placeholder,
  ...rest
}) => {
  const [date, setDate] = useState(initialDate || undefined)
  const [time, setTime] = useState('')
  const [open, setOpen] = useState(false)

  const onClose = (date, time) => {
    setDate(date)
    setTime(time)
    setOpen(false)
    userOnClose({date, time})
  }

  useEffect(() => {
    setDate(initialDate)
  }, [initialDate,shiftManagement])

  return (
    <DropButton
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      dropProps={{
        onClickOutside: () => onClose(date, time),
        responsive: false,
      }}
      dropContent={
        <DropContent
          closeOnClick={closeOnClick}
          date={date}
          onClose={onClose}
          showTime={showTime}
          time={time}
          {...rest}
        />
      }
    >
      <Box
        direction="row"
        gap="medium"
        justify="between"
        align="center"
        pad={{vertical:'xsmall', horizontal:'small'}}
      >
        <Text color={date ? undefined : 'dark-5'}>
          {date
            ? date_format ? `${ moment(date).format(`${date_format}`)} ${time}` : `${new Date(date).toLocaleDateString()} ${time}`
            : (placeholder?placeholder:`Select date ${showTime ? '& time' : ''}`)}
        </Text>
        <Image
          alt="job center"
          height="20px"
          src={process.env.PUBLIC_URL + '/img/calendar_icon.svg'}
        />
      </Box>
    </DropButton>
  )
}

DateTimeDropButton.defaultProps = {
  initialDate: undefined,
  onClose: () => {},
  showTime: false,
  closeOnClick: false,
  date_format: undefined
}

DateTimeDropButton.propTypes = {
  initialDate: PropTypes.string,
  onClose: PropTypes.func,
  showTime: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  date_format: PropTypes.string,
}

export default DateTimeDropButton
