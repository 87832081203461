import React from 'react'
import {Anchor, Box, Text} from 'grommet'

const Footer = () => {
  return (
    <Box
      as="footer"
      align="center"
      pad={{
        vertical: 'small',
        horizontal: 'medium',
      }}
      margin="none"
      flex={{shrink: 0}}
    >
      <Text size="small">
        Powered By{' '}
        <Anchor target="__blank" href="https://thoughts2binary.com">
          T2B Granite
        </Anchor>
      </Text>
    </Box>
  )
}

export default Footer
