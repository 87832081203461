import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as EditIcon} from './edit.svg';

const EditIconAtom = props => (
  <EditIcon {...props} />
);

EditIconAtom.defaultProps = {
  height: 15,
  width: 15,
};

EditIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default EditIconAtom;
